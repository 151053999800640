import React, {useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import "./css/landing_body.css";
import Whatsapp from './img/redes/whatsapp.svg';
import Email from './img/redes/email.svg';


export default function BasicTimeline() {
    const [estatusBeca] = useState(parseInt(sessionStorage.getItem('estatus_beca')));

    return (
      <>
      <div>
        <p className="" style={{marginTop: "10px", width: "100%"}}>
           Si requieres obtener un Certificado de Carrera de Google y necesitas de una beca, realiza los siguientes pasos:
        </p>
        {(estatusBeca !== 1 && estatusBeca !== 3)  ?
        <Timeline style={{ pull: 'left' }} className="center-timeline">
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>01.</span> Regístrate.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>02.</span> Inicia Sesión y completa el formulario.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>03.</span> Selecciona el Certificado que te interesa.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='overwrite-padding'><span className='number-bold'>04.</span> Para saber si tu beca fue otorgada ingresa con tu usuario y contraseña de un lapso de 1 a 3 semanas.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
            <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>05.</span> Si recibiste tu beca ¡Felicidades! tienes 2 semanas para activar tu curso en Coursera.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>06.</span> Organiza tus tiempos para tomar tu curso, recuerda que requieres alrededor de 10 hrs. a la semana.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>07.</span> Al concluir el curso obtendrás tu certificado.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>08.</span> Si obtienes tu certificado antes de 6 meses, tendrás acceso a nuestra plataforma de vinculación con aliados de Google e INROADS.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>09.</span> Si deseas realizar otro curso tendrás hasta el 31 de marzo para concluirlo.</TimelineContent>
            </TimelineItem>
        </Timeline> :
        <>
        <Timeline style={{ pull: 'left' }} className="center-timeline">
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>01.</span> Si recibiste tu beca, ¡Felicidades!, tienes 2 semanas para activar tu curso en Coursera.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>02.</span> Organiza tus tiempos para tomar tu curso, recuerda que requieres alrededor de 10 hrs. a la semana.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>03.</span>Al concluir el curso obtendrás tu certificado.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='overwrite-padding'><span className='number-bold'>04.</span>Al concluir tu primer Certificado, aprovecha tu beca y selecciona otro en esta misma plataforma. Tendrás hasta el 31 de marzo 2023 para concluirlo.</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '5vw', paddingRight: '0vw' }} />
                <TimelineSeparator>
                <TimelineDot color="info"/>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent><span className='number-bold'>05.</span>Cuando obtengas tu Certificado tendrás acceso próximamente a nuestra plataforma de empleo con aliados de Google e INROADS.</TimelineContent>
            </TimelineItem>
        </Timeline>
        <div className='conten-contact '>
            <p>Puedes contactarnos por medio de nuestros canales:</p>
            <div>
                <a href="https://wa.link/or8oge" target="_blank" rel="noopener noreferrer"><img src={Whatsapp} alt="whats" width={"25px"}/><span>56 3388 9253</span></a>
            </div>
            <div>
               <a href="mailto:becas.certificado.google@inroads.org.mx"> <img src={Email} alt="whats" width={"25px"}/><span>becas.certificado.google@inroads.org.mx</span> </a>
            </div>
        </div>
        </>
        }
    </div>
    <div className='acordion-mobil'>
        <div onClick={()=> document.getElementById("panel1a-header").click() } role="button">
            <KeyboardArrowUpIcon fontSize="large"/>
        </div>
    </div>
    </>
  );
}