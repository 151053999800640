import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ParallaxBanner } from 'react-scroll-parallax';
import "../css/parallax.css";
import BasicTimeline from '../Timeline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';


const ParallaxImage = () => {
  const [estatusBeca] = useState(parseInt(sessionStorage.getItem('estatus_beca')));
  const rutaImg = estatusBeca !== 2 ? './header_fin.png' : './header_image02.jpg';
  const [imgUrl, setImgUrl] = useState([]);

  useEffect(() => {
    if(estatusBeca === 1 || estatusBeca > 2) {
      require('bootstrap/dist/css/bootstrap.min.css');
    }

    let arrayImg = [];
    axios.get('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/frontend/noticias/')
    .then((response) => {
      
      arrayImg = response.data.conf_noticias
      
      if(estatusBeca === 3) {
        const imgAux = {
          texto_img: null,
          url_link: " https://bewanted.com/landings/google_inroads_candidatos",
          url_noticia : "https://storage.googleapis.com/gcp-noticias-frontend-4894465/Header_beWanted.png"
        }
        arrayImg.push(imgAux);
      }
      setImgUrl(arrayImg);

    })
    .catch((error) => {
        console.error("Error en la petición ",error);
    });


  }, []);

  const ParallaxItem = ({img, text}) => (
      <>
        {text ? 
         <ParallaxProvider>
          <ParallaxBanner
            layers={[
              { image: img, speed: -150 },
                {
                speed: -120,
              },
              { image: img, speed: -18},
            ]}
            className="parallexAspectRatio"
          >
            <div className="absolute inset-0 flex items-center justify-center text-into parallexAspectRatio-text">
              <p className="text-8xl text-white font-thin">
                <div dangerouslySetInnerHTML={{ __html: text}} />
              </p>
            </div>
          </ParallaxBanner>
         </ParallaxProvider> :
        <div className='parallax-none'>
            <img src={img}/>
        </div>
        }
      </>
      
     
  );

  if(sessionStorage.getItem('formulario') !== 'no'){
    return (
      <>
      { estatusBeca === 1 | estatusBeca > 2 ? 
      <Carousel>
        {
          imgUrl.map( img => (
            <Carousel.Item> 
              {
                img.url_link ? 
                <a href={img.url_link} target="_blank" without rel="noopener noreferrer"><ParallaxItem img={img.url_noticia} text={img.texto_img}/></a> :
                <ParallaxItem img={img.url_noticia} text={img.texto_img}/>
              }
            </Carousel.Item>
          ))
        }
      </Carousel> :
        <a href='https://becas.inroads.org.mx/' target="_blank" without rel="noopener noreferrer"><ParallaxItem img={rutaImg} 
        text={""} /></a>
      }
      { estatusBeca !==2 ?
      <div className='accordion-line-time'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Pasos para aplicar</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BasicTimeline />
          </AccordionDetails>
        </Accordion>
      </div> : <></>
      }
    </>
    );
  }else {
    return null
  }
};

export default ParallaxImage