import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import headerInroads from "./img/inroads_becas_email_header.jpg";
import headerInroadsMobil from "./img/inroads_becas_email_header_mobil.jpg";
import ResponsiveDialog from './DailogRegister';
import SignUp from "./SignIn";
import "./css/appBar.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: 'black',
      background: 'white',

    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
  
    logo: {
      maxWidth: 100,
      maxHeight: 500,
      marginRight: '10px'
    },
    logoL: {
      maxWidth: 100,
      maxHeight: 50,
      marginRight: '10px'
    },
    loginButton: {
      borderColor: '#2196F3',
      color: '#2196F3'
    },
  })
);

export function ButtonAppBar() {
  const classes = useStyles();

  return (
    <>
    <div id="appbar" className={classes.root}>
      <AppBar position="static" style={{ background: '#F3F3F3',  Height: 500 }}>
        <Toolbar style={{ height: 100, paddingRight:0 }}>
          <img src={headerInroads} alt="Inroads" height={100}/>
          {/* Fin de certificado
          <Typography variant="h6" className="title">
          <ResponsiveDialog liga="true" />
            <span className="spanHeader"> para comenzar </span>
          </Typography>
          */}
          <SignUp></SignUp>
        </Toolbar>
      </AppBar>
    </div>
    <div id="appbar-mobil">
      <AppBar position="static" style={{ background: '#F3F3F3',  Height: 500 }}>
        <Toolbar style={{ height: 100, paddingRight:0, textAlign:'justify'}}>
          <div>
            <img src={headerInroadsMobil} style={{width:'80%'}} alt="Inroads"/>
          </div>
          <SignUp></SignUp>
        </Toolbar>
      </AppBar>
    </div>
  </>
  );
}
