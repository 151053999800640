import React, { useState, useEffect } from 'react';
import SessionContex from './provider/SessionContex';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import Home from './Home'
import Stuff from './Stuff'
import Admin from './Admin'
import AsignacionBecas from './AsignacionBecas';
import HistorialAprobacion from './HistorialAprobacion'
import AlumnosExportarCoursera from './AlumnosExportarCoursera'
import AdminAlumCert from './AdminAlumCert';
import CambioContrasenia from './CambioContrasenia';
import AdminAlumDismiss from './AdminAlumDismiss';

export default function Main() {
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    setAdmin(sessionStorage.getItem('Admin'))
  },[]);

  const updateValueAdmin = (value) => {
    setAdmin(value);
  }

  function PrivateRoute({ children, ...rest }) {
    const isSigned = sessionStorage.getItem('isSigned')
    
    return (
      
      <Route
        {...rest}
        render={({ location }) =>
          isSigned ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  return (
   <SessionContex.Provider value={{admin,updateValueAdmin}}>
     {
      admin ?
      (<Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <PrivateRoute exact path="/adminDismiss">
            <AdminAlumDismiss />
          </PrivateRoute>
          <PrivateRoute path="/stuff">
            <Stuff />
          </PrivateRoute>
          <PrivateRoute path="/admin">
            <Admin />
          </PrivateRoute>
          <PrivateRoute exact path="/asignacion">
            <AsignacionBecas />
          </PrivateRoute>
          <PrivateRoute exact path="/historial">
            <HistorialAprobacion />
          </PrivateRoute>
          <PrivateRoute exact path="/AlumnosExportarCoursera">
            <AlumnosExportarCoursera />
          </PrivateRoute>
          <PrivateRoute exact path="/certificados">
            <AdminAlumCert />
          </PrivateRoute>
        </Switch>
      </Router>) : (<Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/cambioContra">
            <CambioContrasenia />
          </Route>
          <PrivateRoute path="/stuff">
            <Stuff />
          </PrivateRoute>
        </Switch>
      </Router>)
      }
    </SessionContex.Provider>
  );
}