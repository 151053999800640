import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MediaControlCard from './CardLanding';
import MediaControlCardSecond from './CardLandingSecond';
import BasicTimeline from './Timeline';
import "./css/landing_body.css";

export default function BasicGrid() {
  const [estatusBeca] = useState(parseInt(sessionStorage.getItem('estatus_beca')));

  return (
    <>
    { estatusBeca !== 2 ?
    <Box sx={{ borderColor: 'primary.main' }}>
      <Grid className='grid-paper' container spacing={3} >
        <Grid item xs={2}></Grid>
        <Grid item xs={5} className="font-landing font-color">
            <div style={{marginTop: "10%"}}>
              {
                estatusBeca > 2 ? <MediaControlCardSecond /> : <MediaControlCard />
              }
            </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3} className="font-landing"  style={{ background:'#f3f3f3' }}>
          <BasicTimeline />
        </Grid>
      </Grid>
      <Grid className='grid-paper-mobil' container spacing={3} >
          <Grid item xs={12}>
            {
              estatusBeca > 2 ? <MediaControlCardSecond /> : <MediaControlCard />
            }
          </Grid>
      </Grid>
    </Box>
    : <>
      <div style={{textAlign:'center', padding:'100px'}}>
        <h3>
          Sentimos comunicarte que en esta ocasión no fue posible proporcionarte la beca que solicitaste para los Certificados de Carrera de Google. 
          Sin embargo, ¡No desistas!
        </h3>
        <p>
          Puedes estar pendiente de otras convocatorias en nuestra página <a href="https://inroads.org.mx" target="_blank" rel="noopener noreferrer">www.inroads.org.mx</a> y seguirnos en nuestras redes
          sociales para enterarte de todos los beneficios que aún podemos ofrecerte.
        </p>
        <p>De igual forma, si se abren nuevas oportunidades te lo notificaremos por este medio. <br /> 
        Gracias por tu tiempo, y por pariticipar en nuestro proceso.</p>
        <h3>INROADS de México.</h3>
      </div>
    </>
    }
    </>
  );
}