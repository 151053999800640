import React, { useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import logo1 from "./curso_TI.jpg";
import logo2 from "./curso_datos.jpg";
import logo3 from "./curso_gestionproyectos.jpg";
import logo4 from "./curso_UX.jpg";
import Grid from '@mui/material/Grid';
import "./css/card.css";
import Stack from '@mui/material/Stack';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import iconoConfirmacion from './img/icono-conformacion.svg';
import flechaAnalista from './img/flecha_analisisdatos.svg';
import flechaGestion from './img/flecha_gestionproyectos.svg';
import flechaSoporte from './img/flecha_soporteIT.svg';
import flechaUx from './img/flecha_UX.svg'
import CardCert from './components/CardCert';
import ComparteRedes from './components/ComparteRedes';

export default function  MediaControlCard() {

  const [statusCerts, setstatusCerts] = useState(JSON.parse(sessionStorage.getItem('statusCerts')));
  const [modal, setModal] = useState(false);
  const [messageCerts] = useState({
    c1Message:"Selecciona",
    c2Message:"Selecciona",
    c3Message:"Selecciona",
    c4Message:"Selecciona",
  });

  const validateStatusCerts = (status) => {
    for (const cert in statusCerts) {
      if(statusCerts[cert] === status) {
        return true;
      }
    }
    return false;
  };

  const handleSetCert = (event) => {
    console.log("seleccione un cert");
    const idCertSelect = parseInt(event.target.id);
    const certSelect = "c"+idCertSelect;

    const data = {email:sessionStorage.getItem('email'), id_certificado:idCertSelect};

    axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/certificados/seleccion_otro/', data , {
            auth: {
              username: sessionStorage.getItem('email'),
              password: sessionStorage.getItem("token")
            }
          })
        .then(res => {
          console.log("respuesta:",res);
          if(res.status=== 200) {
            let statusCertAux = statusCerts;

            for(const cert in statusCertAux) {
              statusCertAux[cert] = cert !== certSelect ? 0 : 2;
            }

            setstatusCerts({...statusCertAux});
            sessionStorage.setItem('statusCerts', JSON.stringify(statusCertAux));
            setModal(true);
          }
        })
        .catch(error => {
            console.error(error)
        })

  }

  //Variables de los certificados
  const infoCerts = [
    { 
      idCert:4,
      titleCert:"Certificado en Soporte TI",
      classCert:"soporte-ti",
      statusCert: statusCerts.c4,
      messageCert: messageCerts.c4Message,
      logoCert:logo1,
      contentCert:[
        <>
        Los especialistas de TI solucionan problemas para que las computadoras y las redes funcionen correctamente.
        </>,
        <>
          Ponte en marcha para conseguir un empleo en
          demanda en el sector de TI con un certificado
          profesional de Google. Aprende las bases de las redes
          y los sistemas operativos, o cómo solucionar
          problemas mediante el uso de código.
        </>
      ],
      iconCert:flechaSoporte
    },
    {
      idCert:1,
      titleCert:"Certificado en Análisis de datos",
      classCert:"analisis",
      statusCert: statusCerts.c1,
      messageCert: messageCerts.c1Message,
      logoCert:logo2,
      contentCert:[
        <>
          Los analistas de datos recopilan, transforman y
          organizan los datos para contribuir en la toma de
          decisiones comerciales informadas.
        </>,
        <>
          Da tus primeros pasos en una carrera de análisis de datos, que experimenta un alto nivel de crecimiento, con
          un certificado profesional de Google. Incorpora las
          habilidades requeridas que te prepararán para el trabajo, por ejemplo, cómo analizar y procesar datos
          para obtener perspectivas clave sobre el negocio.
        </>
      ],
      iconCert:flechaAnalista
    },
    {
      idCert:3,
      titleCert:"Certificado en Gestión de Proyectos",
      classCert:"gestion",
      statusCert: statusCerts.c3,
      messageCert: messageCerts.c3Message,
      logoCert:logo3,
      contentCert:[
        <>
          Los gerentes de proyectos se aseguran de que los 
          proyectos de una organización se administren y
          completen con el máximo valor.
        </>,
        <>
          Da tus primeros pasos en una carrera en administración
          de proyectos, que experimenta un alto nivel de crecimiento,
          con un certificado profesional de Google.
          Averigua cómo administrar proyectos de manera
          eficiente y efectiva mediante el uso de métodos
          tradicionales y ágiles.
        </>
      ],
      iconCert:flechaGestion
    },
    {
      idCert:2,
      titleCert:"Certificado en Diseño de experiencia del Usuario(UX)",
      classCert:"ux",
      statusCert: statusCerts.c2,
      messageCert: messageCerts.c2Message,
      logoCert:logo4,
      contentCert:[
        <>
          Los analistas de datos recopilan, transforman y
          organizan los datos para contribuir en la toma de
          decisiones comerciales informadas.
        </>,
        <>
          Incorpora los apectos básicos del diseño de UX: cómo
          lograr empatía con los usuarios, crear wireframes y
          prototipos, y realizaar investigaciones para probar los
          diseños.
        </>
      ],
      iconCert:flechaUx
    },
  ];

  return (
    <>
    <Modal open={modal} onClose={()=>{setModal(false)}} >
      <Stack spacing={5} alignItems="center" style={{marginTop: "5%"}} id="modal-confirmacion" >
        <br></br><br></br>
          <Typography variant="h4" color="text.secondary" style={{textAlign:'center'}}>
            ¡Felicidades por seleccionar este nuevo Certificado de Carrera de Google! 
          </Typography>
          <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" src={iconoConfirmacion}>
            Confirmado
          </Avatar>
          <Typography variant="h6" color="text.secondary">
            Tu constancia será premiada. 
            En el transcurso de la semana te mandaremos una invitación para iniciar tu nuevo curso en Coursera.
          </Typography>
          <br></br>
      </Stack>  
    </Modal>
    
    { !validateStatusCerts(2)&&!validateStatusCerts(3) &&
      <div style={{textAlign:'justify', marginTop:'15px', marginBottom:'40px'}}>
        <h3>¡Felicidades!</h3>
        <p>Acabaste tu Certificado de Carrera de Google y estas listo para llevar tu 
          carrera al siguiente nivel.
        </p>
        <p>Te compartimos un caso de éxito del programa de becas del año passado, hoy INROADS y 
          Google te brindan esta gran oportunidad para que tú también mejores
          tu vida laboral y personal.
        </p>
        <div>
          <iframe width="100%" height="350px"
            src="https://www.youtube.com/embed/pHGtxZiJIaM" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </div>
        <div>
          <p>Ahora puedes seleccionar otro Certificado de Cerrera de Google:</p>
        </div>
      </div>
    }

    { validateStatusCerts(2) ?
      <div style={{textAlign:'justify', marginTop:'15px', marginBottom:'25px'}}>
        <h3>¡Felicidades por haber seleccionado este nuevo Certificado de Carrera de Google!</h3>
        <hr />
        <p>Tu constancia será premiada.<br />
          En el transcurso de la semana te mandaremos una invitación para iniciar tu 
          nuevo Certificado en Coursera.
        </p>
      </div> : <></>
    }
    { validateStatusCerts(3) &&
      <div style={{textAlign:'justify', marginTop:'15px', marginBottom:'40px'}}>
        <h3>¡Felicidades!</h3>
        <hr />
        <p>Tu solicitud para empezar un nuevo Certificado de Carrera de Google 
          ha sido aprobada. ¡Ya puedes seguir tu camino al éxito en Coursera!
        </p>
        <div>
          <iframe width="100%" height="350px"
            src="https://www.youtube.com/embed/YKXLgI8cTLo" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </div>

      </div>
    }

    <Grid container spacing={1}>
      <Grid item xs={12}>
        { 
          infoCerts.map(cert => (
            <CardCert {...cert} handleSetCert={handleSetCert}/>
          ))
        }
      </Grid>
    </Grid>

    <ComparteRedes/>
    </>
    )
}