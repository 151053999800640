import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import logo1 from "./curso_TI.jpg";
import logo2 from "./curso_datos.jpg";
import logo3 from "./curso_gestionproyectos.jpg";
import logo4 from "./curso_UX.jpg";
import Grid from '@mui/material/Grid';
import "./css/card.css";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import iconoConfirmacion from './img/icono-conformacion.svg';
import flechaAnalista from './img/flecha_analisisdatos.svg';
import flechaGestion from './img/flecha_gestionproyectos.svg';
import flechaSoporte from './img/flecha_soporteIT.svg';
import flechaUx from './img/flecha_UX.svg'
import ComparteRedes from './components/ComparteRedes';

const MensajeCursoAceptado = ({icono}) => {
  return(
    <>
    <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
      No olvides activar tu curso con la invitación personalizada que recibiste de parte de Coursera por correo. 
      Revisa tus distintas bandejas.
    </Typography>
    <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
      Es importante que luego SIEMPRE te conectes a Coursera desde AQUÍ para estar al tanto 
      de todas las novedades que tendremos para ti.
    </Typography>
    <div className='boton-cursera'>
      <div><span>Acceso a Coursera</span></div>
      <div className='boton-cursera-boton'>
        <a href="https://www.coursera.org" target="_blank" rel="noopener noreferrer"><img src={icono} alt="Coursera"/></a>
      </div>
    </div>
    </>
  )
}



class MediaControlCard extends Component {

  state = {
    setOpen: false,
    open: false,
    c1: parseInt(sessionStorage.getItem('c1')),
    c2: parseInt(sessionStorage.getItem('c2')),
    c3: parseInt(sessionStorage.getItem('c3')),
    c4: parseInt(sessionStorage.getItem('c4')),

    c1General: sessionStorage.getItem('c1'),
    c2General: sessionStorage.getItem('c2'),
    c3General: sessionStorage.getItem('c3'),
    c4General: sessionStorage.getItem('c4'),

    c1Message: sessionStorage.getItem('c1Status'),
    c2Message: sessionStorage.getItem('c2Status'),
    c3Message: sessionStorage.getItem('c3Status'),
    c4Message: sessionStorage.getItem('c4Status'),

    c1Status: sessionStorage.getItem('c1Status'),
    c2Status: sessionStorage.getItem('c2Status'),
    c3Status: sessionStorage.getItem('c3Status'),
    c4Status: sessionStorage.getItem('c4Status'),
    solicitado: [false,false,false,false],
    estatus_beca: null
  }

  componentDidMount = () => {

    if(sessionStorage.getItem('c1') === 0){
      this.setState({ c1General: false})
      this.setState({ c1Status: sessionStorage.getItem('c1Status')})
    }

    if(sessionStorage.getItem('c2') === 0){
      this.setState({ c2General: false})
      this.setState({ c2Status: sessionStorage.getItem('c2Status')})
    }

    if(sessionStorage.getItem('c3') === 0){
      this.setState({ c3General: false})
      this.setState({ c3Status: sessionStorage.getItem('c3Status')})
    }

    if(sessionStorage.getItem('c4') === 0){
      this.setState({ c4General: false})
      this.setState({ c4Status: sessionStorage.getItem('c4Status')})
    }

    const validaEstatus = parseInt(sessionStorage.getItem('estatus_beca'))

    if(!validaEstatus &&  validaEstatus !== 0) {
      this.setState({estatus_beca:null})
    } else {
      this.setState({estatus_beca:parseInt(sessionStorage.getItem('estatus_beca'))})
    }
  }

  handleClose = event => {
    event.preventDefault()
    this.setState({ open:false})
  }

  handleClickOpen = event => {
    event.preventDefault()
    this.setState({ open:true})
}
  
  handleCertificados = (event) => {
    const id_certificado = parseInt(event.target.id);
    const data = {
      email: sessionStorage.getItem('email'),
      id_certificado: id_certificado
    }
    
    if(id_certificado === 1){
      this.setState(()=>({c1: 0, c1Message:'Seleccionado'}))
      this.setState(()=>({c2: 0, c2Message:'Bloqueado'}))
      this.setState(()=>({c3: 0, c3Message:'Bloqueado'}))
      this.setState(()=>({c4: 0, c4Message:'Bloqueado'}))
    }
    
    if(id_certificado === 2){
      this.setState(()=>({c1: 0, c1Message:'Bloqueado'}))
      this.setState(()=>({c2: 0, c2Message:'Seleccionado'}))
      this.setState(()=>({c3: 0, c3Message:'Bloqueado'}))
      this.setState(()=>({c4: 0, c4Message:'Bloqueado'}))
    }

    if(id_certificado === 3){
      this.setState(()=>({c1: 0, c1Message:'Bloqueado'}))
      this.setState(()=>({c2: 0, c2Message:'Bloqueado'}))
      this.setState(()=>({c3: 0, c3Message:'Seleccionado'}))
      this.setState(()=>({c4: 0, c4Message:'Bloqueado'}))
    }

    if(id_certificado === 4){
      this.setState(()=>({c1: 0, c1Message:'Bloqueado'}))
      this.setState(()=>({c2: 0, c2Message:'Bloqueado'}))
      this.setState(()=>({c3: 0, c3Message:'Bloqueado'}))
      this.setState(()=>({c4: 0, c4Message:'Seleccionado'}))
    }
    // Se cambia el estatus de beca a en espera
    this.setState(()=>({estatus_beca: 0}));
    sessionStorage.setItem('estatus_beca',0);

    axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/certificados/seleccion/', data , {
            auth: {
              username: sessionStorage.getItem('email'),
            }
          })
        .then(res => {
            this.setState({ open:true})
            
            sessionStorage.setItem('c1',res.data.certificados.c1);
            sessionStorage.setItem('c2',res.data.certificados.c2);
            sessionStorage.setItem('c3',res.data.certificados.c3);
            sessionStorage.setItem('c4',res.data.certificados.c4);
            sessionStorage.setItem('formulario',res.data.formulario);
        })
        .catch(error => {
            event.preventDefault()
            console.error(error)
            this.setState({ open:false})
        })
  }

 
  
  render(){
    return (
    <>
    <Modal open={this.state.open} onClose={this.handleClose} >
      <Stack spacing={5} alignItems="center" style={{marginTop: "5%"}} id="modal-confirmacion" >
        <br></br><br></br>
      <Typography variant="h4" color="text.secondary">
            ¡Gracias por completar tu solicitud!            
          </Typography>
          <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" src={iconoConfirmacion}>
            Confirmado
          </Avatar>
          <Typography variant="h6" color="text.secondary">
            Inroads la revisará y te norificará en el trancurso de la semana en este mismo portal si tu beca fue otorgada.
          </Typography>
          <br></br>
      </Stack>  
    </Modal>
    {/* this.state.c1 || this.state.c2  || this.state.c3 || this.state.c4 */}
    { this.state.estatus_beca === null ?
      <div style={{textAlign:'justify', marginTop:'15px', marginBottom:'25px'}}>
        <h3>¡Gracias por completar el formulario de solicitud de beca!</h3>
        <hr />
        <p>Selecciona ahora el Certificado de Carrera de Google con el cual quieres aplicar.</p>
      </div> : <></>
     }
     { this.state.estatus_beca === 0 ?
      <div style={{textAlign:'justify', marginTop:'15px', marginBottom:'25px'}}>
        <h3>Tu solicitud se encuentra en lista de espera...</h3>
        <hr />
        <p>Gracias por pedir una beca para los Certiciados de Carrera de Google <br />
          Tu solicitud esta en lista de espera. Si llega a desocuparse algún espacio, te contactaremos a la brevedad. Mientras tanto, te invitamos a seguirnos
          en nuestras redes sociales, para enterarte de todos los beneficios que aún podemos ofrecerte.
        </p>
        <p>
          Agradecemos tu tiempo y participación en este proceso.
        </p>
      </div> : <></>
    }
    { this.state.estatus_beca === 1 ?
      <div style={{textAlign:'justify', marginTop:'15px', marginBottom:'40px'}}>
        <h3>¡Felicidades!</h3>
        <hr />
        <p>¡Tu beca ha sido otorgada. Empieza ahora tu camino al éxito!</p>
        <div>
          <iframe width="100%" height="350px"
            src="https://www.youtube.com/embed/YKXLgI8cTLo" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </div>

      </div> : <></>
     }
    <Grid container spacing={1} className='cards-no-movil'>
        <Grid item xs={12}>
        { this.state.estatus_beca === null || this.state.c4Message === "Seleccionado" ?
        <Box className="card-cert">
          <Card sx={{ minWidth: 460 , minHeight: 300, display:'flex' }}>
          <Stack spacing={2} justifyContent="flex-start" alignItems="center">
            <CardHeader
              title="Certificado en Soporte TI"
              className={`soporte-ti`}
              style = {{textAlign: "center!important", color: "white"}}
            />
            <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="Soporte" src={logo1}>
                  soporte
                </Avatar>
            </Stack>

            <CardContent>
              { this.state.estatus_beca === 1 && this.state.c4Message === "Seleccionado" ? 
              <MensajeCursoAceptado icono={flechaSoporte}/>: 
              <>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los especialistas de TI solucionan problemas para que
                  las computadoras y las redes funcionen correctamente.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Ponte en marcha para conseguir un empleo en
                  demanda en el sector de TI con un certificado
                  profesional de Google. Aprende las bases de las redes
                  y los sistemas operativos, o cómo solucionar
                  problemas mediante el uso de código.
                  <br></br>
                  {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión
              </Typography>
              <br></br>
              { this.state.c4 ? <Button variant='contained' id="4" onClick={this.handleCertificados}> {this.state.c4Message} </Button> : <Button variant='contained' disabled id="4" onClick={this.handleCertificados}>{this.state.c4Message}</Button>}
              </>}
            </CardContent>
          </Card>  
        </Box> : <></>
        }
        { this.state.estatus_beca === null || this.state.c1Message === "Seleccionado" ?
        <Box className="card-cert">
            <Card sx={{ minWidth: 460 , minHeight: 300, display:'flex' }}>
            <Stack spacing={2} justifyContent="flex-start" alignItems="center">
              <CardHeader
                title="Certificado en Análisis de datos"
                className={`analisis`}
                style = {{textAlign: "center!important", color: "white"}}
              />
              <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="Datos" src={logo2}>
                Datos
              </Avatar>
            </Stack>
              <CardContent>
              { this.state.estatus_beca === 1 && this.state.c1Message === "Seleccionado" ? 
              <MensajeCursoAceptado icono={flechaAnalista}/>: 
              <>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los analistas de datos recopilan, transforman y
                  organizan los datos para contribuir en la toma de
                  decisiones comerciales informadas.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Da tus primeros pasos en una carrera de análisis de datos, que experimenta un alto nivel de crecimiento, con
                  un certificado profesional de Google. Incorpora las
                  habilidades requeridas que te prepararán para el trabajo, por ejemplo, cómo analizar y procesar datos
                  para obtener perspectivas clave sobre el negocio.
                  <br></br>
                  {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
              </Typography>
              <br></br>
              { this.state.c1 ? <Button variant='contained' id="1" onClick={this.handleCertificados}>{this.state.c1Message} </Button> : <Button variant='contained' disabled id="1" onClick={this.handleCertificados}>{this.state.c1Message}</Button>}
              </>}
              </CardContent>
            </Card>  
          </Box> : <></>
          }
          { this.state.estatus_beca === null || this.state.c3Message === "Seleccionado" ?
          <Box className="card-cert">
          <Card sx={{ minWidth: 460 , minHeight: 300, display:'flex' }}>
          <Stack spacing={2} justifyContent="flex-start" alignItems="center">
            <CardHeader
              title="Certificado en Gestión de Proyectos"
              className={`gestion`}
              style = {{textAlign: "center!important", color: "white"}}
              />
              <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="Proyectos" src={logo3}>
                  Proyectos
              </Avatar>
            </Stack>
            <CardContent>
            { this.state.estatus_beca === 1 && this.state.c3Message === "Seleccionado" ? 
              <MensajeCursoAceptado icono={flechaGestion} />: 
              <>
            <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                Los gerentes de proyectos se aseguran de que los 
                proyectos de una organización se administren y
                completen con el máximo valor.
              </Typography>
            <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                Da tus primeros pasos en una carrera en administración
                de proyectos, que experimenta un alto nivel de crecimiento,
                con un certificado profesional de Google.
                Averigua cómo administrar proyectos de manera
                eficiente y efectiva mediante el uso de métodos
                tradicionales y ágiles.
                <br></br>
                {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                Cupo limitado, INROADS se reserva el derecho de admisión.
            </Typography>
            <br></br>
            { this.state.c3 ? <Button variant='contained' id="3" onClick={this.handleCertificados}>{this.state.c3Message} </Button> : <Button variant='contained' disabled id="3" onClick={this.handleCertificados}>{this.state.c3Message}</Button>}
            </>}
            </CardContent>
          </Card>  
        </Box>: <></>
        }
        { this.state.estatus_beca === null || this.state.c2Message === "Seleccionado" ?
        <Box className="card-cert">
            <Card sx={{ minWidth: 460 , minHeight: 300, display:'flex'}}>
            <Stack spacing={2} justifyContent="flex-start" alignItems="center">
              <CardHeader
                title="Certificado en Diseño de experiencia del Usuario(UX)"
                className={`ux`}
                style = {{textAlign: "center!important", color: "white"}}
              />
              <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="UX" src={logo4}>
                    UX
              </Avatar>
              </Stack>
              <CardContent>
                { this.state.estatus_beca === 1 && this.state.c2Message === "Seleccionado" ? 
                <MensajeCursoAceptado icono={flechaUx} />: 
                <>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                    Los analistas de datos recopilan, transforman y
                    organizan los datos para contribuir en la toma de
                    decisiones comerciales informadas.
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Incorpora los apectos básicos del diseño de UX: cómo
                  lograr empatía con los usuarios, crear wireframes y
                  prototipos, y realizaar investigaciones para probar los
                  diseños.
                  <br></br>
                  {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
                </Typography>
                <br></br>
                { this.state.c2 ? <Button variant='contained' id="2" onClick={this.handleCertificados}>{this.state.c2Message} </Button> : <Button variant='contained' disabled id="2" onClick={this.handleCertificados}>{this.state.c2Message}</Button>}
                </>}
              </CardContent>
            </Card>  
          </Box>: <></>
          }
          {this.state.estatus_beca === 1 &&
          <ComparteRedes />
          }
          </Grid>
      </Grid>
      {/* Inicia parte movil */}
      <div className='cards-movil' style={{flexDirection:'column'}}>
        { this.state.estatus_beca === null || this.state.c4Message === "Seleccionado" ?
        <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#4285F4', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'15%'}}>
                  Certificado en Soporte TI
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo1} style={{width:'100%'}} alt="logo1"/>
              </div>
            </div>
            <CardContent>
            { this.state.estatus_beca === 1 && this.state.c4Message === "Seleccionado" ? 
              <MensajeCursoAceptado icono={flechaSoporte}/>: 
              <>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los especialistas de TI solucionan problemas para que
                  las computadoras y las redes funcionen correctamente.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Ponte en marcha para conseguir un empleo en
                  demanda en el sector de TI con un certificado
                  profesional de Google. Aprende las bases de las redes
                  y los sistemas operativos, o cómo solucionar
                  problemas mediante el uso de código.
                  <br></br>
                  {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión
              </Typography>
              <br></br>
              { this.state.c4 ? <Button variant='contained' id="4" onClick={this.handleCertificados}> {this.state.c4Message} </Button> : <Button variant='contained' disabled id="4" onClick={this.handleCertificados}>{this.state.c4Message}</Button>}
              </>}
            </CardContent>
         </Card>: <></>
        }
        { this.state.estatus_beca === null || this.state.c1Message === "Seleccionado" ?
         <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#E84335', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'13%'}}>
                  Certificado en Análisis de datos
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo2} style={{width:'100%'}} alt="logo2"/>
              </div>
            </div>
            <CardContent>
              { this.state.estatus_beca === 1 && this.state.c1Message === "Seleccionado" ? 
              <MensajeCursoAceptado icono={flechaAnalista} />: 
              <>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los analistas de datos recopilan, transforman y
                  organizan los datos para contribuir en la toma de
                  decisiones comerciales informadas.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Da tus primeros pasos en una carrera de análisis de datos, que experimenta un alto nivel de crecimiento, con
                  un certificado profesional de Google. Incorpora las
                  habilidades requeridas que te prepararán para el trabajo, por ejemplo, cómo analizar y procesar datos
                  para obtener perspectivas clave sobre el negocio.
                  <br></br>
                  {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
              </Typography>
              <br></br>
              { this.state.c1 ? <Button variant='contained' id="1" onClick={this.handleCertificados}>{this.state.c1Message} </Button> : <Button variant='contained' disabled id="1" onClick={this.handleCertificados}>{this.state.c1Message}</Button>}
              </>}
            </CardContent>
         </Card> : <></>
        }
         { this.state.estatus_beca === null || this.state.c3Message === "Seleccionado" ?
         <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#E6ac27', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'13%'}}>
                  Certificado en Gestión de Proyectos
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo3} style={{width:'100%'}} alt="logo3"/>
              </div>
            </div>
            <CardContent>
            { this.state.estatus_beca === 1 && this.state.c3Message === "Seleccionado" ? 
              <MensajeCursoAceptado icono={flechaGestion} />: 
              <>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los gerentes de proyectos se aseguran de que los 
                  proyectos de una organización se administren y
                  completen con el máximo valor.
                </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Da tus primeros pasos en una carrera en administración
                  de proyectos, que experimenta un alto nivel de crecimiento,
                  con un certificado profesional de Google.
                  Averigua cómo administrar proyectos de manera
                  eficiente y efectiva mediante el uso de métodos
                  tradicionales y ágiles.
                  <br></br>
                  {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
              </Typography>
              <br></br>
              { this.state.c3 ? <Button variant='contained' id="3" onClick={this.handleCertificados}>{this.state.c3Message} </Button> : <Button variant='contained' disabled id="3" onClick={this.handleCertificados}>{this.state.c3Message}</Button>}
              </>}
            </CardContent>
         </Card>: <></>
        }
         { this.state.estatus_beca === null || this.state.c2Message === "Seleccionado" ?
         <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#1F8839', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'13%'}}>
                  Certificado en Diseño de experiencia del Usuario(UX)
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo4} style={{width:'100%'}} alt="logo4"/>
              </div>
            </div>
            <CardContent>
            { this.state.estatus_beca === 1 && this.state.c2Message === "Seleccionado" ? 
              <MensajeCursoAceptado icono={flechaUx} />: 
                <>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                    Los analistas de datos recopilan, transforman y
                    organizan los datos para contribuir en la toma de
                    decisiones comerciales informadas.
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Incorpora los apectos básicos del diseño de UX: cómo
                  lograr empatía con los usuarios, crear wireframes y
                  prototipos, y realizaar investigaciones para probar los
                  diseños.
                  <br></br>
                  {this.state.estatus_beca === null ? <>No pierdas más tiempo y solicita tu beca.</>:<></>}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
                </Typography>
                <br></br>
                { this.state.c2 ? <Button variant='contained' id="2" onClick={this.handleCertificados}>{this.state.c2Message} </Button> : <Button variant='contained' disabled id="2" onClick={this.handleCertificados}>{this.state.c2Message}</Button>}
                </>}
            </CardContent>
         </Card>: <></>
        }
        {this.state.estatus_beca === 1 &&
          <ComparteRedes />
        }
      </div>
    </>
    )
  }
}

export default MediaControlCard