import React from 'react';
import RedesSociales from './RedesSociales';
import "../css/card.css";
import comparte from '../img/comparte.svg';

export default function ComparteRedes () {
    return(
      <div className='container-redes'>
        <div className='container-redes_img'>
            <img src={comparte} style={{width:'100%'}} alt="compartir" />
        </div>
        <div className='container-redes_mensaje'>
            <p>Comparte esta gran noticia e invita a tus amigos a solicitar una beca tambien.</p>
            <RedesSociales />
        </div>
      </div>
    )
}