import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MediaControlCard from './Card';
import BasicTimeline from './Timeline';
import logo from "./atencion.svg";
import "./css/landing_body.css";

export default function BasicGrid() {
  return (
    <Box sx={{ borderColor: 'primary.main' }}>
      <Grid className='grid-paper' container spacing={3} >
        <Grid item xs={2}></Grid>
        <Grid item xs={5} className="font-landing font-color">
        <div style={{marginTop:'20px'}}>
            <iframe width="100%" height="500px"
              src="https://www.youtube.com/embed/LewAEmdfpW8" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
        </div>
        <div style={{marginTop: "10%"}}>
                <p style={{textAlign:"left"}}>
                Los Certificados de Carrera de Google son programas de formación online flexibles y
                    diseñados para adquirir competencias profesionales en campos con una demanda
                    elevada y gran crecimiento. Desarrolla tus habilidades a través de actividades en un
                    laboratorio virtual y otras evaluaciones interactivas que pondrán a prueba tus
                    conocimientos.
                <br></br>
                <span style={{textAlign:'left'}}>
                *Las personas que realicen estos cursos no necesitan experiencia previa en estos temas</span>
                </p>
                <br />
                <p style={{fontSize:"25px", marginBottom:"0px"}}>
                  Beca para Certificado de <span style={{fontWeight:"bold"}}>Carrera de Google</span>
                </p>
                <hr></hr>
                <p style={{textAlign:'left'}}>
                    Con esta beca podrás acceder sin costo a la plataforma de Coursera para realizar los cursos y mejorar tus perspectivas laborales a corto plazo.
                    <br></br>
                    Si quieres obtener un Certificado de Carrera de Google y necesitas de una beca, puedes 
                    solicitarla para realizar uno de los siguientes cursos online en español:
                </p>
                <MediaControlCard></MediaControlCard>
                <br />
                <br />
                <br />
                <div className='row font-landing'>
                  <div className="column1"><img src={logo} alt="Atencion" style={{ maxWidth: '50px' }}/></div>
                  <div className="column2" style={{ maxHeight: '75px' }}><p>Deberás seleccionar uno de los 4 cursos para poder solicitar la beca. En caso de que
                    quieras realizar más cursos, deberás primero concluir el curso al que estas inscrito.</p></div>
                </div> 
                <br />
                <br />
                <br />
                <Typography variant="h4" style={{textAlign:"left",fontWeight:"lighter", fontSize:"25px"}}>
                    Requisitos
                </Typography>
                <ul style={{textAlign:"left"}} className={"font-landing"}>
                    <li>Estudio mínimo de preparatoria terminada.</li>
                    <li>Acceso a computadora, Tablet o dispositivo electrónico y a Internet.</li>
                    <li>Disponibilidad de tiempo (Considera que cada curso requiere de alrededor de 10 horas semanales para concluirlo en 6 meses).</li>
                    <li>Ser una persona con ganas de crecer y desarrollarse, y con interés de aprender en temas relacionados a las becas ofrecidas, cambiar de área laboral, obtener un empleo, una promoción, emprender o impulsar su carrera profesional.</li>
                    <li>Compromiso de concluir al menos uno de los 4 cursos antes del 31 de marzo de 2023.</li>
                </ul>
            </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3} className="font-landing"  style={{ background:'#f3f3f3' }}>
          <BasicTimeline />
        </Grid>
      </Grid>
      <Grid className='grid-paper-mobil' container spacing={1} >
        <Grid item xs={12} className="font-landing font-color">
        <div style={{marginTop: "-30px", padding:"10px 40px"}}>
              <div style={{marginTop: "10px"}}>
                    <iframe width="100%" height="auto"
                      src="https://www.youtube.com/embed/LewAEmdfpW8" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
              </div>

                <p style={{textAlign:"left"}}>
                Los certificados de Carrera de Google son programas de formación online flexibles y
                    diseñados para adquirir competencias profesionales en campos con una demanda
                    elevada y gran crecimiento. Desarrolla tus habilidades a través de actividades en un
                    laboratorio virtual y otras evaluaciones interactivas que pondrán a prueba tus
                    conocimientos.
                <br></br>
                <span style={{textAlign:'left'}}>
                *Las personas que realicen estos cursos no necesitan tener formación o experiencia previa.</span>
                </p>
                <br />
                <p style={{fontSize:"25px", marginBottom:"0px"}}>
                  Beca para Certificado de <span style={{fontWeight:"bold"}}>Carrera Google</span>
                </p>
                <hr></hr>
                <p style={{textAlign:'left'}}>
                    Con esta beca podrás acceder sin costo a la plataforma de Coursera para realizar los cursos y mejorar tus perspectivas laborales a corto plazo.
                    <br></br>
                    Si quieres obtener un Certificado de Carrera de Google y necesitas de una beca, puedes 
                    solicitarla para realizar uno de los siguientes cursos online en español:
                </p>
                <MediaControlCard></MediaControlCard>
                <div className='row font-landing aviso-margin'>
                  <div className="column1"><img src={logo} alt="Atencion" style={{ maxWidth: '50px' }}/></div>
                  <div className="column2" style={{ maxHeight: '75px' }}><p>Deberás seleccionar uno de los 4 cursos para poder solicitar la beca. En caso de que
                    quieras realizar más cursos, deberás primero concluir el curso al que estas inscrito.</p></div>
                </div> 
                <Typography variant="h4" style={{textAlign:"left",fontWeight:"lighter", fontSize:"25px"}}>
                    Requisitos
                </Typography>
                <ul style={{textAlign:"left"}} className={"font-landing"}>
                    <li>Estudio mínimo de preparatoria terminada.</li>
                    <li>Acceso a computadora, Tablet o dispositivo electrónico y a Internet.</li>
                    <li>Disponibilidad de tiempo (Considera que cada curso requiere de alrededor de 10 horas semanales para concluirlo en 6 meses).</li>
                    <li>Ser una persona con ganas de crecer y desarrollarse, y con interés de aprender en temas relacionados a las becas ofrecidas, cambiar de área laboral, obtener un empleo, una promoción, emprender o impulsar su carrera profesional.</li>
                    <li>Compromiso de concluir al menos uno de los 4 cursos antes del 31 de marzo de 2023.</li>
                </ul>
            </div>
        </Grid>
      </Grid>
    </Box>
  );
}