import React,{useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './css/landing_body.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import ameInd from './img/colaboradores/American indus.png';
import aveNegro from './img/colaboradores/AVE_MARCA EN BLANCO.png';
import bancoAzteca from './img/colaboradores/BANCO-AZTECA.png';
import danone from './img/colaboradores/Danone.png';
import elektra from './img/colaboradores/Elektra.png';
import engraneDigital from './img/colaboradores/Engrane Digital.png';
import gnc2 from './img/colaboradores/GNC.png';
import grupoSalinas from './img/colaboradores/grupo_salinas.png';
import hotelesCity from './img/colaboradores/Hoteles City.png';
import italika from './img/colaboradores/Italika.png';
import justo from './img/colaboradores/JUSTO.png';
import prixz from './img/colaboradores/PRIXZ.png';
import totalPlay from './img/colaboradores/TotalPlay.png';
import tvAzteca from './img/colaboradores/TV_AZTECA.png';
import adbid from './img/colaboradores/logo_ad_bid.png'


export default function Collaborators() {

    const slidesToShow = useMediaQuery('screen and (max-width: 1400px)') ? 1 : 5;
    const [estatusBeca] = useState(parseInt(sessionStorage.getItem('estatus_beca')));

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 6000,
        autoplaySpeed: 0,
        swipe: false,
        cssEase: "linear"
    };

    return(
        <div className='content-collaborators'>
            <div className='content-header-collaborators'>
                <span>
                    ¡Acaba tu certificado y podrás acceder a nuestra plataforma de empleo!
                </span> 
                <br />
                <span>
                    Conoce a nuestros principales aliados.
                </span>
                <br />
                <span>
                    Ellos están buscando gente como tú, dales la oportunidad de conocerte.
                </span>
            </div>
            <div className='content-carrusel'>
                <Slider {...settings}>
                    <div className='content-carrusel_item'>
                        <img src={ameInd} alt="ameInd" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={aveNegro} alt="aveNegro" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={bancoAzteca} alt="bancoAzteca" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={danone} alt="danone" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={elektra} alt="elektra" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={engraneDigital} alt="engraneDigital" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={gnc2} alt="gnc2" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={grupoSalinas} alt="grupoSalinas" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={hotelesCity} alt="hotelesCity" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={italika} alt="italika" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={justo} alt="justo" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={prixz} alt="prixz" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={totalPlay} alt="totalPlay" width={160}/>
                    </div>
                    <div className='content-carrusel_item'>
                        <img src={tvAzteca} alt="tvAzteca" width={160}/>
                    </div> 
                    <div className='content-carrusel_item'>
                        <img src={adbid} alt="adbid" width={160}/>
                    </div>
                </Slider>
            </div>
            

            { estatusBeca === 3 &&
            <div className='content-collaborators-button'>
                <p>Únete próximamente a nuestra plataforma de empleo</p>
                <a href='https://bewanted.com/landings/google_inroads_candidatos'>Ingresa aquí</a>
            </div>
            }
        </div>
    );
};