import React, {useState,useEffect} from 'react';
import axios from 'axios';
import AppBar from './AppBarHome';
import StickyFooter from "./StickyFooter";
import { Modal } from 'react-responsive-modal';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./css/adminStyle.css";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

export default function HistorialAprobacion() {

    const [historial, setHistorial] = useState([]);
    const [modal, setModal] = useState(false);
    const [idDocumento, setIdDocumento] = useState(null);
    const [check, setCheck] = useState(false);
    const [activaBoton, setActivaBoton] = useState(false);
    const [refresca, setRefresca] = useState(0);

    useEffect(() => {
        axios.get('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/becas/historial/',{ auth: {
             username: sessionStorage.getItem('email')
            }
        })
        .then((response) => {
            setHistorial(response.data.historial);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refresca]);

    const handleAbreModal = (idDocumento) => {
        setCheck(true);
        setIdDocumento(idDocumento);
        setModal(true);
    };

    const handleLimpiaDatosModal = () => {
        setModal(null);
        setIdDocumento(null);
        setCheck(false);
    };

    const handleChangueMensajeValidacion = (event) => {
        const textoValida = event.target.value;
        if(textoValida === "Si, acepto") {
            setActivaBoton(true);
        } else {
            setActivaBoton(false);
        }
    }

    const handleEnviarInformacion = () => {

        axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/becas/historial_confirmacion/',{idDocumento},{auth: {
             username: sessionStorage.getItem('email')
            }
        })
        .then((response) => {
            setIdDocumento(null);
            setCheck(false);
            setModal(false);
            setActivaBoton(false);
            setRefresca((refresca) => refresca ? 0:1);
        })
        .catch((error) => {
            console.error(error);
        });

    };

    // Estilos para tabla
    const StyledTableCell = styled(TableCell)(({backgroundColor}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor,
            fontSize: 17,
            color: '#fff',
            padding: '25px 0px',
            border: 'solid 1px black',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            padding: '10px 0px',
            border: 'solid 1px black',
        },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        
    }));
    
    const TablaHistorial = ({colorHead, data}) => (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell backgroundColor={colorHead} align="center"><div></div></StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Número de personas</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Fecha de creación</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Fecha de carga</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {data ? data.map((row) => (
                    <StyledTableRow key={row.idDocumento} >
                        <StyledTableCell align="center" style={{width:"10%"}}>
                            <a href={row.linkDocumento}>
                                <ArticleOutlinedIcon  sx={{ color: 'black' }} fontSize="large"/>
                            </a>
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.numPersonas}</StyledTableCell>
                        <StyledTableCell align="center">{row.fechaCreacion}</StyledTableCell>
                        <StyledTableCell align="center">
                            {
                                row.fechaCarga ? row.fechaCarga : 
                                <div>
                                    <Checkbox checked={check}
                                     onChange={()=>handleAbreModal(row.idDocumento)}
                                     />Confirmación
                                </div>
                            }
                        </StyledTableCell>
                    </StyledTableRow>
                )): <></>}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
    // -- Fin de estilos para tabla
    
    const ModalConfirmacion = () => (
        <Modal open={modal} onClose={handleLimpiaDatosModal} >
            <DialogContent>
                <DialogContentText >
                    <div className='dialog'>
                        <h2>¡Alerta!</h2>
                        <div>
                            <p>Al verificar esta casilla, confirmas su carga a Coursera <br />
                            y dicha acción no es reversible.</p>
                        </div>
                        <div>
                            Escribe el mensaje de confirmación <p></p>
                            <TextField id="mensajeValidacion" 
                                variant="outlined"
                                placeholder="Si, acepto"
                                onChange={handleChangueMensajeValidacion}
                            />
                            <div className='dialog-button form-button-background-morado'>
                                <Button 
                                    variant="contained" 
                                    size="large"
                                    disabled={!activaBoton}
                                    onClick={handleEnviarInformacion}
                                >Aceptar</Button>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    return (
        <>
            <AppBar/>
            <ModalConfirmacion />
            <div className='card-main'>
                <h1>Historial <br /> de versiones</h1>
                <hr />
                <div className="form-table">
                    <TablaHistorial  colorHead={'#4e2378'} data={historial}/>
                </div>
            </div>
            <StickyFooter />
        </>
    );
}               