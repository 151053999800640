import React, {useState,useEffect} from 'react';
import CSVFileValidator from 'csv-file-validator'
import axios from 'axios';
import AppBar from './AppBarHome';
import StickyFooter from "./StickyFooter";
import { Modal } from 'react-responsive-modal';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./css/adminStyle.css";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function AdminAlumCert() {

    const [modal, setModal] = useState(false);
    const [csv, setCsv] = useState(null);
    const [muestraErrores, setMuestraErrores] = useState(false);
    const [certificados, setCertificados] = useState([]);
    const [activaBoton, setActivaBoton] = useState(false);
    const [refrescar, setRefrescar] = useState(false);

    // Para control de mensajes de exito y error
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    // Fin control de mensajes

    const Input = styled('input')({
        display: 'none',
    });

    // Expresiones para validaciones
    const regexTexto = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    const regexCorreo = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const regexFecha = /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})(\s)([0-1][0-9]|2[0-3])(:)([0-5][0-9])$/;
    const regexUrl = /^(http|https):\/\/[^ "]+$/;
    const regexEntero = /^[0-9]+$/;
    const regexDecimal = /^[0-9]+([.,][0-9]+)?$/;

    useEffect(() => {
        axios.get('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/fin_certificado/historial_reportes_coursera/',{auth: {
             username: sessionStorage.getItem('email'), password: sessionStorage.getItem("token")
            }
        })
        .then((response) => {
            console.log("Response: ", response);
           setCertificados(response.data.historial);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refrescar]);

    // Metodos para validaciones
    const isTexto = (texto) => (regexTexto.test(texto) && texto.length < 101);
    const isCorreo = (correo) => (regexCorreo.test(correo) && correo.length < 101);
    const isFecha = (fecha) => (regexFecha.test(fecha) && fecha.length < 101);
    const isUrl = (url) => (regexUrl.test(url) && url.length < 101);
    const isEntero = (entero) => (regexEntero.test(entero) && entero.length < 14);
    const isDecimal = (decimal) => (regexDecimal.test(decimal) && decimal.length < 14);

    // Mensaje para errores
    const requiredError = (headerName, rowNumber, columnNumber) => {
        return `<div>${headerName} es requerido en la <strong>fila ${rowNumber}</strong> / <strong>columna ${columnNumber}</strong></div>`
    }
    
    const validateError = (headerName, rowNumber, columnNumber) => {
        return `<div>La <strong>columna ${headerName}</strong> no tiene un valor valido o supera el máximo número de caractares en la <strong>fila ${rowNumber}</strong></div>`
    }

    // Configuracion para validacion de csv
    const CSVConfig = {
        headers: [
            { name: 'Nombre', inputName: 'nombre', required: true, requiredError, validate: isTexto, validateError },
            { name: 'Correo', inputName: 'correo', required: true, requiredError, validate: isCorreo, validateError },
            { name: 'External ID', inputName: 'externalID', required: true, requiredError, validate: isEntero, validateError },
            { name: 'Certificado', inputName: 'certificado', required: true, requiredError, validate: isTexto, validateError },
            { name: 'Fecha de inscripción', inputName: 'fechaIncripcion', required: true, requiredError, validate: isFecha, validateError },
            { name: 'Completado', inputName: 'completado', required: true, requiredError, validate: isTexto, validateError },
            { name: 'Progreso', inputName: 'progreso', required: true, requiredError, validate: isDecimal, validateError },
            { name: 'Horas de aprendizaje', inputName: 'horasAprendizaje', required: true, requiredError, validate: isDecimal, validateError },
            { name: 'Calificación Certificado', inputName: 'calificacionCertificado', required: true, requiredError, validate: isDecimal, validateError },
            { name: 'Fecha de finalización', inputName: 'fechaFinalizacion', required: true, requiredError, validate: isFecha, validateError },
            { name: 'URL del certificado Final', inputName: 'urlCertificadoFinal', required: true, requiredError, validate: isUrl, validateError }
        ],
        isHeaderNameOptional: true,
        parserConfig: {
            encoding: "windows-1258",
        }
    };

    const validaFormatoCSV = (e) => {
        setMuestraErrores(false);
        if(e.target.files[0].type === "text/csv") {
            CSVFileValidator(e.target.files[0], CSVConfig)
		    .then(csvData => {
                if(csvData.inValidMessages.length > 0) {
                    setMuestraErrores(true);
                    csvData.inValidMessages.forEach(message => {
                        if(message.includes("Number of fields mismatch")){
                             message = `<div>La estructura del archivo no es valido</div>`
                        }
                        document.getElementById('invalidMessages').insertAdjacentHTML('beforeend', message)
                    });
                } else {
                    setMuestraErrores(false);
                    setCsv(e.target.files[0]);
                    setModal(true);
                }
		    })           
        }
    };

    const handleLimpiaDatosModal = () => {
        setModal(false);
        setActivaBoton(false);
        setCsv(null);
    };

    const handleChangueMensajeValidacion = (event) => {
        const textoValida = event.target.value;
        if(textoValida === "Si, acepto") {
            setActivaBoton(true);
        } else {
            setActivaBoton(false);
        }
    };

    const handleEnviarInformacion = () => {
        const formData = new FormData();
        formData.append('reporte_coursera', csv);

        setActivaBoton(false);

        axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/fin_certificado/upload_reporte_coursera/',formData,
        {
            auth: {
                username: sessionStorage.getItem('email'), 
                password: sessionStorage.getItem("token")
            }
        })
        .then((response) => {
            if(response.data.estatus === 1) {
                setModal(false);
                setRefrescar(refrescar?false:true);
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se cargo correctamente el archivo.",
                    tipoMensaje:"success"
                });
            } else {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Ocurrio un error al cargar el archivo.",
                    tipoMensaje:"error"
                });
            }
        })
        .catch((error) => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Ocurrio un error al cargar el archivo.",
                tipoMensaje:"error"
            });
            console.error("Error en la petición ",error);
        });
    };

    // Estilos para tabla
    const StyledTableCell = styled(TableCell)(({backgroundColor}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor,
            fontSize: 17,
            color: '#fff',
            padding: '25px 0px',
            border: 'solid 1px black',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            padding: '10px 0px',
            border: 'solid 1px black',
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        
    }));

    const TablaHistorial = ({colorHead, data}) => (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell backgroundColor={colorHead} align="center">Nombre archivo</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Archivo con registro cargados</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Archivo con registro erroneos</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Archivo Original</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Número de personas cargadas</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Número de personas no cargadas</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Número de personas totales</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Fecha de creación</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {data ? data.map((row) => (
                    <StyledTableRow key={row.idDocumento} >
                        <StyledTableCell align="center">{row.file_name}</StyledTableCell>
                        <StyledTableCell align="center" style={{width:"10%"}}>
                            <a href={row.linkDocumentoOk}>
                                <ArticleOutlinedIcon  sx={{ color: 'black' }} fontSize="large"/>
                            </a>
                        </StyledTableCell>
                        <StyledTableCell align="center" style={{width:"10%"}}>
                            <a href={row.linkDocumentoBad}>
                                <ArticleOutlinedIcon  sx={{ color: 'black' }} fontSize="large"/>
                            </a>
                        </StyledTableCell>
                        <StyledTableCell align="center" style={{width:"10%"}}>
                            <a href={row.linkDocumentoTotal}>
                                <ArticleOutlinedIcon  sx={{ color: 'black' }} fontSize="large"/>
                            </a>
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.numPersonasOk}</StyledTableCell>
                        <StyledTableCell align="center">{row.numPersonasBad}</StyledTableCell>
                        <StyledTableCell align="center">{row.numPersonasTotal}</StyledTableCell>
                        <StyledTableCell align="center">{row.fechaCarga}</StyledTableCell>
                    </StyledTableRow>
                )): <></>}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
    // -- Fin de estilos para tabla

    const ModalConfirmacion = () => (
        <Modal open={modal} onClose={handleLimpiaDatosModal} >
            <DialogContent>
                <DialogContentText >
                    <div className='dialog'>
                        <h2>¡Alerta!</h2>
                        <div>
                            <p>Al verificar esta casilla, confirmas la carga de información <br />
                            y dicha acción no es reversible.</p>
                        </div>
                        <div>
                            Escribe el mensaje de confirmación <p></p>
                            <TextField id="mensajeValidacion" 
                                variant="outlined"
                                placeholder="Si, acepto"
                                onChange={handleChangueMensajeValidacion}
                            />
                            <div className='dialog-button form-button-background-morado'>
                                <Button 
                                    variant="contained" 
                                    size="large"
                                    disabled={!activaBoton}
                                    onClick={handleEnviarInformacion}
                                >Aceptar</Button>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    return (
        <>
            <AppBar/>
            <ModalConfirmacion />
            <div className='card-main'>
                <h1>Módulo de administración de <br /> alumnos certificados</h1>
                <hr />
                <div className="form-table">
                    <TablaHistorial  colorHead={'#4e2378'} data={certificados}/>
                </div>
                <div className='form-button form-button-background-morado'>
                    <label htmlFor="contained-button-file">
                        <Input accept=".csv" id="contained-button-file" multiple type="file" onChange={validaFormatoCSV} />
                        <Button variant="contained" component="span">
                            Carga CSV de Coursera
                        </Button>
                    </label>
                </div>
                {muestraErrores &&
                    <div className='caja-mensaje-errores'>
                        <h3>Errores encontrados en el archivo:</h3>
                        <div id='invalidMessages'>
                        </div>
                    </div>
                }
            </div>
            <Snackbar
                open={mensaje.mostrarMensaje}
                autoHideDuration={3000}
                onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
            >
                <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
            </Snackbar>
            <StickyFooter />
        </>
    );

}