import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import ResponsiveDialog from './DailogRegister';
import "./css/landing_body.css";

class Footer extends Component {
    render() {
        return(
            <div className="footer">
                <Stack  direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                >
                {/* Fin de certificado
                <span className="font-footer">No pierdas más tiempo</span>
                <ResponsiveDialog />
                */}
                </Stack>
            </div>
        )
    }
}

export default Footer  