import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import "../css/card.css";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import 'react-responsive-modal/styles.css';



const MensajeCursoAceptado = ({icono}) => {
    return(
      <>
      <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
        No olvides activar tu curso con la invitación personalizada que recibiste de parte de Coursera por correo. 
        Revisa tus distintas bandejas.
      </Typography>
      <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
        Es importante que luego SIEMPRE te conectes a Coursera desde AQUÍ para estar al tanto 
        de todas las novedades que tendremos para ti.
      </Typography>
      <div className='boton-cursera'>
        <div><span>Acceso a Coursera</span></div>
        <div className='boton-cursera-boton'>
          <a href="https://www.coursera.org" target="_blank" rel="noopener noreferrer"><img src={icono} alt="Coursera"/></a>
        </div>
      </div>
      </>
    )
}

export default function CardCert({idCert, titleCert, classCert, statusCert, messageCert,logoCert, contentCert, iconCert, handleSetCert}) {
    
    const certFinishStyle = classCert + " cert-finish card-cert";
    
    return(
    <>
    {statusCert !== 0 &&
            <>
            {statusCert !== 4 ? 
            <Card className='card-cert display-card-mode' sx={{minHeight: 300}}>
                <Stack className='cards-no-movil' spacing={2} justifyContent="flex-start" alignItems="center">
                    <CardHeader
                    title={titleCert}
                    className={classCert}
                    style = {{textAlign: "center!important"}}
                    />
                    <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt={titleCert} src={logoCert}>
                        soporte
                    </Avatar>
                </Stack>
                <div className='cards-movil'>
                    <div className={classCert} style={{width:'70%'}}>
                        <p style={{textAlign:'center', marginTop:'15%'}}>
                        {titleCert}
                        </p>
                    </div>
                    <div  style={{width:'30%'}}>
                        <img src={logoCert} style={{width:'100%'}} alt={titleCert}/>
                    </div>
                </div>
                <CardContent>
                {(statusCert === 1 ||  statusCert === 2) &&
                <>
                {   
                    contentCert&&contentCert.map(text => (
                        <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                            {text}
                        </Typography>
                    ))
                }
                <br></br>
                <Button disabled={statusCert === 2} variant='contained' id={idCert} onClick={handleSetCert}> {messageCert} </Button>
                </>
                }
                { statusCert === 3 &&
                <MensajeCursoAceptado icono={iconCert}/> }
                </CardContent>
            </Card> :
            <div className={certFinishStyle} style={{display:'block'}}>
                <p>{titleCert} <b>Finalizado</b></p>
            </div>
            }
            </>
    }
    </>
    );
}