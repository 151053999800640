import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createStyles,makeStyles } from "@material-ui/core/styles";
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import logo1 from "./curso_TI.jpg";
import logo2 from "./curso_datos.jpg";
import logo3 from "./curso_gestionproyectos.jpg";
import logo4 from "./curso_UX.jpg";
import Grid from '@mui/material/Grid';
import "./css/card.css";
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: "flex",
 },

  cardHeaderX: {
    textAlign: "center!important",
    color: "white"
  },
  
})
);

export default function ActionAreaCard() {
  const classes = useStyles();
  return (
    <>
    <Grid container spacing={1} className='cards-no-movil'>
        <Grid item xs={12}>
        <Box className="card-cert">
          <Card sx={{ minWidth: 460 , minHeight: 300 }} className={classes.root}>
          <Stack spacing={2} justifyContent="flex-start" alignItems="center">
            <CardHeader
              title="Certificado en Soporte TI"
              className={`soporte-ti ${classes.cardHeaderX}`}
            />
            <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="Soporte" src={logo1}>
                  soporte
                </Avatar>
            </Stack>

            <CardContent>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los especialistas de TI solucionan problemas para que
                  las computadoras y las redes funcionen correctamente.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Ponte en marcha para conseguir un empleo en
                  demanda en el sector de TI con un certificado
                  profesional de Google. Aprende las bases de las redes
                  y los sistemas operativos, o cómo solucionar
                  problemas mediante el uso de código.
                  <br></br>
                  No pierdas más tiempo y solicita tu beca.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión
              </Typography>
            </CardContent>
          </Card>  
        </Box>

        <Box className="card-cert">
            <Card sx={{ minWidth: 460 , minHeight: 300 }}  className={classes.root}>
            <Stack spacing={2} justifyContent="flex-start" alignItems="center">
              <CardHeader
                title="Certificado en Análisis de datos"
                className={`analisis ${classes.cardHeaderX}`}
              />
              <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="Datos" src={logo2}>
                Datos
              </Avatar>
            </Stack>
              <CardContent>
                
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los analistas de datos recopilan, transforman y
                  organizan los datos para contribuir en la toma de
                  decisiones comerciales informadas.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Da tus primeros pasos en una carrera de análisis de datos, que experimenta un alto nivel de crecimiento, con
                  un certificado profesional de Google. Incorpora las
                  habilidades requeridas que te prepararán para el trabajo, por ejemplo, cómo analizar y procesar datos
                  para obtener perspectivas clave sobre el negocio.
                  <br></br>
                  No pierdas más tiempo y solicita tu beca.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
              </Typography>
              </CardContent>
            </Card>  
          </Box>

          <Box className="card-cert">
          <Card sx={{ minWidth: 460 , minHeight: 300 }}  className={classes.root}>
          <Stack spacing={2} justifyContent="flex-start" alignItems="center">
            <CardHeader
              title="Certificado en Gestión de Proyectos"
              className={`gestion ${classes.cardHeaderX}`}
              />
              <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="Proyectos" src={logo3}>
                  Proyectos
              </Avatar>
            </Stack>
            <CardContent>
              
            <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                Los gerentes de proyectos se aseguran de que los 
                proyectos de una organización se administren y
                completen con el máximo valor.
              </Typography>
            <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                Da tus primeros pasos en una carrera en administración
                de proyectos, que experimenta un alto nivel de crecimiento,
                con un certificado profesional de Google.
                Averigua cómo administrar proyectos de manera
                eficiente y efectiva mediante el uso de métodos
                tradicionales y ágiles.
                <br></br>
                No pierdas más tiempo y solicita tu beca.
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                Cupo limitado, INROADS se reserva el derecho de admisión.
            </Typography>
            </CardContent>
          </Card>  
        </Box>
        </Grid>

        <Box className="card-cert">
            <Card sx={{ minWidth: 460 , minHeight: 300}}  className={classes.root}>
            <Stack spacing={2} justifyContent="flex-start" alignItems="center">
              <CardHeader
                title="Certificado en Diseño de experiencia del Usuario(UX)"
                className={`ux ${classes.cardHeaderX}`}
              />
              <Avatar sx={{ minWidth: 75 , minHeight: 75 }} variant="square" alt="UX" src={logo4}>
                    UX
              </Avatar>
              </Stack>
              <CardContent>
                
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                    Los analistas de datos recopilan, transforman y
                    organizan los datos para contribuir en la toma de
                    decisiones comerciales informadas.
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Incorpora los apectos básicos del diseño de UX: cómo
                  lograr empatía con los usuarios, crear wireframes y
                  prototipos, y realizaar investigaciones para probar los
                  diseños.
                  <br></br>
                  No pierdas más tiempo y solicita tu beca.
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
                </Typography>
              </CardContent>
            </Card>  
          </Box>
      </Grid>
      
      <div className='cards-movil' style={{flexDirection:'column'}}>
        <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#4285F4', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'15%'}}>
                  Certificado en Soporte TI
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo1} style={{width:'100%'}} alt="logo1"/>
              </div>
            </div>
            <CardContent>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los especialistas de TI solucionan problemas para que
                  las computadoras y las redes funcionen correctamente.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Ponte en marcha para conseguir un empleo en
                  demanda en el sector de TI con un certificado
                  profesional de Google. Aprende las bases de las redes
                  y los sistemas operativos, o cómo solucionar
                  problemas mediante el uso de código.
                  <br></br>
                  No pierdas más tiempo y solicita tu beca.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión
              </Typography>
            </CardContent>
         </Card>
         <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#E84335', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'13%'}}>
                  Certificado en Análisis de datos
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo2} style={{width:'100%'}} alt="logo2"/>
              </div>
            </div>
            <CardContent>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los analistas de datos recopilan, transforman y
                  organizan los datos para contribuir en la toma de
                  decisiones comerciales informadas.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Da tus primeros pasos en una carrera de análisis de datos, que experimenta un alto nivel de crecimiento, con
                  un certificado profesional de Google. Incorpora las
                  habilidades requeridas que te prepararán para el trabajo, por ejemplo, cómo analizar y procesar datos
                  para obtener perspectivas clave sobre el negocio.
                  <br></br>
                  No pierdas más tiempo y solicita tu beca.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
              </Typography>
            </CardContent>
         </Card>
         <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#E6ac27', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'13%'}}>
                  Certificado en Gestión de Proyectos
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo3} style={{width:'100%'}} alt="logo3"/>
              </div>
            </div>
            <CardContent>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Los gerentes de proyectos se aseguran de que los 
                  proyectos de una organización se administren y
                  completen con el máximo valor.
                </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Da tus primeros pasos en una carrera en administración
                  de proyectos, que experimenta un alto nivel de crecimiento,
                  con un certificado profesional de Google.
                  Averigua cómo administrar proyectos de manera
                  eficiente y efectiva mediante el uso de métodos
                  tradicionales y ágiles.
                  <br></br>
                  No pierdas más tiempo y solicita tu beca.
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
              </Typography>
            </CardContent>
         </Card>
         <Card style={{margin:'10px 0px'}}>
            <div style={{display:'flex'}}>
              <div style={{width:'70%', background:'#1F8839', color:'#fff'}}>
                <p style={{textAlign:'center', marginTop:'13%'}}>
                  Certificado en Diseño de experiencia del Usuario(UX)
                </p>
              </div>
              <div  style={{width:'30%'}}>
                <img src={logo4} style={{width:'100%'}} alt="logo4"/>
              </div>
            </div>
            <CardContent>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                    Los analistas de datos recopilan, transforman y
                    organizan los datos para contribuir en la toma de
                    decisiones comerciales informadas.
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Incorpora los apectos básicos del diseño de UX: cómo
                  lograr empatía con los usuarios, crear wireframes y
                  prototipos, y realizaar investigaciones para probar los
                  diseños.
                  <br></br>
                  No pierdas más tiempo y solicita tu beca.
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{textAlign:"left"}}>
                  Cupo limitado, INROADS se reserva el derecho de admisión.
                </Typography>
            </CardContent>
         </Card>
      </div>
    </>
  );
}
