import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-responsive-modal';
import "./css/adminStyle.css";
import AppBarAdmin from './AppBarHome';
import StickyFooter from "./StickyFooter";
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function AsignacionBecas() {

    const [umbral, setUmbral] = useState({
        umbralAprobacion: 0,
        maxHombres: 0,
        maxMujeres: 0,
        maxBinarios: 0,
    });

    const [formUmbral, setFormUmbral] = useState({
        umbralAprobacion: "",
        maxHombres: "",
        maxMujeres: "",
        maxBinarios: "",
    });

    const [listaPreAprobados,setListaPreAprobados] = useState({
        preAprobados:[],
        totalesPreAprobadosFooter:{
            totalHombres:0,
            totalMujeres:0,
            totalBinarios:0,
            granTotal:0,
        },
    });

    const [listaEspera,setListaEspera] = useState({
        listaEspera:[],
        totalesListaEsperaFooter:{
            totalHombres:0,
            totalMujeres:0,
            totalBinarios:0,
            granTotal:0,
        },
    });

    const [cifras, setCifras] = useState({
        totalHombres:0,
        totalMujeres:0,
        totalNoBinarios:0,
        totalAprobados:0,
        totalNoAprobados:0
    });

    const [modal, setModal] = useState(false);

    const [activaBoton, setActivaBoton] = useState(false);

    const [refresca, setRefresca] = useState(0);

    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    
    const [versionNumber, setVersionNumber] = useState(0);

    const [status, setStatus] = useState(1);

    const [maxBecas, setMaxBecas] = useState(0);

    const mensajeRequerido = "Campo requerido";
    const mensajeDatoNumerico = "El dato debe ser númerico";

    useEffect(() => {
        axios.get('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/becas/main_pre_aprobacion/',{ auth: {
             username: sessionStorage.getItem('email'),
             password: sessionStorage.getItem('token')
            }
        })
        .then((response) => {
            setUmbral({
                    umbralAprobacion: response.data.umbralAprobacion,
                    maxHombres: response.data.maxHombres,
                    maxMujeres: response.data.maxMujeres,
                    maxBinarios: response.data.maxBinarios
            });

            setListaPreAprobados({
                preAprobados:response.data.preAprobados,
                totalesPreAprobadosFooter:{
                    ...response.data.totalesPreAprobadosFooter
                }
            });

            setListaEspera({
                listaEspera:response.data.listaEspera,
                totalesListaEsperaFooter:{
                    ...response.data.totalesListaEsperaFooter
                }
            });

            setCifras({
                ...response.data.cifras
            });

            setVersionNumber(response.data.version_number_current);

            setMaxBecas(response.data.maxBecas);

            setStatus(response.data.status);
        })
        .catch((error) => {
            console.error(error);
        })
    }, [refresca]);

    // Estilos para tabla
    const StyledTableCell = styled(TableCell)(({backgroundColor }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor,
            fontSize: 17,
            color: '#fff',
            padding: '25px 0px',
            border: 'solid 1px black',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            padding: '25px 0px',
            border: 'solid 1px black',
        },
        [`&.${tableCellClasses.footer}`]: {
            fontSize: 17,
            padding: '25px 0px',
            backgroundColor:'#ccc',
        },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        
    }));

    const TablaCalificaciones = ({colorHead, data, dataFooter}) => (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell backgroundColor={colorHead} align="center">CALIFICACIÓN</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">#HOMBRES</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">#MUJERES</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">#NO BINARIOS</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">#TOTALES</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {data ? data.map((row) => (
                    <StyledTableRow key={row.calificacion} >
                        <StyledTableCell align="center">{row.calificacion}</StyledTableCell>
                        <StyledTableCell align="center">{row.numHombres}</StyledTableCell>
                        <StyledTableCell align="center">{row.numMujeres}</StyledTableCell>
                        <StyledTableCell align="center">{row.numBinarios}</StyledTableCell>
                        <StyledTableCell align="center">{row.totales}</StyledTableCell>
                    </StyledTableRow>
                )): <></>}
                </TableBody>
                <TableFooter>
                    <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell align="left">Total: {dataFooter.totalHombres}</StyledTableCell>
                        <StyledTableCell align="left">Total: {dataFooter.totalMujeres}</StyledTableCell>
                        <StyledTableCell align="left">Total: {dataFooter.totalBinarios}</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <div className='table-post-footer'>
            <span>Gran Total: {dataFooter.granTotal}</span>
        </div>
        </>
    );
    // -- Fin de estilos para tabla

    // handle's
    const handleChangueForm = (event,nombreCampo) => {
        const value = event.target.value;
        if(value !== "0"){
            realizaValidaciones(nombreCampo,value);
            setUmbral({...umbral,[nombreCampo]:value});
        }
    };
    
    const handleActualizaUmbral = () => {
        if(validaForm()) {
            const umbralAprobacion = parseInt(umbral.umbralAprobacion);
            const maxHombres = parseInt(umbral.maxHombres);
            const maxMujeres = parseInt(umbral.maxMujeres);
            const maxBinarios = parseInt(umbral.maxBinarios);

            const data = {
                umbralAprobacion,maxHombres,maxMujeres,maxBinarios
            }
            
            axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/becas/update_parameter/', data , {
                auth: {
                    username: sessionStorage.getItem('email'),
                    password: sessionStorage.getItem('token')
                }
            })
            .then(res => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Actualización exitosa.",
                    tipoMensaje:"success"
                });
                setRefresca((refresca) => refresca ? 0:1);
            })
            .catch(error => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Error al actualizar.",
                    tipoMensaje:"error"
                });
                console.error(error);
            })
        }
    };

    const handleAbreModal = () => {
        setModal(true);
    };

    const handleChangueMensajeValidacion = (event) => {
        const textoValida = event.target.value;
        if(textoValida === "Si, acepto") {
            setActivaBoton(true);
        } else {
            setActivaBoton(false);
        }
    }

    const handleLimpiaDatosModal = () => {
        setModal(false);
        setActivaBoton(false);
    }

    const handleEnviarInformacion = () => {
        axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/becas/aprobacion/', {version_number_current:versionNumber} , {
            auth: {
                username: sessionStorage.getItem('email'),
                password: sessionStorage.getItem('token')
            }
        })
        .then(res => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Aprobación exitosa.",
                tipoMensaje:"success"
            });
            setRefresca((refresca) => refresca ? 0:1);
        })
        .catch(error => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Error al aprobar.",
                tipoMensaje:"error"
            });
            console.error(error);
        })
    };

    // -- Fin handle's

    // Validaciones
    const realizaValidaciones = (nombreCampo,valorCampo) => {
        let mensaje = validaRequerido(valorCampo);
        if(!mensaje) {
            mensaje = validaDatoNumerico(valorCampo);
            if(!mensaje) {
                if(nombreCampo === "umbralAprobacion") {
                    mensaje = validaMaximo(valorCampo,100);
                }
            }
        }
        if(!mensaje) {
            validaSumaMaxima({...umbral,[nombreCampo]:valorCampo});
        } else {
            setFormUmbral({...formUmbral,[nombreCampo]:mensaje});
        }
    }

    const validaRequerido = (valorCampo) => {
        return (valorCampo === null || valorCampo === "") ? mensajeRequerido:"";
    }

    const validaDatoNumerico = (valorCampo) => {
        return /^[0-9]*$/.test(valorCampo) ? "":mensajeDatoNumerico;
    }

    // Valida el maximo permitido de los campos
    const validaMaximo = (valorCampo,cantidadMaxima) => {
        const valorCampoInt = parseInt(valorCampo);
        if(valorCampoInt > cantidadMaxima) {
            return "El valor de este campo no puede ser mayor a "+cantidadMaxima;
        }
    }

    const validaSumaMaxima = (umbral) => {

        const maxHombresInt = parseInt(umbral.maxHombres);
        const maxMujeresInt = parseInt(umbral.maxMujeres);
        const maxBinariosInt = parseInt(umbral.maxBinarios);

        const totalMaximos = maxHombresInt+maxMujeresInt+maxBinariosInt;
        if(totalMaximos > maxBecas) {
            setFormUmbral({
                maxHombres:"La suma en conjunto de # de Hombres, # de Mujeres, # de No Binarios no debe ser mayor a " + maxBecas,
                maxMujeres:"La suma en conjunto de # de Hombres, # de Mujeres, # de No Binarios no debe ser mayor a " + maxBecas,
                maxBinarios:"La suma en conjunto de # de Hombres, # de Mujeres, # de No Binarios no debe ser mayor a " + maxBecas
            });
        } else {
            setFormUmbral({
                maxHombres:"",
                maxMujeres:"",
                maxBinarios:""
            });
        }
    }

    const validaForm = () => {
        for (const nombreCampo in formUmbral) {
            if(formUmbral[nombreCampo]){
                return false;
            }
        }
        return true;
    }
    // --- Fin de validaciones

    const ModalConfirmacion = () => (
        <Modal open={modal} onClose={handleLimpiaDatosModal} >
            <DialogContent>
                <DialogContentText >
                    <div className='dialog'>
                        <h2>¡Alerta!</h2>
                        <div>
                            <p>¿Estás seguro en APROBAR a este bloque de personas? <br />
                            Dicha acción no séra reversible.</p>
                        </div>
                        <div>
                            Escribe el mensaje de confirmación <p></p>
                            <TextField id="mensajeValidacion" 
                                variant="outlined"
                                placeholder="Si, acepto"
                                onChange={handleChangueMensajeValidacion}
                            />
                            <div className='dialog-button form-button-background-morado'>
                                <Button 
                                    variant="contained" 
                                    size="large"
                                    disabled={!activaBoton}
                                    onClick={handleEnviarInformacion}
                                >Aceptar</Button>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    return (
        <>
        <AppBarAdmin />   
        <ModalConfirmacion />     
        <div className='card-main'>
            <h1>Modulo de asignación de <br /> becas</h1>
            <hr />
            <div>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <p>Total de hombres aprobados: {cifras.totalHombres}</p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <p>Total de mujeres aprobados: {cifras.totalMujeres}</p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <p>Total de no binarios aprobados: {cifras.totalNoBinarios}</p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p>Total de aprobados: {cifras.totalAprobados}</p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p>Total de no aprobados: {cifras.totalNoAprobados}</p>
                    </Grid>
                </Grid>
            </div>
            <div className='form-umbral'>
            <Box
                component="form"
                autoComplete="off"
            >
                <h4>COLOCA LA MÍNIMA CALIFICACION APROBATORIA</h4>
                <div>
                    <p>Máximo de becas por aprobar: {maxBecas}</p>
                </div>
                <div className='form-umbral-grid'>
                    <Grid container rowSpacing={3}>
                        <Grid item xs={12} sm={2}>
                            <p>Umbral de aprobación:</p>
                        </Grid>
                        <Grid item xs={11} sm={10}>
                            <TextField variant="outlined"
                            error={formUmbral.umbralAprobacion}
                            helperText={formUmbral.umbralAprobacion}
                            inputProps={{ maxLength: 3 }}
                            onChange={(event)=>handleChangueForm(event,'umbralAprobacion')}
                            value={umbral.umbralAprobacion}/>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <p># de Hombres:</p>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <TextField variant="outlined"
                            error={formUmbral.maxHombres}
                            helperText={formUmbral.maxHombres}
                            inputProps={{ maxLength: 5 }}
                            onChange={(event)=>handleChangueForm(event,'maxHombres')}
                            value={umbral.maxHombres}/>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <p># de Mujeres:</p>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <TextField variant="outlined"
                            error={formUmbral.maxMujeres}
                            helperText={formUmbral.maxMujeres}
                            inputProps={{ maxLength: 5 }}
                            onChange={(event)=>handleChangueForm(event,'maxMujeres')}
                            value={umbral.maxMujeres}/>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <p># de No Binarios:</p>
                        </Grid>
                        <Grid item xs={10} sm={10}>
                            <TextField variant="outlined"
                            error={formUmbral.maxBinarios}
                            helperText={formUmbral.maxBinarios}
                            inputProps={{ maxLength: 5 }}
                            onChange={(event)=>handleChangueForm(event,'maxBinarios')}
                            value={umbral.maxBinarios}/>
                        </Grid>
                    </Grid>
                    <div className='form-button form-button-background-morado'>
                        <Button variant="contained" 
                        size="large" 
                        onClick={handleActualizaUmbral}>Actualizar</Button>
                    </div>
                </div>
            </Box>
            </div>
            <div className="form-table">
                <TablaCalificaciones colorHead={'#4e2378'} data={listaPreAprobados.preAprobados} dataFooter={listaPreAprobados.totalesPreAprobadosFooter}/>
                <div className='form-button form-button-background-morado'>
                    <Button variant="contained" size="large" disabled={status === 2}
                    onClick={handleAbreModal}>Aprobar</Button>
                    {status === 2 ? 
                    <>
                        <p className='texto-aviso'>Tienes becarios pendientes por subir a cursera.</p>
                    </> : <></>
                }
                </div>
                {status === 2 ? 
                    <>
                        <p className='texto-aviso'>Tienes becarios pendientes por subir a cursera.</p>
                    </> : <></>
                }
            </div>
            <div className="form-table">
                <h3>LISTA DE ESPERA:</h3>
                <TablaCalificaciones colorHead={'#d37913'} data={listaEspera.listaEspera} dataFooter={listaEspera.totalesListaEsperaFooter}/>
            </div>
        </div>
        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={3000}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
        >
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
        <StickyFooter />   
        </>
    );
}