import React, { Component} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@mui/material/FormGroup';
import ResponsiveDialog from './DailogRegister';
import { withRouter } from 'react-router';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Alert from '@mui/material/Alert';
import "./index.css";
import arrow from "./flecha_gris.svg";
import btnLogin from "./img/icon_sesion.svg"
import "./css/appBar.css";
import "./css/landing_body.css";
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import ReCAPTCHA from "react-google-recaptcha";
import SessionContex from './provider/SessionContex';

class SignUp extends Component {

    constructor() {
        super();

        this.captchaRef = React.createRef();

        this.state = {
            user: '',
            pass: '',
            user_valid: true,
            pass_valid: true,
            isSignedup: false,
            setOpen:false,
            open:false,
            openModal: false,
            openModalEmail: false,
            confirmado: 0,
            found:0,
            showPassword: false,
            emailAgain: '',
            messageDismiss: '',
            certificado1: 0,
            certificado2: 0,
            certificado3: 0,
            certificado4: 0,
            captcha_valid: true,
        }
      
    }

  
  handleClickShowPassword = event => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleClickOpen = event => {
      event.preventDefault()
      this.setState({ setOpen:true})
  }

  handleClose = event => {
      this.setState({ open:false})
  }

  handleUser = event => {
      this.setState({ user: event.target.value })
  }

  handlePass = event => {
      this.setState({ pass: event.target.value })
  }

  Checklogin = (updateValueAdmin) => {

    if(!this.captchaRef.current.getValue()){
        this.setState( {captcha_valid:false});
        return false;
    } 

    this.setState( {captcha_valid:true});
    
    const user = {
        email: this.state.user,
        password: this.state.pass,
        email_valid: this.state.user_valid,
        pass_valid: this.state.pass_valid
        //signedup: this.state.isSignedup,
    };

    if(user.email === "")
        user.email_valid =  false
    
    if(user.password === "")
        user.pass_valid =  false

    if (!user.pass_valid || !user.email_valid){
        this.setState({user_valid:user.email_valid,pass_valid:user.pass_valid})
        return false;
    }


    const headersPost = {
        'content-type': 'text/json'
      }

      const data = user
      const headers_post = JSON.stringify(headersPost)

      this.setState({ found: 0, messageDismiss:''});

      axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/login/', data, headers_post)
      .then(res => {
        if (res.request.status === 200){
            if(res.data.activo === 0) {
                this.setState({messageDismiss:res.data.message});
            } else {
                sessionStorage.setItem('isSigned', true)
                sessionStorage.setItem('email', this.state.user)
                sessionStorage.setItem('formulario',res.data.formulario)
                sessionStorage.setItem('estatus_beca', res.data.estatus_beca)
                
                if (res.data.certificados){
                    if(res.data.certificados.c1 === "disponibles"){
                        sessionStorage.setItem('c1', 1)
                        sessionStorage.setItem('c1Status', "Disponible")
                    }

                    if(res.data.certificados.c1 === "bloqueado"){
                        sessionStorage.setItem('c1', 0)
                        sessionStorage.setItem('c1Status', "Bloqueado")
                    }

                    if(res.data.certificados.c1 === "seleccionado"){
                        sessionStorage.setItem('c1', 0)
                        sessionStorage.setItem('c1Status', "Seleccionado")
                    }


                    if(res.data.certificados.c2 === "disponibles"){
                        sessionStorage.setItem('c2', 1)
                        sessionStorage.setItem('c2Status', "Disponible")
                    }

                    if(res.data.certificados.c2 === "seleccionado"){
                        sessionStorage.setItem('c2', 0)
                        sessionStorage.setItem('c2Status', "Seleccionado")
                    }

                    if(res.data.certificados.c2 === "bloqueado"){
                        sessionStorage.setItem('c2', 0)
                        sessionStorage.setItem('c2Status', "Bloqueado")
                    }

                    if(res.data.certificados.c3 === "disponibles"){
                        sessionStorage.setItem('c3', 1)
                        sessionStorage.setItem('c3Status', "Disponible")
                    }

                    if(res.data.certificados.c3 === "bloqueado"){
                        sessionStorage.setItem('c3', 0)
                        sessionStorage.setItem('c3Status', "Bloqueado")
                    }

                    if(res.data.certificados.c3 === "seleccionado"){
                        sessionStorage.setItem('c3', 0)
                        sessionStorage.setItem('c3Status', "Seleccionado")
                    }


                    if(res.data.certificados.c4 === "disponibles"){
                        sessionStorage.setItem('c4', 1)
                        sessionStorage.setItem('c4Status', "Disponible")
                    }

                    if(res.data.certificados.c4 === "bloqueado"){
                        sessionStorage.setItem('c4', 0)
                        sessionStorage.setItem('c4Status', "Bloqueado")
                    }

                    if(res.data.certificados.c4 === "seleccionado"){
                        sessionStorage.setItem('c4', 0)
                        sessionStorage.setItem('c4Status', "Seleccionado")
                    }
                }
                
                if(res.data.statusCerts) {
                    const statusCerts = JSON.stringify(res.data.statusCerts);
                    // const statusCerts = JSON.stringify({c1:1,c2:1,c3:1,c4:4});
                    sessionStorage.setItem('statusCerts', statusCerts);
                }

                //this.setState({ isSignedup: true })
                //sessionStorage.setItem('isSigned',true)

                //se actualiza a true solo cuando es admin
                updateValueAdmin(false);
                if (res.data.rol === 'Administrador'){
                    //this.setState({ isSignedup: true })
                    sessionStorage.setItem('isSigned',true)

                    sessionStorage.setItem('token', res.data.token)

                    sessionStorage.setItem('Admin',true)
                    updateValueAdmin(true);
                    //sessionStorage.setItem('rol',res.data.rol)

                    this.props.history.push('/admin')
                }

                if (res.data.rol === 'Usuario'){
                    //this.setState({ isSignedup: true })
                    sessionStorage.setItem('isSigned',true)
                    //sessionStorage.setItem('rol',res.data.rol)
                    this.props.history.push('/stuff')
                }

                if (res.data.email !== '' & res.data.password !==''){
                    sessionStorage.setItem('isSigned',true)
                    //this.setState({ isSignedup: true })
                }
                
                if (res.data.status === 400){
                    this.setState({ found: 1 })
                    //this.setState({ isSignedup: false })
                    sessionStorage.setItem('isSigned',true)
                }

                if (res.data.confirmed === 'no confirmado' ){
                    this.setState({ confirmado: 1 })            
                    //this.setState({ isSignedup: false })
                    sessionStorage.setItem('isSigned',true)
                }
            }
        }else {
            //this.setState({ isSignedup: false })
            sessionStorage.setItem('isSigned',true)
        }
      }).catch((error) => {
        this.setState({ found: 1 });
        console.error(error);
    });
  }

  launchModal = e => {
      e.preventDefault()
      this.setState({ openModal: true})
  }

  launchModalEmail = e => {
    e.preventDefault()
    this.setState({ openModalEmail: true})
  }

  closeModal = e => {
      this.setState({ openModal: false,user_valid: true, pass_valid: true, messageDismiss:''})
  }

  closeModalEmail = e => {
    this.setState({ openModalEmail: false})
  }

   sendEmailValidation = event => {
    const user = {
        email:this.state.emailAgain
    }

    const headersPost = {
        'content-type': 'text/json'
    }

    const headers_post = JSON.stringify(headersPost)

    const data = user

    axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/login/send_again_email/', data, headers_post)
    .then(res => {
        this.setState({status_envio: res.request.status})
      }).catch(res =>{
        this.setState({status_envio: 500})
      })
}


handleEmailAgain = event => {
    this.setState({ emailAgain: event.target.value })
}

handleCambioContrasenia = () => {
    this.props.history.push('/cambioContra')
}

onChangueCaptcha = () => {
    if(this.captchaRef.current.getValue()) {
        this.setState({captcha_valid:true});
    }
}

  render(){
      return (
        <>
        <SessionContex.Consumer>
        { (context) => (
        <>
        {/*<Button onClick={this.launchModal} style={{textTransform: 'None',height:'100px', marginLeft: '25px', background: '#272f51', color:'white', fontFamily: 'sans-serif', fontSize: 16,}} className='loginButton btn-login'>
            <span className='spanHeader'> Iniciar sesión</span>
            <img src={arrow} alt="Inroads" style={{width:'4%', marginLeft:'15px' }} />
        </Button>*/}
        <Button onClick={this.launchModal} style={{width:'20%'}} className='btn-login-movil'>
            <img src={btnLogin} alt="Inroads" style={{width:'45px'}} />
        </Button>
          <Modal open={this.state.openModal} onClose={this.closeModal} >
              <DialogContent>
                  <DialogContentText style={{fontSize:'25px'}} className="font-landing-herencia">
                      Iniciar sesión
                  </DialogContentText>

                  <TextField 
                    autoFocus
                    error={!this.state.user_valid}
                    margin="dense"
                    id="usuario"
                    label="Correo"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={this.handleUser}/>
                  
                    <FormControl variant="standard" style={{width: "100%"}} >
                    <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
                    <Input
                        error={!this.state.pass_valid}
                        margin="dense"
                        id="contraseña"
                        label="Contraseña"
                        type={this.state.showPassword ? 'text' : 'password'}
                        fullWidth variant="standard" 
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        onChange={this.handlePass}
                        />
                    </FormControl>
                    <div className='captcha'>
                        <ReCAPTCHA
                            ref={this.captchaRef}
                            onChange={this.onChangueCaptcha}
                            sitekey="6LfqQzAgAAAAAKCsjMytq0OXfCRADHSBQE4QNtVQ"
                        />
                        {!this.state.captcha_valid &&
                            <p>Por favor acepta el captcha</p>
                        }
                    </div>
                  <FormGroup>
                      { /*
                      <FormControlLabel control={<Checkbox />} label="No soy un robot" />
                    */ }
                      <Button 
                      className="button_ovalado" 
                      variant="contained"
                      style={{width:'60%', textTransform: 'None',margin: "auto", paddingTop:'5px' }}
                      onClick={()=>this.Checklogin(context.updateValueAdmin)}>Ingresar</Button>
                      <button className='link-button' onClick={this.handleCambioContrasenia}>¿Olvidaste tu contraseña?</button>
                  </FormGroup>
                    <br></br>
                    {this.state.confirmado ? <Alert severity="warning">Recuerda que debes de validar tu correo antes de iniciar sesión</Alert> : null}
                    {this.state.found ? <Alert severity="warning">Correo y/o contraseña incorrectos</Alert> : null}
                    {this.state.messageDismiss ? <Alert severity="warning">{this.state.messageDismiss}</Alert> : null}
              </DialogContent>
              <Button 
                className='button-link link'
                style={{textTransform: 'None', textAlign:'center'}}
                onClick={this.launchModalEmail}>
                No has recibido tu correo de confirmación, haz click aquí
            </Button>
                <Modal open={this.state.openModalEmail} onClose={this.closeModalEmail}>
                <DialogContent>
                    <TextField 
                    autoFocus 
                    margin="dense" 
                    id="usuario" 
                    label="Correo" 
                    type="email" 
                    fullWidth variant="standard" 
                    onChange={this.handleEmailAgain}/>
                    <FormGroup>
                    
                    { this.state.status_envio === 200 ? <Alert severity="info">Se envio tu correo de validacion exitosamente</Alert> : null}
                    { this.state.status_envio >= 400 ? <Alert severity="error">Favor de intentar más tarde</Alert> : null}
                      <Button 
                      className="button_ovalado"
                      style={{width:'60%', textTransform: 'None',margin: "auto",marginTop: "15%"}}
                      variant="contained" onClick={this.sendEmailValidation}>Enviar</Button>
                    </FormGroup>
                </DialogContent>
                </Modal>
              {/*
              <p
              className="font-landing"
              style={{textAlign:'center'}}>
                  <a className="link"
                  href="/contraseña">Olvidaste tu contraseña?</a>
            </p> */ }
              <p 
              className="font-landing"
              style={{textAlign:'center'}}>
            <span>Eres nuevo? </span>
            <ResponsiveDialog liga="true" />
            <span> para iniciar tu solicitud </span>
             
             </p>
          </Modal>
          </>
          )
          }
          </SessionContex.Consumer>
          </>
      )
  }
}

export default withRouter(SignUp);