import React, { Component } from 'react';
import AppBar from './AppBarHome';
import StickyFooter from "./StickyFooter";
import SimpleAccordion from "./Accordion";
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import {DropzoneDialog} from 'material-ui-dropzone'
import BasicGrid from './PaperLanding';
import './index.css'
import Typography from "@material-ui/core/Typography";
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import ParallaxImg from './components/Parallax';
import CommunityFacebook from './CommunityFacebook';
import Collaborators from './Collaborators';

class Stuff extends Component {
    state = {
        generos:[],
        estados: [],
        actualmente: [],
        motivacion: [],
        ingresos: [],
        difusiones: [],
        dedicaciones: [],
        promedios: [],
        motivos:[],
        estudios: [],
        universidades: [],
        organizaciones: [],
        generoValue: '',
        estadoValue: '',
        actualmenteValue:'',
        estudiosValue:'',
        motivacionValue:'',
        ingresosValue: '',
        dedicacionesValue: '',
        difusionesValue: '',
        promediosValue:'',
        motivosValue:'',
        universidadesValue:'',
        organizacionValue:'',
        nombre: '',
        nombreOficial: '',
        apellido: '',
        apellidoOficial: '',
        genero: '',
        edad: '',
        celular: '',
        estado:'',
        checkCelular:'',
        codigo: '',
        estudiosProm: '',
        organizacion: '',
        dedicacion: '',
        dispositivo: '',
        curp: '',
        inroader: '',
        computadora:'',
        otraUniversidad: '',
        declaro: false,
        comprometo: false,
        acepto:false,
        condiciones:false,
        privacidad:false,
        identificacion:'',
        comprobante: '',
        openDropZone: false,
        openDropZone2: false,
        files:[],
        files2: [],
        email: '',
        universidad: '',
        motivo: '',
        box1: 0,
        box2: 0,
        box3: 0,
        box4: 0,
        box5: 0,
        boxes: 0,
        computadoraReal: 0,
        dispositivoReal: 0,
        estatus_beca: null,
        nombreEmpty:1,
        apellidoEmpty:1,
        nombreOficialEmpty:1,
        apellidoOficialEmpty:1,
        generoEmpty:1,
        edadEmpty:1,
        celularEmpty:1,
        checkCelularEmpty:1,
        residenciaEmpty:1,
        codigoEmpty:1,
        actualmenteEmpty:1,
        estudiosEmpty:1,
        motivacionEmpty:1,
        promedioEmpty:1,
        ingresoEmpty:1,
        canalesEmpty:1,
        horasEmpty:1,
        motivoEmpty:1,
        curpEmpty:1,
        identificacionEmpty: 1,
        comprobanteEmpty:1,
        universidadEmpty:1,
        universidadOtraEmpty:1,
        organizacionEmpty:1,
        idUniversidad:0,
        open_university:0,
        otra_university:0,
        alert_duplicate_file: 0,
        validate:{
            message_curp: "",
            is_valid_curp: 1,
            message_edad: "",
            is_valid_edad: 1,
            is_valid_cp: 1,
            is_valid_cel: 1,
            is_valid_check_cel: 1
        },
        formulario:0,
        is_valid_all_form: 0
    }

    componentDidMount = () => {
        this.setState({email: sessionStorage.getItem('email')});
        this.setState({estatus_beca:parseInt(sessionStorage.getItem('estatus_beca'))});


        if(sessionStorage.getItem('formulario') === 'no'){
            this.setState({ formulario: 1});
        }else{
            
            this.setState({ formulario: 0});
        }

        axios.get('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/form/get_catalogs/',{ auth: {
             username: sessionStorage.getItem('email'),
            }
        })

        .then((response) => {

            let gen = [];
            let est = [];
            let act = [];
            let mot = [];
            let ing = [];
            let dif = [];
            let ded = [];
            let pro = [];
            let moti = [];
            let estu = [];
            let uni = [];
            let org = [];
            
            for(const organizacion of response.data.organizaciones)
                org.push(organizacion)
            
            for(const genero of response.data.generos){
                gen.push(genero)
            }

            for (const estado of response.data.estados){
                est.push(estado)
            }

            for (const ocupacion of response.data.ocupaciones){
                act.push(ocupacion)
            }

            for (const motivacion of response.data.motivaciones){
                mot.push(motivacion)
            }

            for (const ingreso of response.data.ingresos){
                ing.push(ingreso)
            }

            for (const difusion of response.data.difusiones){
                dif.push(difusion)
            }

            for (const dedicacion of response.data.dedicaciones){
                ded.push(dedicacion)
            }

            for (const promedio of response.data.promedios){
                pro.push(promedio)
            }

            for (const motivo of response.data.motivos){
                moti.push(motivo)
            }

            for (const estudio of response.data.estudios){
                estu.push(estudio)
            }

            for (const universidad of response.data.universidades){
                let json = {label: universidad.texto_universidad, id_catalogo_universidad: universidad.id_catalogo_universidad}
                //uni.push(universidad)
                uni.push(json)
            }

            this.setState({ generos: gen })
            this.setState({ estados: est })
            this.setState({ actualmente: act })
            this.setState({ motivacion: mot })
            this.setState({ ingresos: ing })
            this.setState({ difusiones: dif })
            this.setState({ dedicaciones: ded})
            this.setState({ promedios: pro })
            this.setState({ motivos: moti})
            this.setState({ estudios: estu })
            this.setState({ universidades: uni})
            this.setState({ organizaciones: org})
            this.setState({ inroader: response.data.inroader})
        })
        .catch((error) => {
            console.error(error)
        })
    }

    validAllForm = () => {
        console.log("Validando formulario....")
        console.log(this.state.genero)
        let flag_error = 0

        if(this.state.nombre === ''){ flag_error = 1;  }

        if(this.state.nombreOficial === ''){ flag_error = 1; }

        if(this.state.genero === ''){ flag_error = 1; }

        if(this.state.celular === ''){ flag_error = 1; }
        
        if(this.state.estadoValue === ''){ flag_error = 1; }

        if(this.state.actualmenteValue === ''){  flag_error = 1; }

        if(this.state.motivacionValue === ''){  flag_error = 1; }

        if(this.state.ingresosValue === ''){ flag_error = 1; }

        if(this.state.dedicacionesValue === ''){ flag_error = 1; }

        if(this.state.apellido === ''){ flag_error = 1; }

        if(this.state.apellidoOficial === ''){ flag_error = 1; }

        if(this.state.edad === ''){ flag_error = 1; }

        if(this.state.checkCelular === ''){ flag_error = 1; }

        if(this.state.codigo === ''){ flag_error = 1; }

        if(this.state.estudiosValue === ''){ flag_error = 1; }

        if(this.state.estudiosValue >= 4){
            
            if(this.state.universidadesValue  === ''){
                flag_error = 1;
            }else{
                if(this.state.universidadesValue === 0 && this.state.otraUniversidad === ''){
                    flag_error = 1;
                }
            }
        }

        if(this.state.promediosValue === ''){ flag_error = 1; }

        if(this.state.difusionesValue === ''){  flag_error = 1; }

        if(this.state.motivosValue === ''){ flag_error = 1; }

        if(this.state.curp === ''){ flag_error = 1;
        }

        if(this.state.organizacionValue === ''){ flag_error = 1; }
        if(this.state.validate.is_valid_curp === 0 || this.state.validate.is_valid_edad === 0 || this.state.validate.is_valid_cp === 0 || this.state.validate.is_valid_cel === 0 || this.state.validate.is_valid_check_cel === 0){
            flag_error = 1;
        }

        if(this.state.files.length === 0){ flag_error = 1; }

        if(this.state.files2.length === 0){ flag_error = 1; }

        if(this.state.files.name && this.state.files.name === this.state.files2.name){
            flag_error = 1;
        }

        if(flag_error === 1){
            console.log("Form valido en :0");
            this.setState({ is_valid_all_form: 0 })
        }else{
            console.log("Form valido en :1");
            this.setState({ is_valid_all_form: 1 })
        }

    }

    handleGenero = (event) => {
        this.setState({ generoValue: event.target.value });
        this.setState({ genero: event.target.value });
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleEstado = (event) => {
        this.setState({ estadoValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleActualmente = (event) => {
        this.setState({ actualmenteValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleMotivacion = (event) => {
        this.setState({ motivacionValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleIngresos = (event) => {
        this.setState({ ingresosValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleDedicaciones = (event) => {
        this.setState({ dedicacionesValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleMotivos = (event) => {
        this.setState({ motivosValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleDifusiones = (event) => {
        this.setState({ difusionesValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handlePromedios = (event) => {
        this.setState({ promediosValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleNombre = (event) =>{
        this.setState( {nombre: event.target.value})
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleNombreOficial = (event) =>{
        this.setState( {nombreOficial: event.target.value})
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleApellido = (event) => {
        this.setState({ apellido: event.target.value})
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleApellidoOficial = (event) => {
        this.setState({ apellidoOficial: event.target.value})
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleEdad = (event) => {
        
        const edad = event.target.value;
        // eslint-disable-next-line
        if(/^[1-9][0-9]{0,1}$/.test(edad)){
            this.setState({ edad: event.target.value,
                validate: { ...this.state.validate, message_edad: "", is_valid_edad: 1
             }});
        }else
            this.setState({validate: { ...this.state.validate, message_edad: "Por favor ingresa edad valida",is_valid_edad: 0 }});
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleCelular = (event) => {
        const cel = event.target.value;
        // eslint-disable-next-line
        if(/^[0-9]{10}$/.test(cel)){
            this.setState({ celular: cel,
                validate: { ...this.state.validate, is_valid_cel: 1
             }});
        }else
            this.setState({validate: { ...this.state.validate,is_valid_cel: 0 }});
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handlecheckCelular = (event) => {
        const check_celular = event.target.value;
        if(check_celular !== "" && check_celular === this.state.celular){
            this.setState({ checkCelular: check_celular,
                validate: { ...this.state.validate, is_valid_check_cel: 1
             }});
        }else
           this.setState({validate: { ...this.state.validate,is_valid_check_cel: 0 }});
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleCodigo = (event) => {
        const cp = event.target.value;
        // eslint-disable-next-line
        if(/^[0-9]{5}$/.test(cp)){
            this.setState({ codigo: cp,
                validate: { ...this.state.validate, is_valid_cp: 1
             }});
        }else
            this.setState({validate: { ...this.state.validate,is_valid_cp: 0 }});
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleEstudios = (event) => {
        const estudios = event.target.value;
        /*Si es mayor o igual a 4, pertenece a una universidad, se debe desplegar el campo*/
        if(estudios >= 4)
            this.setState({ estudiosValue: estudios,open_university: 1 });
        else{
            this.setState({ estudiosValue: estudios,open_university: 0, otra_university: 0, universidadesValue: null});
        }
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleUniversidades = (event, values) => {
        
        if(values !== null ){
            const universidad = values.id_catalogo_universidad;
            if(universidad === 0)
                this.setState({ universidadesValue: universidad,otra_university: 1 });
            else
                this.setState({ universidadesValue: universidad,otra_university: 0 });
        }
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleEstudiosProm = (event) => {
        this.setState({ estudiosProm: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleOrganizacion = (event) => {
        this.setState({ organizacionValue: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleDedicacion = (event) => {
        this.setState({ dedicacion: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleDispositivo = (event) => {
       
        if(event.target.value === "Si"){
            this.setState({ computadoraReal: 1 })    
        }else{
            this.setState({ computadoraReal: 0 })    
        }
        this.setState({ computadora: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleInternet = (event) => {
        
        if(event.target.value === "Si"){
            this.setState({ dispositivoReal: 1 })    
        }else{
            this.setState({ dispositivoReal: 0 })    
        }
        this.setState({ dispositivo: event.target.value })
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleCurp = (event) => {

    const curp = event.target.value.toUpperCase();
    // eslint-disable-next-line
    if(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(curp)){
        this.setState({ curp: event.target.value,
            validate: { ...this.state.validate, message_curp: "", is_valid_curp: 1
         }});
    }else
        this.setState({validate: { ...this.state.validate, message_curp: "Por favor ingresa un CURP valido",is_valid_curp: 0 }});
    setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleOtraUniversidad = (event) => {
        const otra_universidad = event.target.value;
        this.setState({ otraUniversidad: otra_universidad});
        setTimeout(() => {  this.validAllForm() }, 1000);
        }
    

    handleDeclaro = (event) => {
        let declaro =  event.target.checked;
        this.setState({declaro: event.target.checked })
        this.setState({box1: 1})

        if (declaro && this.state.comprome &&  this.state.acepto && this.state.condiciones  && this.state.privacidad){
            this.setState({ boxes: 1})
        }else{
            this.setState({ boxes: 0})
        }
    }

    handleComprometo = (event) => {
        let comprome =  event.target.checked;
        this.setState({comprome: event.target.checked})
        this.setState({ box2: 1})

        if (this.state.declaro && comprome &&  this.state.acepto && this.state.condiciones  && this.state.privacidad){
            this.setState({ boxes: 1})
        }else{
            this.setState({ boxes: 0})
        }
    }

    handleAcepto = (event) => {
        let acepto =  event.target.checked;
        this.setState({acepto: event.target.checked})
        this.setState({ box3: 1})

        if (this.state.declaro && this.state.comprome && acepto && this.state.condiciones  && this.state.privacidad){
            this.setState({ boxes: 1})
        }else{
            this.setState({ boxes: 0})
        }
    }

    handleCondiciones = (event) => {
        let condiciones =  event.target.checked;
        this.setState({condiciones: event.target.checked})
        this.setState({ box4: 1})

        if (this.state.declaro && this.state.comprome && this.state.acepto && condiciones  && this.state.privacidad){
            this.setState({ boxes: 1})
        }else{
            this.setState({ boxes: 0})
        }
    }

    handlePrivacidad = (event) => {
        let privacidad = event.target.checked
        this.setState({privacidad: event.target.checked})
        this.setState({ box5: 1})
        //if (this.state.box1 === 1 & this.state.box2 === 1 & this.state.box3 === 1 & this.state.box4 === 1){
        //    this.setState({ boxes: 1})
        //}

        if (this.state.declaro && this.state.comprome && this.state.acepto && this.state.condiciones  && privacidad){
            this.setState({ boxes: 1})
        }else{
            this.setState({ boxes: 0})
        }
    }

    handleIdentificacion = (event) => {
        this.setState({ identificacion: event.target.value})
    }

    handleComprobante = (event) => {
        this.setState({ comprobante: event.target.value })
    }

    handleCloseDropZone = () => {
        this.setState({openDropZone:false})
    }

    handleSaveFiles = (files) => {
        
        this.setState ({ identificacion: files[0].name, openDropZone: false})
        this.setState ({ files: files[0]})
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleOpenDropZone = () => {
        this.setState ({openDropZone: true})
    }

    handleCloseDropZone2 = () => {
        this.setState({openDropZone2:false})
    }

    handleSaveFiles2 = (files) => {
        
        this.setState ({ comprobante: files[0].name, openDropZone2: false})
        this.setState ({ files2: files[0]})
        setTimeout(() => {  this.validAllForm() }, 1000);
    }

    handleOpenDropZone2 = () => {
        this.setState ({openDropZone2: true})
    }

    handleEmail = (event) =>{
        this.setState( {email: event.target.value})
    }

    sendForm = () => {

        let flag_error = 0

        if(this.state.nombre === ''){
            this.setState({nombreEmpty: 0})
            flag_error = 1;
        }

        if(this.state.nombreOficial === ''){
            this.setState({nombreOficialEmpty: 0})
            flag_error = 1;
        }

        if(this.state.genero === ''){
            this.setState({generoEmpty: 0})
            flag_error = 1;
        }

        if(this.state.celular === ''){
            this.setState({celularEmpty: 0})
            flag_error = 1;
        }
        
        if(this.state.estadoValue === ''){
            this.setState({residenciaEmpty: 0})
            flag_error = 1;
        }

        if(this.state.actualmenteValue === ''){
            this.setState({actualmenteEmpty: 0})
            flag_error = 1;
        }

        if(this.state.motivacionValue === ''){
            this.setState({motivacionEmpty: 0})
            flag_error = 1;
        }

        if(this.state.ingresosValue === ''){
            this.setState({ingresoEmpty: 0})
            flag_error = 1;
        }

        if(this.state.dedicacionesValue === ''){
            this.setState({horasEmpty: 0})
            flag_error = 1;
        }

        if(this.state.apellido === ''){
            this.setState({apellidoEmpty: 0})
            flag_error = 1;
        }

        if(this.state.apellidoOficial === ''){
            this.setState({apellidoOficialEmpty: 0})
            flag_error = 1;
        }

        if(this.state.edad === ''){
            this.setState({edadEmpty: 0})
            flag_error = 1;
        }

        if(this.state.checkCelular === ''){
            this.setState({checkCelularEmpty: 0})
            flag_error = 1;
        }

        if(this.state.codigo === ''){
            this.setState({codigoEmpty: 0})
            flag_error = 1;
        }

        if(this.state.estudiosValue === ''){
            this.setState({estudiosEmpty: 0})
            flag_error = 1;
        }

        if(this.state.estudiosValue >= 4){
            
            if(this.state.universidadesValue  === ''){
                this.setState({universidadEmpty: 0})
                flag_error = 1;
            }else{
                if(this.state.universidadesValue === 0 && this.state.otraUniversidad === ''){
                    this.setState({universidadOtraEmpty: 0})
                    flag_error = 1;
                }
            }
        }


        if(this.state.promediosValue === ''){
            this.setState({promedioEmpty: 0})
            flag_error = 1;
        }

        if(this.state.difusionesValue === ''){
            this.setState({canalesEmpty: 0})
            flag_error = 1;
        }

        if(this.state.motivosValue === ''){
            this.setState({motivoEmpty: 0})
            flag_error = 1;
        }

        if(this.state.curp === ''){
            this.setState({curpEmpty: 0})
            flag_error = 1;
        }

        if(this.state.organizacionValue === ''){
            this.setState({organizacionEmpty: 0})
            flag_error = 1;
        }
        if(this.state.validate.is_valid_curp === 0 || this.state.validate.is_valid_edad === 0 || this.state.validate.is_valid_cp === 0 || this.state.validate.is_valid_cel === 0 || this.state.validate.is_valid_check_cel === 0){
            flag_error = 1;
        }

        if(this.state.files.length === 0){
            flag_error = 1;
            this.setState({identificacionEmpty: 0})
        }

        if(this.state.files2.length === 0){
            flag_error = 1;
            this.setState({comprobanteEmpty: 0})
        }

        if(this.state.files.name && this.state.files.name === this.state.files2.name){
            flag_error = 1;
            this.setState({alert_duplicate_file: 1,comprobanteEmpty: 0,identificacionEmpty: 0});
        }

        if(flag_error === 1){
            return false;
        }

        const bodyFormData = new FormData();
        bodyFormData.append('file_identificacion',this.state.files);
        bodyFormData.append('email',this.state.email);
        bodyFormData.append('nombre',this.state.nombre);
        bodyFormData.append('nombre_oficial',this.state.nombreOficial);
        bodyFormData.append('apellido',this.state.apellido);
        bodyFormData.append('apellido_oficial',this.state.apellidoOficial);
        bodyFormData.append('edad',this.state.edad);
        bodyFormData.append('telefono_celular',this.state.celular);
        bodyFormData.append('acceso_internet',this.state.computadoraReal);
        bodyFormData.append('codigo_postal',this.state.codigo);
        bodyFormData.append('curp',this.state.curp.toUpperCase());
        bodyFormData.append('acceso_computadora',this.state.dispositivoReal);
        bodyFormData.append('id_catalogo_estado',this.state.estadoValue);
        bodyFormData.append('id_catalogo_ocupacion',this.state.actualmenteValue);
        bodyFormData.append('id_catalogo_estudio',this.state.estudiosValue);
        bodyFormData.append('id_catalogo_promedio',this.state.promediosValue);
        bodyFormData.append('id_catalogo_dedicacion',this.state.dedicacionesValue);
        bodyFormData.append('id_catalogo_motivo',this.state.motivosValue);
        bodyFormData.append('id_catalogo_motivacion',this.state.motivacionValue);
        bodyFormData.append('id_catalogo_ingresos',this.state.ingresosValue);
        bodyFormData.append('id_catalogo_genero',this.state.genero);
        bodyFormData.append('id_catalogo_difusion',this.state.difusionesValue);
        bodyFormData.append('id_catalogo_comunidad',1);
        bodyFormData.append('file_estudios',this.state.files2);
        bodyFormData.append('id_catalogo_universidad',this.state.universidadesValue);
        bodyFormData.append('otra_universidad',this.state.otraUniversidad);
        bodyFormData.append('id_catalogo_organizacion',this.state.organizacionValue);


        const data = {
            file_identificacion: this.state.files,
            email: this.state.email,
            nombre: this.state.nombre,
            apellido: this.state.apellido,
            edad: this.state.edad,
            telefono_celular: this.state.celular,
            acceso_internet: this.state.computadoraReal,
            codigo_postal: this.state.codigo,
            inroader: 'No',
            curp: this.state.curp,
            acceso_computadora: this.state.dispositivoReal,
            id_catalogo_estado: this.state.estadoValue,
            id_catalogo_ocupacion:this.state.actualmenteValue,
            id_catalogo_estudio: this.state.estudiosValue,
            id_catalogo_promedio: this.state.promediosValue,
            id_catalogo_dedicacion: this.state.dedicacionesValue,
            id_catalogo_motivo: this.state.motivosValue,
            id_catalogo_motivacion: this.state.motivacionValue,
            id_catalogo_ingresos: this.state.ingresosValue,
            id_catalogo_genero: this.state.genero,
            id_catalogo_difusion: this.state.difusionesValue,
            id_catalogo_comunidad: 1,
            file_estudios: this.state.files2,
            id_catalogo_universidad: this.state.universidadesValue,
            id_catalogo_organizacion: this.state.organizacionValue
        }

        console.log(data)

        axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/form/write_form/', bodyFormData, {
            auth: {
              username: sessionStorage.getItem('email'),
            }
          })
        .then(res => {
            if (res.data.message === 'ok'){
                sessionStorage.setItem('formulario','si')
                this.setState({ formulario: 0})
            }
        })
        .catch(error => {
            console.error(error)
        })
    }

    render() {
        return(
            <div>
                <AppBar />
                <div>
                    <ParallaxImg ></ParallaxImg>
                </div>
                { this.state.formulario ? 
                <div className='content content-formulario'>
                    <Grid container rowSpacing={6} columnSpacing={8}>
                        {/* Row */}
                        <Grid item xs={12} md={12}>
                            <Typography variant="h4" color="text.secondary" style={{textAlign:"left", fontWeight:"lighter"}}>
                                Formulario de inscripción
                            </Typography>
                        </Grid>

                        {/* Row */}
                        <Grid item xs={12} md={12}>
                            <Alert severity="info">Recuerda que debes de llenar tu formulario</Alert>
                        </Grid>
                        
                        
                        {/* Row */}
                        <Grid item xs={12} md={6}>
                            <TextField error={!this.state.nombreOficialEmpty} 
                            autoFocus
                            margin="dense"
                            id="nombreOficial"
                            label="Nombre/s oficial"
                            type="text"
                            fullWidth
                            variant="standard"
                            inputProps={{ 
                                            maxLength: 40,
                                        }}
                            onChange={this.handleNombreOficial}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                error={!this.state.apellidoOficialEmpty}
                                margin="dense"
                                id="apellidoOficial"
                                label="Apellido/s oficial"
                                type="text"
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    maxLength: 100,
                                }}
                                onChange={this.handleApellidoOficial}/>
                        </Grid>

                        {/* Row */}
                        
                        <Grid item xs={12} md={6}>
                            <TextField error={!this.state.nombreEmpty} 
                            margin="dense"
                            id="nombre"
                            label="Nombre con el cual quieres que aparezca tu Certificado"
                            type="text"
                            fullWidth
                            variant="standard"
                            inputProps={{ 
                                            maxLength: 40,
                                        }}
                            onChange={this.handleNombre}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                error={!this.state.apellidoEmpty}
                                margin="dense"
                                id="apellido"
                                label="Apellido con el cual quieres que aparezca tu Certificado"
                                type="text"
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    maxLength: 100,
                                }}
                                onChange={this.handleApellido}/>
                        </Grid>

                        {/* Row */}
                        <Grid item xs={12} md={6}>
                            <TextField 
                            error={!this.state.curpEmpty || !this.state.validate.is_valid_curp}
                            helperText={this.state.validate.message_curp}
                            margin="dense"
                            id="curp"
                            label="Ingresa tu CURP"
                            type="text"
                            fullWidth
                            variant="standard"
                            inputProps={{ 
                                maxLength: 18,
                                minLength: 18,
                                style: { textTransform: "uppercase" }
                            }}
                            onChange={this.handleCurp}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p style={{fontWeight:"lighter"}}>Si no sabes cual es tu CURP, puedes consultarlo 
                            <a className='link' href='https://www.gob.mx/curp/' target="_blank" rel="noopener noreferrer"> aqui. </a>
                            </p>
                        </Grid>
                        {/* Row */}
                        <Grid item xs={12} md={6}>
                                <FormControl error={!this.state.generoEmpty}
                                    fullWidth variant="standard" >
                                    <InputLabel id="genero">Género</InputLabel>
                                    <Select labelId="genero" id="chooseGenero" value={this.state.generoValue} onChange={this.handleGenero}>
                                        {this.state.generos.map(genero=>(
                                            <MenuItem key={genero.id_catalogo_genero} value={genero.id_catalogo_genero}>{genero.texto_genero}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl error={!this.state.residenciaEmpty} fullWidth variant="standard" >
                                <InputLabel id="estado">Estado de residencia</InputLabel>
                                <Select labelId="estado" id="chooseEstado" value={this.state.estadoValue} onChange={this.handleEstado}>
                                    {this.state.estados.map(estado=>(
                                        <MenuItem key={estado.id_catalogo_estado} value={estado.id_catalogo_estado}>{estado.texto_estado}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Row */}
                        <Grid item xs={12} md={6}>
                            <TextField 
                                error={!this.state.edadEmpty || !this.state.validate.is_valid_edad}
                                helperText={this.state.validate.message_edad}
                                margin="dense"
                                id="edad"
                                label="Edad"
                                type="text"
                                fullWidth
                                variant="standard"
                                inputProps={{ 
                                    maxLength: 2,
                                    minLength: 2,
                                }}
                                onChange={this.handleEdad}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                            error={!this.state.codigoEmpty || !this.state.validate.is_valid_cp}
                            helperText="A cinco digitos"
                            margin="dense"
                            id="cp"
                            label="Código Postal"
                            type="text"
                            fullWidth
                            variant="standard"
                            inputProps={{ 
                                maxLength: 5,
                                minLength: 5,
                            }}
                            onChange={this.handleCodigo}/>
                        </Grid>
                        {/* Row */}
                        <Grid item xs={12} md={6}>
                            <TextField 
                            error={!this.state.celularEmpty || !this.state.validate.is_valid_cel}
                            margin="dense" 
                            id="telefono" 
                            label="Teléfono celular (10 dígitos)" 
                            type="text"
                            fullWidth
                            variant="standard"
                            inputProps={{ 
                                maxLength: 10,
                                minLength: 10,
                            }}
                            onChange={this.handleCelular}/>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                error={!this.state.checkCelularEmpty || !this.state.validate.is_valid_check_cel}
                                margin="dense"
                                id="checkCell"
                                label="Confirmar teléfono celular"
                                type="text"
                                fullWidth
                                variant="standard"
                                inputProps={{ 
                                    maxLength: 10,
                                    minLength: 10,
                                }}
                                onChange={this.handlecheckCelular}/>
                        </Grid>
                        {/* Row */}
                        <Grid item xs={12} md={12}>
                            <div style={{margin:'30px 0px'}}>
                                <hr style={{width:'90%', margin:'auto', border:'1px solid #dadada'}} />
                            </div>
                        </Grid>
                        {/* Row */}
                        <Grid item xs={12} md={6}>
                            { this.state.actualmenteEmpty ? <FormControl fullWidth variant="standard" >
                                <InputLabel id="actualmente">¿Actualmente te encuentras?</InputLabel>
                                <Select labelId="actualmente" id="chooseActualmente" value={this.state.actualmenteValue} onChange={this.handleActualmente}>
                                    {this.state.actualmente.map(actual=>(
                                        <MenuItem key={actual.id_catalogo_ocupacion} value={actual.id_catalogo_ocupacion}>{actual.texto_ocupacion}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : <FormControl error fullWidth variant="standard" >
                                <InputLabel id="actualmente">¿Actualmente te encuentras?</InputLabel>
                                <Select labelId="actualmente" id="chooseActualmente" value={this.state.actualmenteValue} onChange={this.handleActualmente}>
                                    {this.state.actualmente.map(actual=>(
                                        <MenuItem key={actual.id_catalogo_ocupacion} value={actual.id_catalogo_ocupacion}>{actual.texto_ocupacion}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> 
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                        { this.state.horasEmpty ? <FormControl fullWidth variant="standard" >
                                <InputLabel id="dedicaciones">¿Cuántas horas a la semana le puedes dedicar al curso?</InputLabel>
                                <Select labelId="dedicaiones" id="chooseDedicaciones" value={this.state.dedicacionesValue} onChange={this.handleDedicaciones}>
                                    {this.state.dedicaciones.map(dedicacion=>(
                                        <MenuItem key={dedicacion.id_catalogo_dedicacion} value={dedicacion.id_catalogo_dedicacion}>{dedicacion.texto_dedicacion}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : <FormControl error fullWidth variant="standard" >
                                <InputLabel id="dedicaciones">¿Cuántas horas a la semana le puedes dedicar al curso?</InputLabel>
                                <Select labelId="dedicaiones" id="chooseDedicaciones" value={this.state.dedicacionesValue} onChange={this.handleDedicaciones}>
                                    {this.state.dedicaciones.map(dedicacion=>(
                                        <MenuItem key={dedicacion.id_catalogo_dedicacion} value={dedicacion.id_catalogo_dedicacion}>{dedicacion.texto_dedicacion}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                        </Grid>
                        {/* Row */}
                        <Grid item xs={12} md={6}>
                            { this.state.motivacionEmpty ? 
                                <FormControl fullWidth variant="standard" >
                                <InputLabel id="motivacion">¿Cuál es tu motivación por el que deseas realizar el curso?</InputLabel>
                                <Select labelId="motivacion" id="chooseMotivacion" value={this.state.motivacionValue} onChange={this.handleMotivacion}>
                                    {this.state.motivacion.map(motiva=>(
                                        <MenuItem key={motiva.id_catalogo_motivacion} value={motiva.id_catalogo_motivacion}>{motiva.texto_motivacion}</MenuItem>
                                    ))}
                                </Select>
                                </FormControl> :
                                <FormControl error fullWidth variant="standard" >
                                <InputLabel id="motivacion">¿Cuál es tu motivación por el que deseas realizar el curso?</InputLabel>
                                <Select labelId="motivacion" id="chooseMotivacion" value={this.state.motivacionValue} onChange={this.handleMotivacion}>
                                    {this.state.motivacion.map(motiva=>(
                                        <MenuItem key={motiva.id_catalogo_motivacion} value={motiva.id_catalogo_motivacion}>{motiva.texto_motivacion}</MenuItem>
                                    ))}
                                </Select>
                                </FormControl>    
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            { this.state.ingresoEmpty ? <FormControl fullWidth variant="standard" >
                                <InputLabel id="ingresos">¿Cuál es tu ingreso familiar mensual?</InputLabel>
                                <Select labelId="ingresos" id="chooseIngresos" value={this.state.ingresosValue} onChange={this.handleIngresos}>
                                    {this.state.ingresos.map(ingreso=>(
                                        <MenuItem key={ingreso.id_catalogo_ingresos} value={ingreso.id_catalogo_ingresos}>{ingreso.texto_ingresos}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : <FormControl error fullWidth variant="standard" >
                                <InputLabel id="ingresos">¿Cuál es tu ingreso familiar mensual?</InputLabel>
                                <Select labelId="ingresos" id="chooseIngresos" value={this.state.ingresosValue} onChange={this.handleIngresos}>
                                    {this.state.ingresos.map(ingreso=>(
                                        <MenuItem key={ingreso.id_catalogo_ingresos} value={ingreso.id_catalogo_ingresos}>{ingreso.texto_ingresos}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> }

                        </Grid>
                        {/* Row */}
                        <Grid item xs={12} md={6}>
                        {this.state.canalesEmpty ? <FormControl fullWidth variant="standard" >
                                <InputLabel id="difusiones">¿Cómo te enteraste de este programa?</InputLabel>
                                <Select labelId="difusiones" id="chooseDifusiones" value={this.state.difusionesValue} onChange={this.handleDifusiones}>
                                    {this.state.difusiones.map(difusion=>(
                                        <MenuItem key={difusion.id_catalogo_difusion} value={difusion.id_catalogo_difusion}>{difusion.texto_difusion}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : <FormControl error fullWidth variant="standard" >
                                <InputLabel id="difusiones">¿Cómo te enteraste de este programa?</InputLabel>
                                <Select labelId="difusiones" id="chooseDifusiones" value={this.state.difusionesValue} onChange={this.handleDifusiones}>
                                    {this.state.difusiones.map(difusion=>(
                                        <MenuItem key={difusion.id_catalogo_difusion} value={difusion.id_catalogo_difusion}>{difusion.texto_difusion}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            { this.state.motivoEmpty ? <FormControl fullWidth variant="standard" >
                                <InputLabel id="motivo">¿Cuál es el motivo por el que solicitas una beca?</InputLabel>
                                <Select labelId="motivo" id="chooseMotivo" value={this.state.motivosValue} onChange={this.handleMotivos}>
                                    {this.state.motivos.map(motivo=>(
                                        <MenuItem key={motivo.id_catalogo_motivo} value={motivo.id_catalogo_motivo}>{motivo.texto_motivo}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : <FormControl error fullWidth variant="standard" >
                                <InputLabel id="motivo">¿Cuál es el motivo por el que solicitas una beca?</InputLabel>
                                <Select labelId="motivo" id="chooseMotivo" value={this.state.motivosValue} onChange={this.handleMotivos}>
                                    {this.state.motivos.map(motivo=>(
                                        <MenuItem key={motivo.id_catalogo_motivo} value={motivo.id_catalogo_motivo}>{motivo.texto_motivo}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> }
                        </Grid>

                        {/* Row */}
                        <Grid item xs={12} md={6}>
                            <FormControl error={!this.state.estudiosEmpty} fullWidth variant="standard" >
                                <InputLabel id="estudios">¿Cuál es tu nivel de estudios?</InputLabel>
                                <Select labelId="estudios" id="chooseEstudios" value={this.state.estudiosValue} onChange={this.handleEstudios}>
                                    {this.state.estudios.map(estudio=>(
                                        <MenuItem key={estudio.id_catalogo_estudio} value={estudio.id_catalogo_estudio}>{estudio.texto_estudio}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        { this.state.promedioEmpty ? 
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" >
                                <InputLabel id="promedios">Promedio general de tu último nivel de estudios</InputLabel>
                                <Select labelId="promedios" id="choosePromedios" value={this.state.promediosValue} onChange={this.handlePromedios}>
                                    {this.state.promedios.map(promedio=>(
                                        <MenuItem key={promedio.id_catalogo_promedio} value={promedio.id_catalogo_promedio}>{promedio.texto_promedio}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                         :
                        <Grid item xs={12} md={6}>
                           <FormControl error fullWidth variant="standard" >
                                <InputLabel id="promedios">Promedio general de tu último nivel de estudios</InputLabel>
                                <Select labelId="promedios" id="choosePromedios" value={this.state.promediosValue} onChange={this.handlePromedios}>
                                    {this.state.promedios.map(promedio=>(
                                        <MenuItem key={promedio.id_catalogo_promedio} value={promedio.id_catalogo_promedio}>{promedio.texto_promedio}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        }
                        {/* Row */}
                        {this.state.open_university? 
                        <Grid item xs={12} md={6}>
                            
                                <FormControl fullWidth variant="standard" >
                                         {/* <InputLabel id="universidades">¿A que universidad perteneces?</InputLabel> */ }
                                        
                                        <Autocomplete
                                        disablePortal
                                        id="auto_complete"
                                        options={this.state.universidades}
                                        onChange={this.handleUniversidades}
                                        renderInput={(params) => <TextField {...params} label="¿A que universidad perteneces?" />}
                                        />
                                    </FormControl>
                            
                        </Grid>
                        : null}
                        {this.state.otra_university?
                        <Grid item xs={12} md={6}>
                            <TextField error={!this.state.universidadOtraEmpty} 
                                autoFocus
                                margin="dense"
                                id="otra_universidad"
                                label="Especificar otra"
                                type="text"
                                fullWidth
                                variant="standard"
                                inputProps={{ 
                                                maxLength: 100,
                                            }}
                                onChange={this.handleOtraUniversidad}/>
                        
                        </Grid>
                        : null }
                      {/* Row */}
                       <Grid item xs={12} md={6}>
                        <FormControl 
                            error={!this.state.organizacionEmpty}
                            fullWidth
                            variant="standard" >
                            <InputLabel id="estudios">¿Perteneces a alguna de estas organizaciones?</InputLabel>
                            <Select
                            labelId="organizacion"
                            id="chooseOrganizacion"
                            value={this.state.organizacionValue}
                            onChange={this.handleOrganizacion}>
                            {this.state.organizaciones.map(organizacion=>(
                                    <MenuItem key={organizacion.id_catalogo_organizacion}
                                              value={organizacion.id_catalogo_organizacion}>{organizacion.texto_organizacion}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                    {/* Row */}
                    <Grid item xs={12} md={6}>
                        {this.state.inroader!== "" ?
                            <TextField 
                            disabled 
                            margin="dense"
                            id="inroader"
                            label="Soy parte de INROADS"
                            type="text" fullWidth
                            variant="standard"
                            value={this.state.inroader}/>
                            : null }
                    </Grid>
                    {/* Row */}
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth variant="standard" onChange={this.handleDispositivo}>
                            <RadioGroup row aria-labelledby="dispositivo" name="haveDispositivo">
                                <Grid container rowSpacing={1} columnSpacing={8}>
                                    <Grid item xs={12} md={6}>
                                        <p style= {{ paddingRight:'50px',fontWeight:"lighter"}}>¿Cuentas con computadora, tablet o dispositivo electrónico?</p>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {/* Row */}

                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth variant="standard" onChange={this.handleInternet}>
                            <RadioGroup row aria-labelledby="internet" name="haveInternet">
                                <Grid container rowSpacing={1} columnSpacing={8}>
                                    <Grid item xs={12} md={6}>
                                        <p style= {{ paddingRight:'50px', fontWeight:"lighter"}}>¿Cuentas con acceso a Internet?</p>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>

                        <Grid item xs={1} md={4}></Grid>
                        <Grid item xs={10} md={3}>
                            { this.state.alert_duplicate_file? 
                                <Alert severity="error" style={{fontSize: "12px"}}>El documento se encuentra duplicado</Alert> 
                            : null }
                        </Grid>
                        <Grid item xs={1} md={5}></Grid>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl error={!this.state.identificacionEmpty} fullWidth style={{paddingTop:'50px'}} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Identificación oficial con fotografía</InputLabel>
                            <Input disabled
                                value={this.state.identificacion}
                                id="standard-adornment-password"
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton color="primary" aria-label="upload picture" onClick={this.handleOpenDropZone}>
                                        <FileUploadIcon />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            <DropzoneDialog
                                 open={this.state.openDropZone}
                                 onSave={this.handleSaveFiles.bind(this)}
                                 acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                                 showPreviews={true}
                                 maxFileSize={5000000}
                                 dropzoneText="Carga tu identificación"
                                 filesLimit={1}
                                 dialogTitle=""
                                 cancelButtonText="Cancelar"
                                 submitButtonText="Subir"
                                 previewText="Verificar"
                                 onClose={this.handleCloseDropZone.bind(this)}
                            />
                               <FormHelperText id="helper_comprobante_estudios">
                            Formato jpeg, png, pdf y límite de 6MB.
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl 
                         error={!this.state.comprobanteEmpty} 
                         fullWidth style={{paddingTop:'50px'}} 
                         variant="standard">
                            <InputLabel htmlFor="standard-adornment-password-2">Comprobante de tu último nivel de estudios</InputLabel>
                            <Input disabled
                                value={this.state.comprobante}
                                id="standard-adornment-password-2"
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton color="primary" aria-label="upload picture" onClick={this.handleOpenDropZone2}>
                                        <FileUploadIcon />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            <DropzoneDialog
                            open={this.state.openDropZone2}
                            onSave={this.handleSaveFiles2.bind(this)}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp','application/pdf']}
                            showPreviews={true}
                            maxFileSize={5000000}
                            dropzoneText="Carga tu comprobante"
                            dialogTitle=""
                            filesLimit={1}
                            cancelButtonText="Cancelar"
                            submitButtonText="Subir"
                            previewText="Verificar"
                            onClose={this.handleCloseDropZone2.bind(this)}
                                                />
                            <FormHelperText id="helper_comprobante_estudios">
                            Formato jpeg, png, pdf y límite de 6MB.
                            <br></br>
                            Si no cuentas con un comprobante de tu ultimo nivel de estudio, favor de compartir una carta bajo protesta de decir 
                            verdad indicando la universidad, carrera, promedio y fechas de inicio y termino
                        </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <b>Para concluir solicitud debes seleccionar todas las casillas y dar clic en "Guardar y enviar".</b>
                        <FormGroup className='form-cheks'>
                            
                            <FormControlLabel control={<Checkbox />} label="Declaro que toda la información que proporcione es verdadera,completa y correcta" onChange={this.handleDeclaro}/>
                            <FormControlLabel control={<Checkbox />} label="Me comprometo a terminar en tiempo y forma de acuerdo a lo establecido, en caso de ser seleccionad@ para tener 1 beca del programa Certificados de carrera de Google" onChange={this.handleComprometo} />
                            <FormControlLabel control={<Checkbox />} label="Acepto que mis datos personales de identificación,de contacto, academicos y financieros, sean tratados conforme a lo señalado en el presente." onChange={this.handleAcepto} />
                            <FormControlLabel control={<Checkbox />} label="Condiciones de uso: Deberás seleccionar uno de los 4 cursos para poder solicitar la beca. Si fuiste seleccionad@, recibirás un mail de Coursera con el alta del curso que elegiste en un plazo de 1 a 3 semanas. Entra a tu cuenta de Coursera (si no tienes una, date de alta) para empezar con tu curso. Es importante que recuerdes que el correo registrado inicialmente será con el cual continuarás durante todo tu proceso y no habrá ningún cambio. Tienes que hacer este paso dentro de las dos primeras semanas, de lo contrario, la beca será asignada a otro candidato.
                            En caso de que quieras realizar más cursos, deberás primero concluir el curso al que estás inscrito, podrás seleccionar el siguiente curso que deseas tomar directo en la plataforma. Recuerda que tu beca expira el 31 de marzo de 2023. Recibirás el certificado al momento de concluir cada curso. 
                            Te adelantamos que, para no perder tu beca en el camino, deberás tener avance continuo en el curso, si presentas inactividad en un plazo mayor a dos semanas perderás tu beca." onChange={this.handleCondiciones}/>
                            <FormControlLabel control={<Checkbox />} 
                            label={<a href='https://inroads.org.mx/aviso-de-privacidad/'>Aviso de privacidad</a>}
                            onChange={this.handlePrivacidad}
                            >
                            </FormControlLabel>
                        </FormGroup>
                    </Grid>

                    {/* Row */}
                    <Grid item xs={12} md={12}>
                            {this.state.boxes && !this.state.is_valid_all_form ?
                            <Alert severity="warning">Favor de revisar el formulario, existen campos vacios o invalidos.</Alert>
                            : null }
                    </Grid>

                    {/* Row */}
                    <Grid item xs={5}></Grid>
                        <Grid item xs={2}>
                        {this.state.boxes ?
                            <Button 
                                variant="contained"
                                fullWidth
                                disabled={!this.state.is_valid_all_form}
                                onClick={this.sendForm}
                                className={this.state.is_valid_all_form ? "button_ovalado" : "button_ovalado_disabled"}
                                style={{textTransform: 'None',margin: "auto"}}>
                                    Guardar y enviar
                            </Button>
                            : null}
                        </Grid>
                    <Grid item xs={5}></Grid>

                </Grid>
                </div> : 
                <div className='content content-no-movil'>
                    <BasicGrid />
                </div> 
                }
                {(this.state.estatus_beca === 1 || this.state.estatus_beca > 2 )&&
                <>
                <div>
                    <CommunityFacebook />
                </div>
                <div>
                    <Collaborators />
                </div>
                </>
                }
                <div>
                    <SimpleAccordion /> 
                </div>
                <div>
                    <StickyFooter />
                </div>
            </div>
        )
    }
}

export default Stuff