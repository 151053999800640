import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import headerInroads from "./img/inroads_becas_header_short.jpg";
import LogoutIcon from '@mui/icons-material/Logout';
import { Drawer, Divider, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router';
import ReorderIcon from '@material-ui/icons/Reorder';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentTurnedInSharpIcon from '@mui/icons-material/AssignmentTurnedInSharp';
import HistoryIcon from '@material-ui/icons/History';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import "./css/appBar.css";

const styles = {
  loginButton: {
      maxWidth: 100,
      maxHeight: 500,
      marginRight: '10px'
  }
};

class ButtonAppBar extends Component {

  state = {
    isDrawerOpened: false,
    admin: false
  }

  componentDidMount = () => {
    if(sessionStorage.getItem('Admin') === "true" ){
      this.setState({ admin:true})
    }
  }

  logout = (event) => {
    sessionStorage.setItem('isSigned',false)
    sessionStorage.clear()
    this.props.history.push('/')
  }

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    })
  }

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
      })
  }

  render() {
    const usuario = sessionStorage.getItem('email')
    const { isDrawerOpened } = this.state;

    return (
        <>
        <div id="appbar" style={{flexGrow: 1,color: 'black',background: 'white'}}>
          <AppBar position="static" style={{ background: '#F3F3F3',  Height: 500 }}>
            <Toolbar style={{ height: 100, paddingRight:0 }}>
              {this.state.admin ? 
                                  <div>
                                      <IconButton onClick={this.toggleDrawerStatus} style={styles.loginButton}>
                                      {!isDrawerOpened ? <ReorderIcon /> : null }
                                      </IconButton>
                                      <Divider/>
                                  </div>
                                  
              :<></> 
              }
              <img src={headerInroads} alt="Inroads" style={{ maxHeight: 100}}/>
              <Typography variant="h6" className="title">
                <span className="spanHeader"> Bienvenido {usuario} </span>
              </Typography>
              <IconButton aria-label="delete" onClick={this.logout}>
                <LogoutIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <div id="appbar-mobil">
        <AppBar position="static" style={{ background: '#F3F3F3',  Height: 500 }}>
          <Toolbar style={{flexFlow:'column', justifyContent:'stretch'}}>
            <div>
              <img src={headerInroads} style={{width:'100%'}} alt="Inroads"/>
            </div>
            <div style={{display:'flex'}}>
                <p className="title" style={{textAlign:'center'}}>Bienvenido {usuario}</p>
                <IconButton aria-label="delete" onClick={this.logout}>
                  <LogoutIcon />
                </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer
        variant="temporary"
        open={isDrawerOpened}
        onClose={this.closeDrawer}
        >
        <Link to='/admin' style={styles.link}>
            <List>
                <ListItem button key='Busqueda'>
                    <ListItemIcon><SearchIcon/>
                    </ListItemIcon>
                    <ListItemText primary='Busqueda' />
                </ListItem>
            </List>
        </Link>
        <Link to='/asignacion' style={styles.link}>
          <List>
            <ListItem button key='Asignación'>
                <ListItemIcon><AssignmentTurnedInSharpIcon/>
                </ListItemIcon>
                <ListItemText primary='Asignación' />
                </ListItem>
            </List>
        </Link>
        <Link to='/historial' style={styles.link}>
          <List>
            <ListItem button key='Historial'>
                <ListItemIcon><HistoryIcon/>
                </ListItemIcon>
                <ListItemText primary='Historial de versiones' />
                </ListItem>
            </List>
        </Link>
        <Link to='/certificados' style={styles.link}>
          <List>
            <ListItem button key='Cerficados'>
                <ListItemIcon><VerifiedUserIcon/>
                </ListItemIcon>
                <ListItemText primary='Administración de alumnos certificados' />
                </ListItem>
            </List>
        </Link>
        <Link to='/AlumnosExportarCoursera' style={styles.link}>
          <List>
            <ListItem button key='AlumnosExportarCoursera'>
                <ListItemIcon><FileDownloadSharpIcon/>
                </ListItemIcon>
                <ListItemText primary='Alumnos con más de un certificado' />
                </ListItem>
            </List>
        </Link>
        <Link to='/adminDismiss' style={styles.link}>
          <List>
            <ListItem button key='Cerficados'>
                <ListItemIcon>
                  <DeleteSweepIcon/>
                </ListItemIcon>
                  <ListItemText primary='Administración de baja definitiva' />
                </ListItem>
            </List>
        </Link>
        </Drawer>
      </>
    )
  }
}

export default withRouter(ButtonAppBar);
