import React, { Component } from 'react';

import AppBar from './AppBar';
import Footer from './Footer';
import BasicGrid from './Paper';
import SimpleAccordion from "./Accordion";
import StickyFooter from "./StickyFooter";
import ParallaxImg from './components/Parallax';


class Home extends Component {
    render() {
        return(
            <div>
                <AppBar />
                <div>
                    <ParallaxImg ></ParallaxImg>
                </div>
                <div className='content' style={{paddingLeft: '0px',paddingRight: '0px',paddingBottom: '0px'}}>
                    <BasicGrid />
                </div>
                <div>
                    <Footer />
                </div>
                <br />
                <br />
                <br />
                <div>
                    <SimpleAccordion />
                </div>
                <div>
                    <StickyFooter />
                </div>
            </div>
        )
    }
}

export default Home