import React, { Component } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router';
import axios from 'axios';
import TreeView from '@mui/lab/TreeView';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import "./css/landing_body.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

class SimpleAccordion extends Component {

  state = {
    nombre: '',
    email: '',
    mensaje: '',
    status_envio: 0,
    acepto_aviso: 0,
    errors: {
      code_email: 0,
      email: "",
      nombre:"",
      mensaje:""
    }
  }

  handleCheck = event => {
    this.setState({ acepto_aviso: !this.state.acepto_aviso })
  }

  handleNombre = event => {
    this.setState({ nombre: event.target.value });
    if(event.target.value !== ""){
      this.setState({errors: { ...this.state.errors, nombre: "" }});
      }
  }

  handleCorreo = event => {
    
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value) || event.target.value === "")
      this.setState({ email: event.target.value, errors: { ...this.state.errors, email: "",code_email: 0 } })
    else{
        this.setState({email: event.target.value, errors: { ...this.state.errors, email: "Por favor ingresa un correo valido",code_email: 1 }});
      }
  }

  handleMensaje = event => {
    this.setState({ mensaje: event.target.value })
    if(event.target.value !== ""){
      this.setState({errors: { ...this.state.errors, mensaje: "" }});
      }

  }

  sendSupport = event => {

    

    /*Check empty fields */
    let acc_erros = 0
    let save_current_errors = this.state.errors;

    if(this.state.errors.code_email === 1)
      acc_erros += 1;
    
    if(this.state.email === ""){
        acc_erros += 1;
        save_current_errors['email'] = "El email no puede ser vacio";
        save_current_errors['code_email'] = 1;
    }

    if(this.state.nombre === ""){
      acc_erros += 1;
      save_current_errors['nombre'] = "El nombre no puede ser vacio";
    }

    if(this.state.mensaje === ""){
        acc_erros += 1;
        save_current_errors['mensaje'] = "El mensaje no puede ser vacio";
    }

    if(acc_erros > 0){
      this.setState({errors: save_current_errors});
      return false;
    }

    this.setState({acepto_aviso: 0});

    /*Clean console */
    
    
    const headersPost = {
      'content-type': 'text/json'
    }
    
    const request = {
      nombre: this.state.nombre,
      email: this.state.email,
      mensaje: this.state.mensaje
    }

    const headers_post = JSON.stringify(headersPost)
  
    const data = request

    axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/support_help/contact_by_email/', data, headers_post )
    .then(res => {
      this.setState({status_envio: res.request.status})
      this.setState({nombre: "", email: "", mensaje: ""});
    }).catch(res =>{
      this.setState({status_envio: 500})
    })
  
  }

  render(){
    return (
      <div>

      <Box sx={{ borderColor: 'primary.main' }}>
      <Grid container spacing={3} >
       <Grid item xs={1} md={2}></Grid>
       <Grid item xs={10} md={7}>
        <TreeView className="seccion_preguntas"
        aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
          <TreeItem nodeId="1" label="Condiciones uso de la beca">
            <p>
              Deberás seleccionar uno de los 4 cursos para poder solicitar la beca. Si fuiste seleccionad@, recibirás un mail de Coursera con el alta del curso que elegiste en un plazo de 1 a 3 semanas. Entra a tu cuenta de Coursera (si no tienes una, date de alta) para empezar con tu curso. Es importante que recuerdes que el correo registrado inicialmente será con el cual continuarás durante todo tu proceso y no habrá ningún cambio. Tienes que hacer este paso dentro de las dos primeras semanas, de lo contrario, la beca será asignada a otro candidato.
            </p>
            <p>
            En caso de que quieras realizar más cursos, deberás primero concluir el curso al que estás inscrito, podrás seleccionar el siguiente curso que deseas tomar directo en la plataforma. Recuerda que tu beca expira el 31 de marzo de 2023. Recibirás el certificado al momento de concluir cada curso.
            </p>
            <p>
              Te adelantamos que, para no perder tu beca en el camino, deberás tener avance continuo en el curso, si presentas inactividad en un plazo mayor a dos semanas perderás tu beca.
            </p>
          </TreeItem>

          <TreeItem nodeId="2" label="Preguntas frecuentes">
            <TreeItem nodeId="3" style={{color:'#4285F4'}} label="Los Certificados de Carrera de Google">
              
              <TreeItem nodeId="301" style={{color:'black'}} label="¿Qué son los Certificados de Carrera de Google?">
                <TreeItem nodeId="302" style={{color:'black'}}/>
                  <p>
                  Los Certificados de Carrera de Google son programas de formación online flexibles y diseñados
                   para adquirir competencias profesionales en campos con una demanda elevada y gran crecimiento. 
                   Desarrollan habilidades a través de actividades en un laboratorio virtual y otras evaluaciones 
                   interactivas que pondrán a prueba tus conocimientos.
                  </p>
                </TreeItem>

              <TreeItem nodeId="311" style={{color:'black'}} label="¿Se necesita formación o experiencia previa para realizar estos Certificados?">
                <TreeItem nodeId="312" style={{color:'black'}}/>
                  <p>
                    ¡Ninguno! No se necesita formación o experiencia previa, 
                    más allá de que seas un novato en el campo el Certificado de Carrera de Google 
                    es el programa ideal para ti.
                  </p>
              </TreeItem>

              <TreeItem nodeId="321" style={{color:'black'}} label="¿Cuáles son los Certificados en los que aplica la beca?">
                <TreeItem nodeId="322" style={{color:'black'}}/>
                  <p>
                  La beca aplica para los nuevos Certificados de Carrera de Google en español, que son los siguientes:
                  </p>
                  <br></br>
                  <ul>
                  <li>Soporte de Tecnología de la Información</li>
                  <li>Análisis de Datos</li>
                  <li>Gestión de Proyectos</li>
                  <li>Diseño de Experiencia de Usuario</li>
                  </ul>
                  <br></br>
              </TreeItem>

              <TreeItem nodeId="331" style={{color:'black'}} label="¿Puedo acceder a más de un Certificado de Carrera de Google con una sola beca?">
                <TreeItem nodeId="332" style={{color:'black'}}/>
                  <p>
                  Si, pero tendrás que obtener primero el Certificado del curso al cual te inscribiste 
                  inicialmente antes de solicitar otro, y deberás concluir antes del 31 de marzo 2023. 
                  Te recomendamos concluir el primer Certificado lo antes posible para que puedas tener el 
                  tiempo suficiente para concluir otros Certificados antes de que venza tu beca. 
                  </p>
              </TreeItem>
            
              <TreeItem nodeId="341" style={{color:'black'}} label="¿Cuántas horas aproximadamente me llevará hacer un Certificado?">
                <TreeItem nodeId="342" style={{color:'black'}}/>
                  <p>
                  Cada alumno tiene un ritmo distinto, pero normalmente cada Certificado requiere de 5 a 10 horas de 
                  aprendizaje por semana, durante 6 meses. En el caso de haber recibido una beca, requieres concluir 
                  al menos uno de los cuatro Certificados antes del 31 de marzo de 2023. Te sugerimos ampliamente 
                  organizar tu horario semanal para lograr concluir en tiempo y forma.  
                  ¡Aprovecha al máximo esta gran oportunidad!
                  </p>
              </TreeItem>

              <TreeItem nodeId="351" style={{color:'black'}} label="¿Los Certificados de Carrera de Google son 100% online?">
                <TreeItem nodeId="352" style={{color:'black'}}/>
                  <p>
                  Los certificados son completamente en línea. Por lo tanto, no es necesario que te presentes a un salón de clases. 
                  Puedes acceder a videos instructivos, lecturas y asignaciones cuando desees y desde cualquier lugar, 
                  a través de la Web o tu dispositivo móvil. 
                  Accederás a los certificados utilizando la plataforma Coursera. 
                  Si ya tienes una cuenta Coursera, podrás utilizarla, y si no, deberás 
                  crear una cuenta una vez que tu beca fue otorgada.
                  </p>
              </TreeItem>


              <TreeItem nodeId="361" style={{color:'black'}} label="¿Qué horario tendrán las clases?">
                <TreeItem nodeId="362" style={{color:'black'}}/>
                  <p>
                  Los cursos son 100% de autoestudio online dentro de la plataforma Coursera y puedes 
                  realizarlos a tu propio ritmo en los horarios que más te convengan. 
                  La plataforma funciona 24 horas durante todo el año.
                  </p>
              </TreeItem>


              <TreeItem nodeId="371" style={{color:'black'}} label="¿Cuándo tendrá lugar el curso?">
                <TreeItem nodeId="372" style={{color:'black'}}/>
                  <p>
                  Podrás empezar el curso a partir del momento en el que se confirme tu beca. 
                  Esto lleva un plazo de una a tres semanas después de haberla solicitado.  
                  La beca estará vigente hasta el 31 de marzo de 2023, fecha en la cual tendrás 
                  que haber terminado por lo menos uno de los cuatro cursos.
                  </p>
              </TreeItem>
            
              <TreeItem nodeId="381" style={{color:'black'}} label="¿Qué es Coursera?">
                <TreeItem nodeId="382" style={{color:'black'}}/>
                  <p>
                  Coursera es una plataforma global de aprendizaje en línea que ofrece acceso a cursos en línea. 
                  Google trabaja con Coursera para que los Certificados de Carrera de Google estén disponibles en su 
                  plataforma. Si ya tienes una cuenta Coursera, podrás utilizarla, si no, deberás crear una cuenta 
                  nueva una vez que tu beca fue otorgada.
                  </p>
              </TreeItem>

              <TreeItem nodeId="391" style={{color:'black'}} label="¿Quién diseñó los Certificados de Carrera de Google?">
                <TreeItem nodeId="392" style={{color:'black'}}/>
                  <p>
                  Expertos en la materia y profesionales avanzados de Google de cada campo de trabajo diseñaron y 
                  crearon los Certificados de carrera de Google. Los certificados se crearon para brindarles a los 
                  alumnos conocimientos teóricos y prácticos, y habilidades de resolución de problemas de la vida real a 
                  fin de ser exitosos en un puesto básico. Organizaciones y plataformas expertas de la industria, 
                  como Project Management Institute para la administración de proyectos, Tableu para análisis de datos y 
                  firma para diseño de experiencia del usuario, colaboraron con este material.
                  </p>
              </TreeItem>
            </TreeItem>

            <TreeItem nodeId="40" style={{color:'#4285F4'}} label="Las Becas para los Certificados de Carrera de Google">
              
              <TreeItem nodeId='401' style={{ color:'black'}} label="¿Cuáles son los requisitos para solicitar una beca?">
                <TreeItem nodeId='402' style={{ color:'black' }} />
                <p>
                  <ul>
                    <li>Ser una persona comprometida con ganas de crecer y desarrollarse, y con interés de
                    aprender en temas relacionados a las becas ofrecidas, cambiar de área laboral, obtener un
                    empleo, una promoción, emprender o impulsar su carrera profesional.</li>
                    <li>Estudio mínimo de preparatoria terminada.</li>
                    <li>Acceso a computadora, tablet o dispositivo electrónico y a Internet.</li>
                    <li>Disponibilidad de tiempo.</li>
                    <li>Con compromiso de concluir al menos uno de los 4 cursos antes del 31 de marzo de
                    2023. (Considera que cada curso requiere de alrededor de 5 a 10 horas semanales para
                    concluirlo en 6 meses).</li>
                  </ul>
                </p>
              </TreeItem>

              <TreeItem nodeId='411' style={{ color:'black'}} label="¿Debo ser estudiante universitario para aplicar a esta beca?">
                <TreeItem nodeId='412' style={{ color:'black' }} />
                <p>
                  No es necesario. Cualquier persona, mayor de edad y nacional o residente de los Estados Unidos de México 
                  puede ser candidato para esta beca, si cumple con los requisitos.
                </p>
              </TreeItem>
            

              <TreeItem nodeId='421' style={{ color:'black'}} label="¿Puedo solicitar una beca para más de un Certificado de Carrera de Google?">
                <TreeItem nodeId='422' style={{ color:'black' }} />
                <p>
                No, pero podrás acceder a los otros Certificados una vez que acabes el Certificado al 
                cual te inscribiste inicialmente. 
                </p>
              </TreeItem>

              <TreeItem nodeId='431' style={{ color:'black'}} label="¿Cómo solicito una beca para preparar uno de los Certificados de Carrera de Google?">
                <TreeItem nodeId='432' style={{ color:'black' }} />
                <p>
                Primero te tienes que registrar dentro de la página INROADS (https://becasgoogle2022.inroads.org.mx/). 
                Una vez registrad@, inicia sesión y selecciona el Certificado que más te interesa 
                (dando clic al círculo de color con flecha) y completa el formulario de inscripción para solicitar la beca. 
                Los pasos a seguir son:
                <br></br>
                <br></br>
                <ol>
                <li>Rellena todos los campos del formulario.</li>
                <li>Adjunta los documentos solicitados.</li>
                <li>Lee y acepta los términos y condiciones de la beca.</li>
                <li>Antes de mandar tu solicitud a revisión, valida que tus datos sean correctos ya que estos no podrán ser modificados posteriormente. El correo electrónico registrado será el medio por el cual siempre estaremos en contacto contigo.</li>
                <li>Solo podrás mandar tu solicitud una vez que esté completa y con los documentos adjuntos.</li>
                </ol>
                <br></br>
                Tu petición de beca será confirmada en un plazo de una a tres semanas después de haber mandado tu solicitud.
                Te invitamos a entrar regularmente a tu cuenta INROADS (https://becasgoogle2022.inroads.org.mx/) 
                para conocer el resultado de tu solicitud. Si estás seleccionado, recibirás una invitación de parte de 
                Coursera para activar tu Certificado. Te pedimos que revises todas las bandejas del correo electrónico que 
                registraste en la solicitud.   
                </p>
              </TreeItem>
            

              <TreeItem nodeId='441' style={{ color:'black'}} label="¿Qué documentos necesito adjuntar a mi solicitud de beca?">
                <TreeItem nodeId='442' style={{ color:'black' }} />
                <br></br>
                <ul>
                  <li>Identificación oficial vigente con fotografía: Puede ser INE, pasaporte, cédula profesional o licencia de conducir. En caso de no contar con alguna de ellas podemos revisar tu caso, por lo que necesitamos que adjuntes credencial vigente del lugar de trabajo o de estudiante con fotografía.</li>
                  <li>Comprobante de tu último nivel de estudios.</li>
                </ul>
                <br></br>
              </TreeItem>
              
              <TreeItem nodeId='451' style={{ color:'black'}} label="¿Puedo ceder mi beca a otra persona, o compartir el acceso al curso con alguien más?">
                <TreeItem nodeId='452' style={{ color:'black' }} />
                <p>
                La beca y el acceso al curso son exclusivos e intransferibles. 
                </p>
              </TreeItem>


              <TreeItem nodeId='461' style={{ color:'black'}} label="¿Cómo y cuándo sabré si se me ha concedido la beca?">
                <TreeItem nodeId='462' style={{ color:'black' }} />
                <p>
                  Tu petición de beca será confirmada en un plazo de una a tres semanas después de haber mandado tu solicitud. 
                  Te invitamos a entrar regularmente a tu cuenta de INROADS (https://becasgoogle2022.inroads.org.mx/). 
                  para conocer el resultado de tu solicitud. Te avisaremos también por email y tendrás 2 semanas para activar 
                  tu Certificado en Coursera.
                </p>
              </TreeItem>


              <TreeItem nodeId='471' style={{ color:'black'}} label="¿Es el curso completamente gratuito? ">
                <TreeItem nodeId='472' style={{ color:'black' }} />
                <p>
                Los alumnos a los cuales se otorga una beca no tienen que abonar nada para disfrutar del 
                Certificado que seleccionaron. Es financiado por Google en su totalidad. Tu compromiso es 
                organizar tu tiempo para estudiar regularmente y concluir por lo menos uno de los cuatro 
                Certificados antes del 31 de marzo de 2023. Recuerda que requieres entre 5 y 10 horas 
                semanales durante 6 meses para acabar un Certificado.
                </p>
              </TreeItem>


              <TreeItem nodeId='481' style={{ color:'black'}} label="¿Qué otro beneficio me otorga la beca?">
                <TreeItem nodeId='482' style={{ color:'black' }} />
                <p>
                Una vez conseguido el Certificado de Carrera de Google que seleccionaste inicialmente, 
                podrás empezar otro Certificado, recordando que tienes hasta el 31 de marzo de 2023 
                para acabarlo. Tendrás también la gran oportunidad de registrarte en una nueva plataforma de 
                empleabilidad donde aliados de Google y de INROADS propondrán ofertas de empleo relacionadas a 
                tu(s) nueva(s) certificación(es).
                </p>
              </TreeItem>
              <TreeItem nodeId='491' style={{ color:'black'}} label="¿Puedo aplicar si soy de otro país distinto a México?">
                <TreeItem nodeId='492' style={{ color:'black' }} />
                <p>
                De momento no es posible solicitar una beca para los Certificados de Carrera de Google si 
                no eres Mexicano o si no eres residentes en México. Por ser un programa desarrollado por Google 
                de México, se dedica a apoyar a los Mexicanos, o residentes en México.
                </p>
              </TreeItem>

            </TreeItem>

            <TreeItem nodeId="50" style={{color:'#4285F4'}} label="Registro y Solicitud de Beca en la plataforma INROADS">
              <TreeItem nodeId='501' style={{ color:'black'}} label="¿Por qué necesito registrarme en INROADS para solicitar una beca?">
                <TreeItem nodeId='502' style={{ color:'black' }} />
                <p>
                  INROADS es una organización sin fines de lucro que tiene como misión detectar talento entre los
                  jóvenes mexicanos de recursos limitados para promover su formación y lograr encauzar
                  oportunidades para su desarrollo personal y profesional. Desde 2019 tenemos alianza con Google y
                  para este proyecto colaboraremos con ellos para otorgar y dar seguimiento a las becas.
                </p>
              </TreeItem>

              <TreeItem nodeId='511' style={{ color:'black'}} label="¿Cómo me doy de alta en INROADS?">
                <TreeItem nodeId='512' style={{ color:'black' }} />
                <p>
                Darte de alta es muy fácil, solo tienes que usar un correo electrónico y seguir los siguientes pasos:
                <br></br>
                <ul>
                  <li>Accede al registro de INROADS (https://becasgoogle2022.inroads.org.mx/)</li>
                  <li>Llena tus datos (solo tu email y una contraseña). Recuerda que la contraseña tendrá un mínimo de 10 caracteres, una mayúscula, una minúscula y un número.</li>
                  <li>Acepta la política de privacidad y condiciones generales y pulsa “Registrarme”.</li>
                  <li>Recibirás un email para validar tu correo (si no lo encuentras, revisa tu bandeja de SPAM o correo no deseado).</li>
                  <li>Abre tu email, confirma tu correo y ¡Listo!</li>
              </ul>
              <br></br>
                En caso de error, puedes contactarnos en “Soporte y ayuda” explicando tu problema y adjuntando 
                capturas del error.
                </p>
              </TreeItem>


              <TreeItem nodeId='521' style={{ color:'black'}} label="¿No me ha llegado el correo de validación de usuario?">
                <TreeItem nodeId='522' style={{ color:'black' }} />
                <p>
                Revisa que el email que ingresaste esté correcto y busca en todas las bandejas de tu correo.  
                (si no lo encuentras busca en todas tus bandejas incluyendo SPAM o correo no deseado).
                </p>
              </TreeItem>

              <TreeItem nodeId='531' style={{ color:'black'}} label="Ya me he inscrito, ¿Puedo editar mi solicitud una vez enviada?">
                <TreeItem nodeId='532' style={{ color:'black' }} />
                <p>
                No se puede editar la información una vez que el formulario de solicitud de beca sea guardado y enviado.
                </p>
              </TreeItem>

              <TreeItem nodeId='541' style={{ color:'black'}} label="¿No me ha llegado el correo de confirmación de la beca?">
                <TreeItem nodeId='542' style={{ color:'black' }} />
                <p>
                Revisa el estatus de tu solicitud en el portal de INROADS (https://becasgoogle2022.inroads.org.mx/) 
                Ingresa con tu usuario y contraseña, en un lapso de una a tres semanas después de haber hecho tu solicitud.
                </p>
              </TreeItem>
            
              <TreeItem nodeId='551' style={{ color:'black'}} label="¿Qué hago tras enterarme que se me ha concedido una beca?">
                <TreeItem nodeId='552' style={{ color:'black' }} />
                <p>
                Recibirás por correo electrónico una invitación de parte de Coursera para activar tu Certificado. 
                Si ya tienes una cuenta Coursera, puedes utilizarla, si no, tendrás que darte de alta con tu email registrado. 
                Es importante que actives tu Certificado dentro de las 2 siguientes semanas. De lo contrario, 
                la beca será asignada a otro candidato. 
                </p>
              </TreeItem>

              <TreeItem nodeId='561' style={{ color:'black'}} label="No recuerdo el email con el cual me registre inicialmente">
                <TreeItem nodeId='562' style={{ color:'black' }} />
                <p>
                Anota y guarda el correo electrónico registrado en tu solicitud de beca ya que será el medio en el que 
                nos pondremos en contacto contigo durante todo el proceso y no habrá ningún cambio. ¡Guárdalo!
                </p>
              </TreeItem>
              
              <TreeItem nodeId='571' style={{ color:'black'}} label="¿Por qué fui rechazado para obtener la beca?">
                <TreeItem nodeId='572' style={{ color:'black' }} />
                <p>
                Desde INROADS, trabajamos para ofrecer el máximo número de becas. 
                Desafortunadamente tenemos un límite y no podemos premiar a todos, pero nuestro objetivo es ofrecer 
                cada vez más oportunidades para aquellos que buscan mejorar su perfil profesional. Te invitamos a 
                seguirnos en nuestras redes sociales y que conozcas los beneficios que INROADS ofrece.
                </p>
              </TreeItem>

              <TreeItem nodeId='581' style={{ color:'black'}} label="Ya no me interesa o no podré disfrutar de la beca a la que apliqué, ¿tengo que hacer algo?">
                <TreeItem nodeId='582' style={{ color:'black' }} />
                <p>
                No te preocupes, por favor notificarnos al correo becas.certificado.google@inroads.org.mx 
                para que podamos brindar esta beca a otra persona que se encuentre en lista de espera. ¡Gracias!
                </p>
              </TreeItem>

            </TreeItem>

            <TreeItem nodeId="60" style={{color:'#4285F4'}} label="Acceso a los Certificados de Carrera de Google">
              
              
              <TreeItem nodeId='601' style={{ color:'black'}} label="¿Cuáles son los requisitos técnicos para acceder al curso en plataforma?">
                <TreeItem nodeId='602' style={{ color:'black' }} />
                <p>
                  Aunque puedas acceder al curso en una tableta o teléfono móvil, la recomendación es acceder a través
                  de una computadora/ordenador. Necesitarás una conexión a internet para acceder al contenido.
                  Podrás descargar el contenido de los cursos para estudiar offline, pero necesitarás estar online para
                  llevar a cabo las evaluaciones.
                </p>
              </TreeItem>
            


            <TreeItem nodeId='611' style={{ color:'black'}} label="¿Cómo acceder a la plataforma de cursos?">
                <TreeItem nodeId='612' style={{ color:'black' }} />
                <p>
                Tras la confirmación de haber sido beneficiario de la beca, recibirás por correo electrónico una 
                invitación de parte de Coursera para activar tu Certificado. Si ya tienes una cuenta Coursera, 
                puedes utilizarla, si no, tendrás que darte de alta con tu email registrado. Es importante que actives tu 
                Certificado dentro de las primeras dos semanas siguientes. De lo contrario, la beca será asignada a otro candidato. 
                </p>
              </TreeItem>
            
            <TreeItem nodeId='621' style={{ color:'black'}} label="¿Con quién me puedo comunicar si tengo problemas de acceso a la plataforma de cursos o cualquier pregunta relacionada?">
                <TreeItem nodeId='622' style={{ color:'black' }} />
                <p>
                Una vez dado de alta en la plataforma Coursera, cualquier problema 
                relacionado con la plataforma notificarlo a través del Centro de ayuda de Coursera. 
                Para cualquier otro problema no relacionado directamente con el curso, ponte en contacto con 
                Becas.certificado.google@inroads.org.mx. El tiempo de respuesta es de 3 días hábiles.
                </p>
              </TreeItem>
              </TreeItem>
            
            <TreeItem nodeId="70" style={{color:'#4285F4'}} label="Coursera">
          
              <TreeItem nodeId='701' style={{ color:'black'}} label="Me otorgaron la Beca, ahora ¿Cómo comienzo mi Certificado?">
                <TreeItem nodeId='702' style={{ color:'black' }} />
                <p>
                <ol>
                  <li>Busca en todas tus bandejas el email de aceptación e invitación a inscribirte de parte Coursera.</li>
                  <li>Da clic en “Unirme Ahora” para activar tu invitación personal; únete e inscríbete en el Certificado de Carrera.</li>
                  <li>Entra por la plataforma de INROADS las siguientes veces: <a href='https://becasgoogle2022.inroads.org.mx'>https://becasgoogle2022.inroads.org.mx</a>.</li>
                </ol>
                  <br/>
                    Nota: Si no activas primero la invitación personal, el link hacia Coursera desde la plataforma de INROADS no te va a funcionar. 
                    Es solo una vez activada la invitación que podrás utilizar el link desde la plataforma. 
                    Puedes revisar la siguiente infografía donde aparecen los pasos a seguir  <a href='proceso_inscripcion_coursera.pdf' target="_blank">Proceso Inscripción a Coursera.pdf.</a>
                </p>
              </TreeItem>
              
              <TreeItem nodeId='703' style={{ color:'black'}} label="Cuando intento ingresar a mi Certificado en Coursera, 
              me aparece que debo inscribirme, pero ya lo hice antes. ¿Qué debo hacer?">
              <p>
                Probablemente estás ingresando desde una cuenta de correo diferente al que usaste cuando solicitaste la beca.<br/>
                Te sugerimos cerrar sesión e ingresar nuevamente con el mismo correo con el que te diste de alta en INROADS.<br/>
                De igual manera, verifica que la cuenta de Google en la cual iniciaste sesión corresponda al correo con el que te diste de alta al solicitar tu beca.
              </p>
              </TreeItem>
          
              <TreeItem nodeId='704' style={{ color:'black'}} label="Los vídeos aparecen en inglés. ¿Cómo hago para que me aparezcan en español?">
                <p>
                  El audio de los cursos está en inglés, sin embargo, puedes activar los subtítulos en español, en la parte inferior derecha de la pantalla 
                  del vídeo debes seleccionar el apartado de <b>subtítulos</b> y dar clic en <b>“español”</b> (Spanish). 
                  De esta manera podrás visualizar el texto en español dentro del vídeo.
                </p>
              </TreeItem>
              
              <TreeItem nodeId='705' style={{ color:'black'}} label="¿Puedo cambiar mi Certificado de Carrera una vez que me lo hayan otorgado?">
                <p>
                  Si por error seleccionaste un Certificado que no querías, y deseas cambiarlo, debes enviar un mensaje explicando tus motivos a 
                  nuestro correo de soporte: <b>becas.certificado.google@inroads.org.mx</b>, mencionando el nombre del Certificado que elegiste por error y 
                  el nombre de aquél que deseas cursar, así mismo tu nombre y correo electrónico (con el cual solicitaste la beca). 
                  El cambio quedará sujeto a la disponibilidad de lugares que se tengan en el Certificado al que deseas ingresar. 
                  Si éste procede se te notificará al mismo correo electrónico con el que solicitaste la beca.
                </p>
              </TreeItem>

              <TreeItem nodeId='706' style={{ color:'black'}} label="No puedo ingresar a Coursera vía la plataforma de INROADS ¿Qué hago?">
                <p>
                  Te sugerimos cerrar sesión e ingresar nuevamente con el mismo correo con el que hiciste tu solicitud para obtener la beca, asegúrate que estés 
                  ingresando con ese mismo correo electrónico. Si esto no te funciona, puedes solicitar la recuperación de tu contraseña,
                  dando clic en “Olvidé mi contraseña” y seguir los pasos indicados. 
                </p>
              </TreeItem>

              <TreeItem nodeId='707' style={{ color:'black'}} label="¿El Certificado tiene algún costo?">
                <p>
                  Una vez que hayas obtenido la beca, el Certificado de Carrera no tiene costo. Sólo verifica que siempre ingreses a tu cuenta de 
                  Coursera con el mismo correo con el que solicitaste la beca, o mejor aún, siempre ingresa desde la plataforma de INROADS&nbsp;
                  <a href='https://becasgoogle2022.inroads.org.mx'>https://becasgoogle2022.inroads.org.mx</a>, ahí encontrarás un botón con el 
                  nombre del curso y mediante éste puedes acceder a Coursera. 
                </p>
              </TreeItem>

              <TreeItem nodeId='708' style={{ color:'black'}} label="¿Por qué, al querer realizar mi Certificado me aparece que tiene un costo, 
              si es que ya me dieron la beca? ">
                <p>
                  Cuando ingreses a Coursera, debes de seleccionar la opción que tiene el ícono de INROADS, 
                  para asegurarte que estás realizando el curso a través de la beca que solicitaste. 
                  Por ello, te sugerimos siempre ingresar mediante la plataforma de INROADS con el correo con el que solicitaste la beca desde el inicio. 
                </p>
              </TreeItem>
              

              <TreeItem nodeId='709' style={{ color:'black'}} label="¿Debo concluir cada módulo en las fechas que se indican en la plataforma de Coursera?">
                <p>
                  Las fechas marcadas para finalizar las actividades de los cursos son propuestas por Coursera para que los estudiantes 
                  puedan terminar su Certificado en el tiempo recomendado. Sin embargo, puedes avanzar a tu propio ritmo, y extender 
                  las fechas en caso de que lo consideres necesario. Siempre y cuando termines tu Certificado a más tardar el 31 de marzo del 2023. 
                  Te recomendamos que administres tu tiempo para que lo logres de manera exitosa. 
                </p>
              </TreeItem>
              

              <TreeItem nodeId='710' style={{ color:'black'}} label="¿Cuál es la diferencia entre Curso y Certificado?">
                <p>
                Toma en cuenta que tu <b>Certificado final se compone de varios cursos, (de 5 a 8 aproximadamente) </b>
                que podrás desbloquear de manera consecutiva, en la medida que vayas completando cada módulo con sus respectivas actividades.  
                <br/>
                Cada curso dura 4 semanas aproximadamente, y contiene vídeos, lecturas, ejercicios de práctica, 
                cuestionarios, así como foros de debate que deberás realizar. Recibirás un “Certificado parcial” para cada curso terminado, y un 
                “Certificado final” cuando acabes el programa entero.
                <br/>
                Como recomendación evita dejar alguna actividad pendiente; completa cada una antes de pasar a la siguiente.
                Cuando concluyas un curso podrás inscribirte al siguiente, de manera consecutiva hasta completar la cantidad total 
                de los cursos que conforman tu Certificado final. 
                </p>
              </TreeItem>
              
              <TreeItem nodeId='711' style={{ color:'black'}} label="¿Qué puedo hacer si alguna de las actividades me aparece como bloqueadas?">
                <p>
                  Asegúrate que hayas completado la actividad y que te aparezca una palomita en color verde. Si continúas con el problema, 
                  puedes crear un ticket de soporte en Coursera, explicando tu situación en el siguiente enlace: <br/>
                  <a href='https://www.coursera.support/s/learner-help-center-contact-us?fbclid=IwAR1MH3_ihJtq240ar_A2eZ6ehIrsNZ-KF9O7caRN6zSPHYDx-TMJUxfpnDY'>https://www.coursera.support</a>.
                </p>
              </TreeItem>

              <TreeItem nodeId='712' style={{ color:'black'}} label="¿A qué se refiere la leyenda que aparece 
                  “Un curso en línea sin crédito autorizado por Google y ofrecido a través de Coursera”?">
                <p>
                  Un Certificado de curso es una credencial oficial de Coursera que confirma que has completado correctamente un curso en Coursera, 
                  pero no proporcionan créditos profesionales o académicos que sirvan para sustituir materias de alguna Universidad. 
                  Les compartimos el link, donde viene más información al respecto. <br/>
                  <a href='https://www.coursera.support/s/article/208280196-Course-Certificates?language=en_US'>https://www.coursera.support</a>.
                </p>
              </TreeItem>

              <TreeItem nodeId='713' style={{ color:'black'}} label="Terminé el Certificado de Carrera de mi beca. ¿Qué sigue?">
                <p>
                ¡¡Felicidades!! Comparte esta excelente noticia en tus redes sociales (principalmente en LinkedIn) e invita tus amigos a solicitar una beca también. 
                No olvides etiquetarnos cuando compartas tu publicación. <br/>
                
                Con la misma beca, una vez que concluyas un Certificado completo, 
                podrás hacer la solicitud para cursar alguna (s) otra (s) de las 3 Certificaciones restantes. <br/>

                Por el momento, solicítalo al correo de <b>becas.certificado.google@inroads.org.mx</b>, indicando tu nombre completo, 
                el nombre del Certificado que deseas cursar, y el mismo correo que utilizaste al solicitar la beca. <br/>

                Posteriormente se habilitará una opción en la plataforma de INROADS para solicitarlo por ese medio. 
                Se les notificará cuando esto suceda.
                </p>
              </TreeItem>

              <TreeItem nodeId='714' style={{ color:'black'}} label="Una vez que concluí mi Certificado 
                ¿Tendré acceso a alguna plataforma de empleabilidad o podré compartir mi CV? ">
                <p>
                  Una vez que concluyas tu Certificado de Carrera y compartas en tus redes sociales este gran logro, tendrás la oportunidad de 
                  acceder a la plataforma de empleabilidad para darte a conocer con empresas empleadores, aliadas de Google y de INROADS.
                  Estará a tu disposición a través de nuestro portal de INROADS en las próximas semanas. <br/>
                
                  No te preocupes, te estaremos informando próximamente, a través de nuestro portal, 
                  así como en nuestras redes sociales. ¡No dejes de seguirnos!<br/>
                </p>
              </TreeItem>
              
              <TreeItem nodeId='715' style={{ color:'black'}} label="Entiendo que, si termino un Certificado, podré preparar otro con la misma beca, 
                ¿pero también me darán el Certificado de este segundo si lo acabo?">
                <p>
                  Recibirás todos los Certificados que logres concluir, de manera consecutiva, antes del 31 de marzo de 2023.
                </p>
              </TreeItem>

              <TreeItem nodeId='716' style={{ color:'black'}} label="Si concluí mi Primer Certificado y envié el correo solicitando el segundo 
                ¿Cuánto tiempo debo esperar para unirme a éste nuevo Certificado?">
                <p>
                  Una vez que envíes el correo a <b>becas.certificado.google@inroads.org.mx</b>, con los datos solicitados, 
                  se te notificará de 2 a 3 días, por este mismo medio, que busques en todas tus bandejas el correo de INROADS+COURSERA, para unirte e inscribirte, siguiendo el mismo proceso que realizaste con tu Primer Certificado.
                </p>
              </TreeItem>

              <TreeItem nodeId='717' style={{ color:'black'}} label="¿Puedo cambiar cómo aparece mi nombre en los Certificados que me proporciona Coursera?">
                <p>
                  Sí. Puedes cambiar tu nombre de la siguiente manera:
                  
                <ul>
                  <li>Abres tu sesión en Coursera.</li>
                  <li>Das clic en el ícono de <b>perfil</b>, y se te desplegará una lista con opciones.</li>
                  <li>Eliges: <b>“Ajustes”</b>. </li>
                  <li>Buscas la opción Verificación de nombre y das clic en el botón <b>“Verificar mi nombre”</b>.</li>
                  <li><b>Colocas el nombre</b> que quieras que aparezca en tu Certificado, así como tu apellido, y <b>seleccionas la casilla</b> que se te solicita.
                  </li>
                  <li>Das clic en <b>“Submit”</b> (sólo se habilita este botón cuando colocas tus datos y seleccionas la casilla).</li>
                </ul>
                <br/>
                <b>Nota:</b> No debes cambiar nada de tu información en el apartado de “Cuenta” (nombre, correo electrónico) ya que estos datos 
                está ligados a tu registro de la beca. La modificación de tu nombre sólo debes hacerla conforme a los pasos indicados anteriormente.
                </p>
              </TreeItem>

             </TreeItem>
          </TreeItem>


          <TreeItem nodeId="4" label="Soporte y Ayuda">
            <TreeItem nodeId="5"/>
            <TextField
            helperText={this.state.errors.nombre}
            error={this.state.errors.nombre !== ""}
            required
            autoFocus
            margin="dense"
            id="usuario"
            label="Nombre completo"
            type="text"
            value={this.state.nombre}
            fullWidth variant="standard"
            onChange={this.handleNombre} />

            <TextField
              helperText={this.state.errors.email}
              error={this.state.errors.code_email === 1}
              required
              margin="dense"
              id="contraseña"
              label="Email"
              type="email"
              value={this.state.email}
              fullWidth variant="standard" 
              onChange={this.handleCorreo}/>

              <TextField
              helperText={this.state.errors.mensaje}
              error={this.state.errors.mensaje !== ""}
              multiline
              required 
              margin="dense"
              id="mensaje"
              label="Mensaje"
              fullWidth
              variant="standard"
              value={this.state.mensaje}
              rows={4}
              onChange={this.handleMensaje}/>
              <br />
              <br />
              <FormGroup>
                <br />
                <br />
                { this.state.status_envio === 200 ? <Alert severity="info">Se envio tu correo exitosamente</Alert> : null}
                { this.state.status_envio >= 400 ? <Alert severity="error">Favor de intentar más tarde</Alert> : null}
                <br />

                <FormGroup>
                    <FormControlLabel control={<Checkbox onChange={this.handleCheck} checked={this.state.acepto_aviso}/>}
                      label={<span>Marcando esta casilla, confirmo que he leído, entendido y aceptado el <a href='https://inroads.org.mx/aviso-de-privacidad/'>aviso de privacidad</a></span>}
                    />
                    <br></br>
                    {this.state.acepto_aviso ? 
                     <Button 
                     variant="contained" 
                     onClick={this.sendSupport} 
                     className="button_ovalado"
                     style={{width:'20%', textTransform: 'None',margin: "auto"}}>
                     enviar
                     </Button>
                      : null }
                  </FormGroup>
              </FormGroup>
          </TreeItem>
        </TreeView>
        </Grid>
        </Grid>
        </Box>
      </div>
    )
  }
}

export default withRouter(SimpleAccordion)
//export default function SimpleAccordion() {
  
//}
