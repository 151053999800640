import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import FacebookIcon from '../img/redes/facebook.svg';
import TwitterIcon from '../img/redes/twitter.svg';
import LinkedInIcon from '../img/redes/linkedin.svg';
import '../css/redes.css'

export default function RedesSociales() {
    return(
        <div className='content-redes'>
            <TwitterShareButton
                title={"Obtén tu beca con INROADS y Google"}
                url={"https://avid-wavelet-344214.wl.r.appspot.com/"}
            >
                <img src={TwitterIcon} alt="compartir" />
            </TwitterShareButton>
            <LinkedinShareButton
                title={"Obtén tu beca con INROADS y Google "}
                url={"https://avid-wavelet-344214.wl.r.appspot.com/"}
                source={"INROADS"}
            >
                <img src={LinkedInIcon} alt="compartir" />
            </LinkedinShareButton>
            <FacebookShareButton
                url={"https://avid-wavelet-344214.wl.r.appspot.com/"}
                quote={"Obtén tu beca con INROADS y Google "}
                description={"esto es la descripcion"}
            >
                {/* <FacebookIcon color='disabled' style={{ fontSize: 25 }}/> */}
                <img src={FacebookIcon} alt="compartir" />
            </FacebookShareButton>
        </div>
    );
}