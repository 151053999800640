import React, {useState,useEffect} from 'react';
import AppBar from './AppBarHome';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import { debounceSearchRender }  from "mui-datatables";
import { CircularProgress,Typography } from '@mui/material';
import StickyFooter from "./StickyFooter";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ClearIcon from '@material-ui/icons/Clear';

export default function SearchPeople() {
  
  const [peopleData, setPeopleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsTable, SetOptionsTable] = useState({filter: true,
                                                    filterType: 'dropdown',
                                                    responsive: 'vertical',
                                                    selectableRows: 'none',
                                                    downloadOptions: {
                                                                      filename: 'INROADS.csv',
                                                                      filterOptions: {
                                                                        useDisplayedColumnsOnly: true,
                                                                        useDisplayedRowsOnly: true
                                                                      }},
                                                    onDownload: (buildHead, buildBody, columns, data) => {
                                                       let new_data = donwload_full(data);
                                                       return "\uFEFF" + buildHead(columns) + buildBody(new_data); 
                                                        
                                                    },
                                                    serverSide: true,
                                                    download: true,
                                                    customSearchRender: debounceSearchRender(1000),
                                                    print: false,
                                                    debounceInterval: 50000,
                                                    searchPlaceholder: "Nombre / Email",
                                                    rowsPerPage: 10,
                                                    rowsPerPageOptions: [10, 15, 20, 100],
                                                    onTableChange: (action, tableState) => {
                                                      console.log(tableState);
                                                      switch (action) {
                                                        case 'changePage':
                                                          changePage(tableState.page, tableState.rowsPerPage);
                                                          break;
                                                        case 'sort':
                                                          sort(tableState.page, tableState.rowsPerPage, tableState.sortOrder);
                                                          break;
                                                        case 'changeRowsPerPage':
                                                          changeRowsPerPage(tableState.page, tableState.rowsPerPage);
                                                          break;
                                                        case 'search':
                                                          search(tableState.page, tableState.rowsPerPage, tableState.searchText);
                                                          break;
                                                        
                                                        case 'filterChange':
                                                          filterChange(tableState.page, tableState.rowsPerPage,tableState.filterList);
                                                          break;

                                                        default:
                                                          console.log('action not handled. ->',action, '<-');
                                                      }
                                                    },
                                                  });

  const paramsPaginate = "?pagina=${page}&rows_per_page=${rows_per_page}";
  const request_backend = 'https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/search/' + paramsPaginate;
  const request_backendChangue = 'https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/change_certificate/';
  const request_backendChangueWhiteList = 'https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/add_change_whitelist/'
  const request_backendChangueUserStatus = 'https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/admin/rollback_baja/'

  const [columnsTable, setColumnsTable] = useState([]);

  // Para control de mensajes de exito y error
  const [mensaje, setMensaje] = useState({
    mostrarMensaje: false,
    textoMensaje:"Se actualizo de manera correcta",
    tipoMensaje:"success"
});

  var pageAux;
  var rowsPerPageAux;
  var jsonRules = {};
  var peopleDataAux;
  

  const updateBodyTable = (page=0,rowsPerPage=10,jsonOptionsRules=jsonRules) => {
    setIsLoading(true);
    let http_request = request_backend.replace('${page}',page).replace('${rows_per_page}',rowsPerPage);
    
    axios.post(http_request, jsonOptionsRules ,
              { auth: { username: sessionStorage.getItem('email'), password: sessionStorage.getItem('token'), }}
              )
      .then((response) => {
        //Obtiene los datos del API
        
        //Datos de las personas.
        console.log("informacion para la tabla ",response.data.lista_personas);
        const newData = tranformData(response.data.lista_personas)
        peopleDataAux = newData
        setPeopleData(newData);

        if (page == "0"){
          //Actualiza el conteo de datos totales
          SetOptionsTable( { ...optionsTable,  count: response.data.count_records});
          //Actualiza header
          const columns_table = 
          [
            { name: 'id_user', label: 'Identificador usuario', options: {"display":false,"filter":false, "viewColumns":false}, },
            { name: 'nombre', label: 'Nombre', options: {"filter":false}, },
            { name: 'email', label: 'Email', options: {"filter":false}, },
            { name: 'etapa_usuario', label: 'Etapa', options: {"filter":true, filterOptions: { names: response.data.etapa_usuario },}, },
            { name: 'estatus_beca', label: 'Estatus de beca', options: {filterOptions: { names: response.data.estatus_beca }}, },
            { name: 'nombre_certificado', label: 'Certificado', options: {filterOptions: { names: response.data.nombre_certificado }}, },
            { name: 'estatus_certificado', label: 'Estatus certificado', options: {filterOptions: { names: response.data.estatus_certificado }}, },
            { name: 'estatus_usuario', label: 'Estatus usuario', options: {filterOptions: { names: response.data.estatus_usuario }}, },
            { name: 'organizacion', label: 'Organizacion', options: {filterOptions: { names: response.data.organizacion }}, },
            { name: 'whitelist', label: 'Whitelist', options: {filterOptions: { names: response.data.whitelist, renderValue: val => { if (val === "" || val === null || val === undefined) { return "(vacio)"; } return val; } }}, },
            { name: 'id_certificado', label: 'Identificador del certificado', options:{ "filter":false, "sort":false, "display":false, "viewColumns":false } },
            { name: 'lock_change_estatus', label: 'Lock change status', options:{ "filter":false, "sort":false, "display":false, "viewColumns":false } },
            { name: 'lock_change_certificate', label: 'Lock change certificate', options:{ "filter":false, "sort":false, "display":false, "viewColumns":false } },
            { name: 'id_catalogo_comunidad', label: 'Identificador del cátalogo comunidad', options:{ "filter":false, "sort":false, "display":false, "viewColumns":false } },
          ]
          setColumnsTable(columns_table);
        }
        //Actualiza los registros por pagina
        SetOptionsTable({ ...optionsTable, rowsPerPage: rowsPerPage, });
        setIsLoading(false);

        })

      .catch((error) => {
          console.log(error);
      });
  }

  const tranformData = (listData) => {
    return listData&&listData.map(data => ({...data, diff_cert:false, diff_whiteList:false,
      nombre_certificado:
      <div className='edit-data'>
        <p>{data.nombre_certificado}</p>
        <button disabled={data.lock_change_certificate === 1} onClick={()=>{handleChangueCert(data);}}><EditIcon/></button>
      </div>,
      whitelist:
      <div className='edit-data'>
        <p>{data.whitelist}</p>
        <button disabled={data.id_catalogo_comunidad != null} onClick={()=>{handleChangueWhiteList(data)}}><EditIcon/></button>
      </div>,
      estatus_usuario: 
      <div className='edit-data'>
        <p>{data.estatus_usuario}</p>
        <button disabled={data.lock_change_estatus === 1} onClick={()=>{handleChangueUserActive(data)}}><EditIcon/></button>
      </div>,
    }));
  }

  const tranformDataEdit = (listData, idUserCert, attributeChangue) => {
    return listData&&listData.map(data => {
      const idUserCertAux = createId(data);
      if(idUserCertAux === idUserCert ) {
        switch(attributeChangue) {
          case 'id_certificado': 
            return {...data, 
              nombre_certificado:
              <>
                <div className='edit-data'>
                  <Select id="chooseCert" value={data.id_certificado_new?data.id_certificado_new:data.id_certificado} onChange={(idCert)=>{updateDataEdit(listData,data,idCert.target.value,attributeChangue);}}>
                    <MenuItem value={1}>Análisis de datos</MenuItem>
                    <MenuItem value={2}>Diseño de experiencia de usuario</MenuItem>
                    <MenuItem value={3}>Gestión de proyectos</MenuItem>
                    <MenuItem value={4}>Soporte de Tecnologías de la Información</MenuItem>
                  </Select>
                  <button className='acept-data' disabled={data.diff_cert === false} onClick={()=>{handleSelectNewCert(data, data.id_certificado_new);}}><DoneIcon/></button>
                  <button className='cancel-data' onClick={()=>{setPeopleData(peopleDataAux)}}><ClearIcon/></button>
                </div>
              </>
            };
          case 'whitelist':
            return {...data, 
              whitelist:
              <>
                <div className='edit-data'>
                  <Select id="chooseWhiteList" value={data.id_catalogo_comunidad_new?data.id_catalogo_comunidad_new:data.id_catalogo_comunidad} onChange={(whiteList)=>{updateDataEdit(listData,data,whiteList.target.value,attributeChangue);}}>
                    <MenuItem value={3}>Empresa Aliada</MenuItem>
                    <MenuItem value={2}>Inroader de Desarrollo Empresarial</MenuItem>
                    <MenuItem value={5}>Inroader Graduado</MenuItem>
                    <MenuItem value={4}>Organización aliada</MenuItem>
                    <MenuItem value={1}>Pool de Talento</MenuItem>
                    <MenuItem value={6}>Otros</MenuItem>
                  </Select>
                  <button className='acept-data' disabled={data.diff_whiteList === false} onClick={()=>{handleSelectNewWhiteList(data, data.id_catalogo_comunidad_new);}}><DoneIcon/></button>
                  <button className='cancel-data' onClick={()=>{setPeopleData(peopleDataAux)}}><ClearIcon/></button>
                </div>
              </>
            };
          
          case 'estatus_usuario':
            const valueEstatusUsu = data.estatus_usuario_aux === 'Activo' ? 1 : 0;
            return {...data, 
              estatus_usuario:
              <>
                <div className='edit-data'>
                  <Select id="chooseWhiteList" value={valueEstatusUsu} onChange={(newUserStatus)=>{updateDataEdit(listData,data,newUserStatus.target.value,attributeChangue);}}>
                    <MenuItem value={0}>Baja</MenuItem>
                    <MenuItem value={1}>Activo</MenuItem>
                  </Select>
                  <button className='acept-data' disabled={valueEstatusUsu === 0} onClick={()=>{handleSelectUserStatus(data);}}><DoneIcon/></button>
                  <button className='cancel-data' onClick={()=>{setPeopleData(peopleDataAux)}}><ClearIcon/></button>
                </div>
              </>
            };
          default: return data;
        }
       } else {
        return data;
       }
      });
  }

  // Funcion que actualiza la seleccion de un idCertificado o un idWhiteList
  const updateDataEdit = (listData,dataOrigin,newId,attributeChangue) => {
    const idUserCertSelectAux = createId(dataOrigin);

    const listDataUpdated = listData.map(data => {
      const idUserCertAux = createId(data);
      if(idUserCertAux === idUserCertSelectAux) {
        switch(attributeChangue) {
          case 'id_certificado': return {...data, id_certificado_new:newId, diff_cert: dataOrigin.id_certificado !== newId };
          case 'whitelist': return {...data, id_catalogo_comunidad_new:newId, diff_whiteList:dataOrigin.id_catalogo_comunidad !== newId};
          case 'estatus_usuario': return {...data, estatus_usuario:newId?'Activo':'Baja', estatus_usuario_aux:newId?'Activo':'Baja'};
          default: return data;
        }
      } else {
        return data;
      }
    });

    const listDataUdateTranform = tranformDataEdit(listDataUpdated,idUserCertSelectAux,attributeChangue);
    setPeopleData(listDataUdateTranform);
  }

  const createId = (data) => {
    const idCert = data.id_certificado ? data.id_certificado : 0;
    return data.id_user.toString()+"_"+idCert.toString();
  }

  const handleChangueCert = (data) => {
    const idUsuCert = createId(data);
    const listPeopleEdit = tranformDataEdit(peopleDataAux,idUsuCert,'id_certificado');
    setPeopleData(listPeopleEdit);
  }

  const handleChangueWhiteList = (data) => {
    const idUsuCert = createId(data);
    const listPeopleEdit = tranformDataEdit(peopleDataAux,idUsuCert,'whitelist');
    setPeopleData(listPeopleEdit);
  }

  const handleChangueUserActive = (data) => {
    const idUsuCert = createId(data);
    const listPeopleEdit = tranformDataEdit(peopleDataAux,idUsuCert,'estatus_usuario');
    setPeopleData(listPeopleEdit);
  }

  const handleSelectNewCert = (data, idCert) => {
    const params = {
      id_user:data.id_user,
      id_certificado_actual:data.id_certificado,
      id_certificado_nuevo:idCert
    };
    setIsLoading(true);
    axios.post(request_backendChangue, params,
      { auth: { username: sessionStorage.getItem('email'), password: sessionStorage.getItem('token'), }}
      )
    .then((response) => {
      updateBodyTable(pageAux,rowsPerPageAux,jsonRules);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  };

  const handleSelectNewWhiteList = (data, newIdWhiteList) => {
    const params = {
      id_user:data.id_user,
      id_comunidad:newIdWhiteList
    };
    
    setIsLoading(true);
    axios.post(request_backendChangueWhiteList, params,
      { auth: { username: sessionStorage.getItem('email'), password: sessionStorage.getItem('token'), }}
      )
    .then((response) => {
      updateBodyTable(pageAux,rowsPerPageAux,jsonRules);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  }

  const handleSelectUserStatus = (data) => {

    const params = {
      id_user:data.id_user,
    };

    setMensaje({...mensaje,mostrarMensaje:false});
    setIsLoading(true);
    
    axios.post(request_backendChangueUserStatus, params,
      { auth: { username: sessionStorage.getItem('email'), password: sessionStorage.getItem('token'), }}
      )
    .then((response) => {
      if(response.data.status_rollback === 'true') {
        updateBodyTable(pageAux,rowsPerPageAux,jsonRules);
      } else {
        setMensaje({
            mostrarMensaje:true,
            textoMensaje:response.data.message,
            tipoMensaje:"error"
        });
      }
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  };

  //Make new request for change page 
  const changePage = (page, rowsPerPage) => {
      pageAux = page;
      rowsPerPageAux = rowsPerPage
      updateBodyTable(page,rowsPerPage);
  };

  const sort = (page, rowsPerPage, sortOrder) => {
    pageAux = page;
    rowsPerPageAux = rowsPerPage;
    jsonRules['sort'] = sortOrder;
    updateBodyTable(page,rowsPerPage,jsonRules);
};

  const changeRowsPerPage = (page, newRowsPerPage) => {
    pageAux = page;
    updateBodyTable(page,newRowsPerPage);
  };

  const search = (page, rowsPerPage, searchText) => { 
    pageAux = page;
    rowsPerPageAux = rowsPerPage;
    jsonRules["searchText"] = searchText;
    updateBodyTable(page,rowsPerPage,jsonRules);
  };

  const filterChange = (page, rowsPerPage, filterList) => {
    console.log("lo que llega al filtrar", filterList);
    pageAux = page;
    rowsPerPageAux = rowsPerPage;

    //Construct json_rule
    //filter list from columns of table
    let json = {
                etapa_usuario: filterList[3],
                estatus_beca: filterList[4],
                nombre_certificado: filterList[5],
                estatus_certificado: filterList[6],
                estatus_usuario: filterList[7],
                organizacion: filterList[8],
                whitelist: filterList[9],
              };

    jsonRules['filter'] = json;
    updateBodyTable(page,rowsPerPage,jsonRules);

  };

  const donwload_full = (data) => {
    return data;
  }


  //Fist load data from backend
  useEffect(() => {
    //Actualizar tabla y header con datos.
    updateBodyTable();
  }, []);

  return (
    <>
        <AppBar/>
        <div className='content'>
          <MUIDataTable
                  title={
                    <Typography variant="h6">
                      Solicitudes
                      {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                    </Typography>
                  }
                  data={peopleData}
                  columns={columnsTable}
                  options={optionsTable}
            />
        </div>
        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={3000}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
        >
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
        <div>
          <StickyFooter />
        </div>
    </>
);

}

