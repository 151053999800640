import React, { Component } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { withRouter } from 'react-router';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Typography from "@material-ui/core/Typography";
import iconoConfirmacion from './img/icono-conformacion.svg';
import "./css/landing_body.css";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

class SignUp extends Component {
  
  state = {
      user: '',
      checkUser: '',
      pass: '',
      checkPass: '',
      isSignedup: false,
      setOpen:false,
      open:false,
      openModal: false,
      alert: false,
      alertContent: '',
      validateEmail: false,
      validatePass: false,
      uriel: false,
      openModalNested: false,
      correo: 0,
      contraseña:0,
      datosGeneral:0,
      showPassword: false,
      acepto_aviso: false,
      existencia:0,
      validate: {
        status_user: 0,
        message_user: "",
        status_check_user: 0,
        message_check_user: "",
        status_pass: 0,
        status_check_pass: 0,
        message_check_pass: "",
      }

  }
  handleClickShowPassword = event => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  }

  handleClickOpen = event => {
      event.preventDefault()
      this.setState({ setOpen:true})
  }

  handleClose = event => {
      this.setState({ open:false})
  }

  handleUser = event => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value) || event.target.value === "")
      this.setState({ user: event.target.value, validate: { ...this.state.validate, message_user: "",status_user: 0 } })
    else{
      this.setState({validate: { ...this.state.validate, message_user: "Por favor ingresa un correo valido",status_user: 1 }});
      this.setState({ user: "" })
    }
  }

  handleUserCheck = event => {
      if(event.target.value !== "" && event.target.value === this.state.user)
        this.setState({checkUser: event.target.value,
          validate: { ...this.state.validate, message_check_user: "",status_check_user: 0 } })
      else
        this.setState({validate: { ...this.state.validate, message_check_user: "El correo no coincide",status_check_user: 1 }});

  }

  handlePass = event => {

    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(event.target.value) || event.target.value === "")
      this.setState({ pass: event.target.value, validate: { ...this.state.validate, status_pass: 0 } })
    else{
      this.setState({validate: { ...this.state.validate, status_pass: 1 }});
      this.setState({ pass: "" })
    }
  }

  handlePassCheck = event => {
    if(event.target.value !== "" && event.target.value === this.state.pass)
        this.setState({checkPass: event.target.value,
          validate: { ...this.state.validate, message_pass_user: "",status_check_pass: 0 } })
      else
        this.setState({validate: { ...this.state.validate, message_check_pass: "La contraseña no coincide",status_check_pass: 1 }});
  }

  handleAlert = event => {
      this.setState({ alert: true })
      this.setState({ alertContent: 'Registro exitoso'})
  }

  handleCheck = event => {
    this.setState({ acepto_aviso: !this.state.acepto_aviso })
  }

  login = event => {
      
      var val_temp_email = false;
      var val_temp_pass = false;
      var validations = this.state.validate;
      
      //Validar no nulo
      if (this.state.user === ""){
        validations.message_user = "Favor de colocar un correo";
        validations.status_user = 1;
      }

      if (this.state.checkUser === ""){
        validations.status_check_user = 1;
      }

      if (this.state.pass === ""){
        validations.status_pass = 1;
      }

      if (this.state.checkPass === ""){
        validations.status_check_pass = 1;
      }
      
      this.setState({validate: validations,acepto_aviso: this.state.acepto_aviso})

      // Revisar que no exista un mensaje de error
      if(  validations.status_user === 1 
        || validations.status_check_user === 1
        || validations.status_pass === 1
        || validations.status_check_pass === 1){
          return false;
      }else{
        val_temp_email = true;
        val_temp_pass = true;
      }
      
      /*
      if (validateUser.email === validateUser.checkEmail){
        val_temp_email = true
      }else{
        this.setState({ correo: 1 })
      }

      if (validateUser.password === validateUser.checkPass){
        val_temp_pass = true
      }else{
        this.setState({ contraseña: 1 })
      }
      */

      const headersPost = {
        'content-type': 'text/json'
      }

      const user = {
          email: this.state.user,
          password: this.state.pass,
          //signedup: this.state.isSignedup,
      };

      if (val_temp_email && val_temp_pass){
        const data = user
        const headers_post = JSON.stringify(headersPost)
        axios.post('https://backend-dot-avid-wavelet-344214.wl.r.appspot.com/api/v1/login/register/', data, headers_post)
        .then(res => {
        if (res.request.status === 200){
        //sessionStorage.setItem('isSigned', true)
        //sessionStorage.setItem('email',this.state.user)
        //this.setState({ isSignedup: true })
        this.setState({ openModalNested: true})

        } else {
          this.setState({ isSignedup: false })
        }
        })
      }else{
        this.setState({ datosGeneral: 1})
      }
  }

  launchModal = e => {
      e.preventDefault()
      this.setState({ openModal: true})
  }

  launchModalNested = e => {
    e.preventDefault()
    this.setState({ openModalNested: true})
}

  closeModal = e => {
      this.setState({ 
        openModal: false,
        acepto_aviso: false,
        validate: {
          status_user: 0,
          message_user: "",
          status_check_user: 0,
          message_check_user: "",
          status_pass: 0,
          status_check_pass: 0,
          message_check_pass: "",
        }
      })
  }

  closeModalNested = e => {
    this.setState({ 
      openModal: false,
      openModalNested: false,
      acepto_aviso: false,
      validate: {
        status_user: 0,
        message_user: "",
        status_check_user: 0,
        message_check_user: "",
        status_pass: 0,
        status_check_pass: 0,
        message_check_pass: "",
      }
    })
}

  render(){
      return (
          <>

          {
          this.props.liga !== "true" ?
          <Button 
          variant="contained" 
          onClick={this.launchModal}
          style={{marginTop:'5px', textTransform: 'None'}} 
          className="button_ovalado" >
          regístrate aquí
          </Button>
          :
          <a href="/" className="link" onClick={this.launchModal}>Regístrate aquí</a>
          }

          <Modal 
          open={this.state.openModal}
          onClose={this.closeModal} 
          id="modal-registro"
          >
            <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1},
              display: 'flex', flexWrap: 'wrap',
            }}
            noValidate
            autoComplete="off"
            style={{width: "85%", margin:"auto"}}
            >
        
              <DialogContent className='font-landing'>
                  <DialogContentText style={{fontSize:'25px'}}>
                    Gracias por tu interés en este programa.
                  </DialogContentText>
                  <br></br>
                  <div>
                    <p>Por favor, compártenos tu correo. Te informaremos, por este medio, si se abren nuevas oportunidades para que puedas participar.</p>
                    <p>INROADS de México A.C.</p>
                    <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSfxtsJA47lMy9z_TJ0jQgpU28OpTLp_Vf4Wqb_405W-kidjvw/viewform">Da clic aquí</a></p>
                  </div>
              </DialogContent>
              </Box>
          </Modal>   
          </>
      )
  }
}

export default withRouter(SignUp);